import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { InternalNote } from '../../../../../core/models/incidents/incident-internal-note.model';
import { UserProfile } from '../../../../../core/models/user-profiles/user-profile.model';
import { IncidentService } from '../../../../../core/services/incidents/incident.service';
import { UserProfileService } from '../../../../../core/services/user-profiles/user-profile.service';
import { ConfigurationService } from '../../../../runtime-configuration/services/configuration.service';

@Component({
  selector: 'app-incident-internal-note',
  templateUrl: './incident-internal-note.component.pug',
  styleUrls: ['./incident-internal-note.component.scss']
})
export class IncidentInternalNoteComponent implements OnInit {
  
  private _subscription = new Subscription();

  @Input()
	public id: string;
  
  public formGroup = new FormGroup({
		description: new FormControl('', Validators.required)
	});

  public profile: UserProfile;
  
  constructor(private _bsModalRef: BsModalRef, private _incidentService: IncidentService, private _configSvc: ConfigurationService, private _profileService: UserProfileService) { }

  ngOnInit() {
    this.getProfile();
  }

  public onCancel(): void {
		this._bsModalRef.hide();
	}

  public getProfile():void {
		this._subscription.add(
		  this._profileService.getMyProfile()
		  .subscribe(data => {
			this.profile =  data;
		  })
		)
	}

  public submit(): void { 
    let request = new InternalNote();
    request.description = this.formGroup.value.description;
    request.taskTypeId = this._configSvc.configuration.caseInternalNoteTaskTypeId;
    request.userFullName = this.profile.displayName;
    request.emailAddress = this.profile.email;
    this._subscription.add(
      this._incidentService.createInternalNote(this.id, request)
      .subscribe(data => {
        this._bsModalRef.hide();
      })
    )
  }
  
  ngOnDestroy() {
		this._subscription.unsubscribe();
	}

}
