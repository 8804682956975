import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

import { ArticleLogPhrase } from '../../../../../core/models/articles/article-log-phrase.model';
import { Article } from '../../../../../core/models/articles/article.model';

@Component({
	selector: 'app-log-phrases',
	templateUrl: './log-phrases.component.pug',
	styleUrls: ['./log-phrases.component.scss']
})
export class LogPhrasesComponent implements OnInit {

	readonly logPhrasesTargetControlName = 'logPhrases';

	@Input('group')
	public formGroup: FormGroup;

	@Input("article")
	public article: Article;

	constructor(private _fb: FormBuilder) {
	}

	ngOnInit() {
		this.createForm();
	}

	private createForm(): void {
		if (this.formGroup) {
			let controls: FormGroup[] = [];

			if (this.article && this.article.articleLogPhrases) {
				controls = this.article.articleLogPhrases.map(r => this._fb.group({
					filename: [r.filename, Validators.required],
					phrase: [r.phrase, Validators.required]
				}));
			}

			this.formGroup.addControl(this.logPhrasesTargetControlName, this._fb.array(controls));
		}
	}

	private initLogPhrase(): FormGroup {
		return this._fb.group({
			filename: ['', Validators.required],
			phrase: ['', Validators.required]
		});
	}

	public addLogPhrase(): void {
		const control = <FormArray>this.formGroup.controls[this.logPhrasesTargetControlName];

		control.push(this.initLogPhrase());
	}

	public removeLogPhrase(i: number): void {
		const control = <FormArray>this.formGroup.controls[this.logPhrasesTargetControlName];

		control.removeAt(i);
	}

	// needed because of a bug reported back in Angular version 5 that does not cast
	public getControls(key: string): any {
		return (<FormArray>this.formGroup.controls[key]).controls;
	}

}
