import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ODataResult } from '../../models/oData/odata-result.model';

@Injectable({
	providedIn: 'root'
})
export class CategoryService {

	private _urlBase: string = "odata/category";

	constructor(private _httpClient: HttpClient) { }

	public getCategories(): Observable<Array<any>> {
		return this._httpClient.get<ODataResult<any>>(`${this._urlBase}?$orderby=name asc`).pipe(
			map(response => response.value)
		);
	}

}
