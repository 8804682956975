import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ArticlesViewerComponent } from "./components/articles/articles-viewer/articles-viewer.component";
import { IncidentViewerComponent } from "./components/incidents/incident-viewer/incident-viewer.component";
import { AuthPermissionsGuard } from "../../core/services/permissions/auth-permissions.guard";
import { ArticlesCompareComponent } from "./components/articles/articles-compare/articles-compare.component";
import { PsrViewerComponent } from "./components/psrs/psr-viewer/psr-viewer.component";

const routes: Routes = [
	{ path: 'incident/details/:incidentId', component: IncidentViewerComponent, canActivate: [AuthPermissionsGuard] },
	{ path: "article/details/:id", component: ArticlesViewerComponent, canActivate: [AuthPermissionsGuard], runGuardsAndResolvers: 'always' },			// version is optional
	{ path: "article/details/:id/:version", component: ArticlesViewerComponent, canActivate: [AuthPermissionsGuard], runGuardsAndResolvers: 'always' },
	{ path: "article/diff/:id/:version1/:version2", component: ArticlesCompareComponent, canActivate: [AuthPermissionsGuard] },
	{ path: 'psr/details/:id', component: PsrViewerComponent, canActivate: [AuthPermissionsGuard], data: { roles: ['view-psr'] } },
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class ContentViewerRoutingModule { }
