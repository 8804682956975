import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { Subscription } from 'rxjs';

import { FilterSettingsModel, PageSettingsModel, SortSettingsModel, SelectionService, GridComponent, ToolbarItems, ExcelExportProperties, ToolbarService, ExcelExportService } from '@syncfusion/ej2-angular-grids';
import { DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';

import { AuthService } from '../../../../../core/services/auth/auth.service';
import { ArticleService } from '../../../../../core/services/articles/article.service';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';

@Component({
	selector: 'app-delete-unused-solutions',
	templateUrl: './delete-unused-solutions.component.pug',
	styleUrls: ['./delete-unused-solutions.component.scss'],
	providers: [SelectionService, ToolbarService, ExcelExportService]
})
export class DeleteUnusedSolutionsComponent implements OnInit {

	private _pageSizes: number[] = [15, 30];

	private _subscriptions = new Subscription();

	public unusedSolution: DataManager;

	public filterOptions: FilterSettingsModel;

	public pageOptions: PageSettingsModel;

	public sortOptions: SortSettingsModel;

	public dusFormGroup: FormGroup;

	public startDate: Date;

	public endDate: Date;

	public maximumEffectiveness: number;

	public minimumAge: number;

	public minimumViewed: number;

	public errorMessage: boolean = false;

	public selectOptions: Object;

	public isDisabled: boolean = true;

	@ViewChild('grid')
	public grid: GridComponent;

	public toolbarOptions: ToolbarItems[];

	constructor(private _fb: FormBuilder, private _authService: AuthService, private _articleService: ArticleService) {
	}

	ngOnInit() {
		this.createForm();

		this.pageOptions = { pageCount: 10, pageSize: this._pageSizes[0], pageSizes: this._pageSizes };

		this.filterOptions = {
			type: 'Menu'
		};

		this.toolbarOptions = ['ExcelExport'];
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	private createForm(): void {
		this.dusFormGroup = this._fb.group({
			startDate: ['', Validators.required],
			endDate: ['', Validators.required],
			maximumEffectiveness: ['', Validators.required],
			minimumAge: ['', Validators.required],
			minimumViewed: ['', Validators.required],
		});

		this.dusFormGroup.addControl('startDate', this._fb.control(''));
		this.dusFormGroup.addControl('endDate', this._fb.control(''));
		this.dusFormGroup.addControl('maximumEffectiveness', this._fb.control(''));
		this.dusFormGroup.addControl('minimumAge', this._fb.control(''));
		this.dusFormGroup.addControl('minimumViewed', this._fb.control(''));

		this._subscriptions.add(
			this.dusFormGroup.get("startDate").valueChanges
				.subscribe(value => {
					this.startDate = value;
				})
		);

		this._subscriptions.add(
			this.dusFormGroup.get("endDate").valueChanges
				.subscribe(value => {
					this.endDate = value;
				})
		);

		this._subscriptions.add(
			this.dusFormGroup.get("maximumEffectiveness").valueChanges
				.subscribe(value => {
					this.maximumEffectiveness = value;
				})
		);

		this._subscriptions.add(
			this.dusFormGroup.get("minimumAge").valueChanges
				.subscribe(value => {
					this.minimumAge = value;
				})
		);

		this._subscriptions.add(
			this.dusFormGroup.get("minimumViewed").valueChanges
				.subscribe(value => {
					this.minimumViewed = value;
				})
		);
	}

	private getDataManager() {
		let query = `startDate=${this.startDate.toISOString()}&endDate=${this.endDate.toISOString()}&maximumEffectiveness=${this.maximumEffectiveness}&minimumAge=${this.minimumAge}&minimumViewed=${this.minimumViewed}`;
		return new DataManager({
			url: `api/admin/deflectionAnalysis?${query}`,
			adaptor: new WebApiAdaptor,
			offline: true,
			crossDomain: true,
			headers: [{ 'Authorization': this._authService.getAuthorizationHeaderValue() }],
		});
	}

	public onSubmit(): void {
		this.errorMessage = false;
		this.unusedSolution = this.getDataManager();
		this.selectOptions = { persistSelection: true };
		this.dusFormGroup.disable();
	}

	public delete(): void {
		if (this.grid.getSelectedRowIndexes().length) {
			var articleIds = this.grid.getSelectedRecords().map(x => x['articleId']);
			this._subscriptions.add(
				this._articleService.deleteArticles(articleIds)
					.subscribe(
						response => {
							this.grid.getSelectedRecords().map((val) => {
								val['isAvailable'] = false;
								this.grid.setRowData(val['articleId'], val);
							});
							this.grid.clearSelection();
						},
						error => {
							this.errorMessage = true;
						}
					)
			);
		}
	}

	public onToolbarClick(args: ClickEventArgs): void {
		if (args.item.id.endsWith('_excelexport')) {
			let exportProperties: ExcelExportProperties = {
				fileName: 'DeleteUnusedSolutions.xlsx'
			};

			this.grid.excelExport(exportProperties);
		}
	}

	actionFailure(e) {
		this.errorMessage = true;
		this.dusFormGroup.enable();
	}

	actionComplete(e) {
		this.dusFormGroup.enable();
	}

	onRowSelected(e) {
		if (this.grid.getSelectedRowIndexes().length) {
			this.isDisabled = false;
		}
	}

	onRowDeselected(e) {
		if (!this.grid.getSelectedRowIndexes().length) {
			this.isDisabled = true;
		}

	}

}
