// Angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// *** App

// Routing
import { AppRoutingModule } from "./app-routing.module";

// Modules
import { RuntimeConfigurationModule } from './modules/runtime-configuration/runtime-configuration.module';
import { CoreModule } from './core/core.module';

// Components
import { AppComponent } from './app.component';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
		HttpClientModule,
		RouterModule,

		// App Imports
		RuntimeConfigurationModule,
		CoreModule.forRoot(),
		AppRoutingModule
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
