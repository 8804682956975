import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-admin-tool',
	templateUrl: './admin-tool.component.pug',
	styleUrls: ['./admin-tool.component.scss']
})
export class AdminToolComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
