import { Role } from "../roles/role.model";

export class ArticleTargetRole {

	public articleId: number;

	public version: number;

	public roleId: string;

	public role: Role;

}
