import { Component } from '@angular/core';
import { Input } from '@angular/core';

@Component({
	selector: 'app-article-search-result',
	templateUrl: './search.result.article.component.pug',
	styleUrls: ['./search.result.article.component.scss']
})

export class ArticleSearchResultComponent {

	@Input()
	result: any; // update this to proper model?

}
