import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';

import { Confirmation } from '../../../../../../content-manager/models/confirmation.model';

@Component({
   selector: 'app-confirmation-with-comment',
   templateUrl: './confirmation-with-comment.component.pug',
   styleUrls: ['./confirmation-with-comment.component.scss']
})
export class ConfirmationWithCommentComponent implements OnInit {

   @Input()
   public title: string = 'Confirmation';

   @Input()
   public commentOptional: boolean = false;

   @Input()
   public confirmLabel: string = 'Confirm';

   @Input()
   public cancelLabel: string = 'Cancel';

   public onClose: Subject<Confirmation>;

   public formGroup: FormGroup;

   public submittedOnce: boolean = false;

   constructor(private _bsModalRef: BsModalRef, private _fb: FormBuilder) { }

   ngOnInit() {
	  //this.submittedOnce = false;

	  var commentValidators = this.commentOptional ? [Validators.minLength(5)] : [Validators.required, Validators.minLength(5)];

	  this.formGroup = this._fb.group({
		 comment: ['', commentValidators]
	  });

	  this.onClose = new Subject();
   }

   public onConfirm(): void {
	  this.submittedOnce = true;

	  if (this.formGroup.invalid) {
		 return;
	  }

	  const comment = this.formGroup.controls['comment'].value.replace(/<[^>]*>/g, '');

	  this.onClose.next({ isCancelled: false, comment: comment });
	  this.onClose.complete();

	  this._bsModalRef.hide();
   }

   public onCancel(): void {
	  this.onClose.next({ isCancelled: true, comment: null });
	  this.onClose.complete();

	  this._bsModalRef.hide();
   }

}
