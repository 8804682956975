import { Incident } from "./incident.model";

export class IncidentActivity {

	public id: string;

	public incidentId: string;

	public type: string;

	public subject: string;

	public description: string;

	public duration: number;

	public createdOn: Date;

	public createdBy: string;

	public createdByUsername: string;

	public modifiedOn: Date;

	public modifiedBy: string;

	public modifiedByUsername: string;

	public incident: Incident;

	public isDisplayOpen: boolean;

	public stateCode: number;
	
	public isWorkaroundIncluded: boolean;

	public workaroundDetails: string;

	public workaroundNextSteps: string;
	
	public agreedSeverityAfterWorkaround: string;
}
