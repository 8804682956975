import { Component } from '@angular/core';
import { PermissionService } from '../../services/permissions/permission.service';
import { Permission } from '../../models/permissions/permission.model';

@Component({
	selector: 'app-engineering-home',
	templateUrl: './engineering-home.component.pug',
})
export class EngineeringHomeComponent {

	constructor(public permissionsService: PermissionService) {
	}

}
