import { Article } from "./article.model";
import { Category } from "../categories/category.model";

export class TechnicalReferenceArticle
	extends Article {

	public type: string = "technicalreference";

	public content: string = null;

	public categoryId: number = null;

	public category: Category = null;

}
