import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { GridModule, PageService, PagerModule, SortService, FilterService } from '@syncfusion/ej2-angular-grids';
import { MultiSelectModule, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { BsDropdownModule, TabsModule, BsModalRef, BsModalService } from 'ngx-bootstrap';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPencilAlt, faEdit, faPlus, faTimes, faEye, faShareSquare, faArrowToBottom } from "@fortawesome/pro-solid-svg-icons";

import { ContentManagerRoutingModule } from './content-manager-routing.module';

import { SharedModule } from '../../shared/shared.module';
import { MarkdownEditorModule } from '../markdown-editor/markdown-editor.module';
import { ClassificationModule } from '../classification/classification.module';

import { UserProfileService } from '../../core/services/user-profiles/user-profile.service';

import { ManageArticlesComponent } from './components/manage-articles/manage-articles.component';
import { CreateArticlesListComponent } from './components/create-articles-list/create-articles-list.component';
import { CreateEditArticlesComponent } from './components/create-edit-articles/create-edit-articles/create-edit-articles.component';
import { LogPhrasesComponent } from './components/create-edit-articles/log-phrases/log-phrases.component';
import { AffectedVersionsComponent } from './components/create-edit-articles/affected-versions/affected-versions.component';
import { CollaboratorsComponent } from './components/create-edit-articles/collaborators/collaborators.component';

import { MarkdownPropertyEditorComponent } from './components/create-edit-articles/markdown-property-editor/markdown-property-editor.component';
import { TargetedRolesComponent } from './components/create-edit-articles/targeted-roles/targeted-roles.component';
import { ProposedNodeComponent } from './components/create-edit-articles/proposed-node/proposed-node.component';
import { TechnicalReviewerComponent } from './components/create-edit-articles/technical-reviewer/technical-reviewer.component';
import { CategorySelectorComponent } from './components/create-edit-articles/category-selector/category-selector.component';

import { PublishConfirmationComponent } from './components/publish-confirmation/publish-confirmation.component';

library.add(faPencilAlt, faEdit, faPlus, faTimes, faEye, faShareSquare, faArrowToBottom);

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		GridModule,
		PagerModule,
		BsDropdownModule,
		DatePickerModule,
		FontAwesomeModule,
		ReactiveFormsModule,
		TabsModule,
		MultiSelectModule,
		DropDownListModule,

		// ** App
		ContentManagerRoutingModule,

		SharedModule,
		MarkdownEditorModule,
		ClassificationModule
	],
	declarations: [
		CreateArticlesListComponent,
		ManageArticlesComponent,
		CreateEditArticlesComponent,
		LogPhrasesComponent,
		AffectedVersionsComponent,
		CollaboratorsComponent,
		MarkdownPropertyEditorComponent,
		TargetedRolesComponent,
		ProposedNodeComponent,
		TechnicalReviewerComponent,
		CategorySelectorComponent,
		PublishConfirmationComponent
	],
	providers: [
		PageService,
		UserProfileService,
		SortService,
		FilterService
	],
	entryComponents: [
		PublishConfirmationComponent
	]
})
export class ContentManagerModule { }
