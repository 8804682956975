import { Injectable } from '@angular/core';

import { Observable, from, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { ConfigurationService } from '../../../modules/runtime-configuration/services/configuration.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	// Original oidc code based on -- https://www.scottbrady91.com/Angular/SPA-Authentiction-using-OpenID-Connect-Angular-CLI-and-oidc-client

	private _manager = new UserManager(this.getClientSettings());

	public user: User = null;

	private _isAuthenticatedStream = new ReplaySubject<boolean>(1);

	constructor(private _configSvc: ConfigurationService) {
		this.getUser().subscribe(user => {
			if (user && !user.expired) {
				this.user = user;
				this._isAuthenticatedStream.next(true);
			} else {
				this._isAuthenticatedStream.next(false);
			}
		});
	}

	private getClientSettings(): UserManagerSettings {
		const settings = this._configSvc.configuration;

		return {
			authority: settings.sso.baseUrl,
			client_id: settings.sso.clientId,
			redirect_uri: `${settings.baseUrl}/auth-callback`,
			post_logout_redirect_uri: settings.baseUrl,
			response_type: 'id_token token',
			scope: settings.sso.scope,
			filterProtocolClaims: true,
			loadUserInfo: true
		};
	}

	public getUser(): Observable<User> {
		return from(this._manager.getUser());
	}

	public login(returnUrl: string = null): Observable<void> {
		let data: any = {
			data: returnUrl,
			acr_values: 'idp:aad'
		};

		return from(
			this._manager.clearStaleState().then(() => {
				this._manager.signinRedirect(data);
			})
		);
	}

	public loggedIn(): Observable<string> {
		return from(this._manager.signinRedirectCallback())
			.pipe(
				map<User, string>((user) => {
					this.user = user;

					this._isAuthenticatedStream.next(!!this.user && !this.user.expired);
					return user.state;
				})
			);
	}

	public renewToken(): Observable<User> {
		return from(this._manager.signinSilent());
	}

	public logout(): Observable<void> {
		return from(this._manager.signoutRedirect());
	}

	// reverted this since tihs was "working"... albeit inefficiently. will fix later
	public isAuthenticated(): Observable<boolean> {
		return this._isAuthenticatedStream.asObservable();
	}

	public getAuthorizationHeaderValue(): string {
		return (this.user && !this.user.expired ? `${this.user.token_type} ${this.user.access_token}` : null);
	}

}
