import { Component, AfterViewInit, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { CommentService } from '../../../../core/services/comments/comment.service';
import { Comment } from '../../../../core/models/comments/comment.model';
import { CommentViewModel } from '../../../../core/models/comments/comment-view.model';

@Component({
	selector: 'app-comment-threads',
	templateUrl: './comment-threads.component.html',
	styleUrls: ['./comment-threads.component.scss']
})
export class CommentThreadsComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input()
	public threadId: string;

	@Input()
	public threadTitle: string;

	@Input()
	public referenceId: string;

	@Input()
	public referenceVersion: string;

	public threadUrl: string;

	public comments: Array<Comment>;

	public commentPlaceholder: string;

	private _subscription = new Subscription();

	public commentForm = new FormGroup({
		commentInput: new FormControl('', Validators.required)
	});

	constructor(private _commentService: CommentService,
		private _location: Location) { }

	ngOnInit() {
		this.commentPlaceholder = "Please enter reply here ....";
		this.threadUrl = window.location.origin + this._location.path();
	}

	public ngAfterViewInit() {
		this.getComments();
	}

	public cancelCommenting(event: any) {
	}

	public submit(): void {
		var newCommentModel: CommentViewModel = {
			id: 0,
			userId: "",
			isAnonymous: false,
			content: this.commentForm.value.commentInput,
			threadId: this.threadId,
			parentId: 0,
			userName: "",
			userEmail: "",
			url: this.threadUrl,
			title: this.threadTitle,
			referenceId: this.referenceId,
			referenceVersion: this.referenceVersion
		};
		
		this._subscription.add(this._commentService.addComment(newCommentModel)
			.subscribe(response => {
				this.comments.push(response);
				this.commentForm.setValue({ commentInput: "" });
			})
		);
	}

	public getComments() {
		this._subscription.add(this._commentService.getComments(this.threadId)
			.subscribe(response => {
				this.comments = response;
			})
		);
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

}
