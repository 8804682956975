import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ODataResult } from '../../models/oData/odata-result.model';
import { User } from '../../models/users/user.model';

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	private _urlBase: string = "/odata/User";

	constructor(private _http: HttpClient) {
	}

	public searchUsers(filter: string): Observable<Array<User>> {
		const url = `${this._urlBase}?$select=id,userName&$orderby=userName&$filter=StartsWith(userName,'${filter}')`;

		return this._http
			.get<ODataResult<Array<User>>>(url)
			.pipe(
				map(result => {
					return result.value.map(user => {
						let ui = new User();
						Object.assign(ui, user);

						return ui;
					});
				})
			);
	}

	public getUserById(filter: string): Observable<User> {
		// TODO: Fix this to be using eq instead of filter based on id, can't seem to get this to work
		const url = `${this._urlBase}?$select=id,userName&$filter=StartsWith(id,'${filter}')`;

		return this._http
			.get<ODataResult<User>>(url)
			.pipe(
				map(result => {
					return result.value;
				})
			);
	}

}
