import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { finalize } from 'rxjs/operators';

import { SpinnerService } from '../services/spinner/spinner.service';

@Injectable({
	providedIn: 'root'
})
export class SpinnerInterceptor implements HttpInterceptor {

	constructor(private _spinnerService: SpinnerService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!(request.headers.keys().indexOf('X-Hide-Progress') >= 0 && request.headers.get('X-Hide-Progress') == 'true')) {
			this._spinnerService.show("Please wait...");
		}

		return next.handle(request)
			.pipe(
				map(event => {
					return event;
				}),
				finalize(() => {
					if (!(request.headers.keys().indexOf('X-Hide-Progress') >= 0 && request.headers.get('X-Hide-Progress') == 'true')) {
						this._spinnerService.hide();
					}
				})
			)
	}

}
