import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Application } from '../../models/applications/application.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

	private _urlOdataBase: string = "odata/applications";

	constructor(private _httpClient: HttpClient) { }

	public getEnabledApplications(): Observable<Array<Application>> {
		return this._httpClient.get<Array<Application>>(this._urlOdataBase + `?$filter=isEnabled eq true & $orderBy=name asc`);
	}

	public getApplicationById(id: string): Observable<Application> {
		return this._httpClient.get<Application>(this._urlOdataBase + `?$filter=id eq ${id}`);
	}

}
