import { Component, OnInit, Input, Output, SimpleChange, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SpinnerService } from '../../../../../core/services/spinner/spinner.service';
import { IncidentService } from '../../../../../core/services/incidents/incident.service';

import { Incident } from '../../../../../core/models/incidents/incident.model';
import { Team } from '../../../../../core/models/teams/team.model';

import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

import { IncidentQualityVote } from '../../../../../core/models/incidents/incident-quality-vote.model';
import { ShareComponent } from '../../../../../shared/components/share/share.component';
import { CaseSubscription } from '../../../../../core/models/incidents/incident-subscription.model';
import { UserProfileService } from '../../../../../core/services/user-profiles/user-profile.service';
import { UserProfile } from '../../../../../core/models/user-profiles/user-profile.model';
import { Contact } from '../../../../../core/models/incidents/incident-contact.model';
import { IncidentInternalNoteComponent } from '../incident-internal-note/incident-internal-note.component';
import { IncidentEscalationConfirmationComponent } from '../incident-escalation-confirmation/incident-escalation-confirmation.component';

@Component({
	selector: 'app-incident-viewer',
	templateUrl: './incident-viewer.component.pug',
	styleUrls: ['./incident-viewer.component.scss']
})
export class IncidentViewerComponent implements OnInit, OnDestroy {

	private _subscription = new Subscription();

	private _modalRef: BsModalRef;

	@Input()
	public incidentId: string;

	@Input()
	public searchTerms: string;

	public isValid: boolean = true;

	public lastUpdated: Date;

	public incident: Incident;

	public isFieldEscalated: boolean = false;

	public isActiveState: boolean = false;

	public userVote: number = 0;

	public votedCount: number = 0;
	
	public profile: UserProfile;

	public isCaseSubscribe:boolean = false;

	public isAccountSubscribe:boolean = false;

	public contactId: string;

	public fullName: string[];

	public isMetallic: boolean = false;

	constructor(private _route: ActivatedRoute, private _profileService: UserProfileService, 
		private _incidentService: IncidentService,
		private _modalService: BsModalService) {
	}

	ngOnInit() {
		this.getProfile();
		if (this.incidentId) {
			this.getData();
		} else {
			this._subscription.add(
				this._route.params.subscribe(params => {
					this.incidentId = (params['incidentId'] ? params['incidentId'] : null);

					this.getData();
				})
			);
		}
	}

	private refresh(): void {
		this.getData();
	}

	private getData(): void {
		if (!this.incidentId) {
			console.log('No incident id has been specified. Aborting call.');

			return;
		}

		this.isValid = true;

		this.getIncidentData();
		this.getUsersQualityVote();
		this.getQualityVoteScore();
		this.isLastActivityFieldEscalation();
		this.getCaseSubscriptions();
	}

	private getIncidentData(): void {
		if (!this.incidentId) {
			return;
		}

		this._incidentService.getIncident(this.incidentId).subscribe(
			response => {
				this.incident = response;

				this.isActiveState = this.incident.state.toLowerCase() == 'active' ? true : false;
				let metallicCase = this.incident.supportType && this.incident.supportType.match(/Metallic/i);
				this.isMetallic = metallicCase && metallicCase.length > 0 ? true : false;

				if (!this.isActiveState) {
					// go get the Resolution
					this._incidentService.getResolution(this.incidentId).subscribe(
						resolutionResponse => {
							this.incident.resolution = resolutionResponse;
							if (this.incident.resolution.classificationText !== null) {
								this.incident.resolution.classificationText = this.incident.resolution.classificationText.split("\\").join(">");
							}
						});
				}
				else if (this.incident.taxonomyPath !== null) {
						this.incident.taxonomyPath = this.incident.taxonomyPath.split("\\").join(">");
					}

				this.lastUpdated = new Date();
				this.getAccountSubscriptions();
			},
			error => {
				this.isValid = false;
			}
		);
	}

	private isLastActivityFieldEscalation(): void {
		if (!this.incidentId) {
			return;
		}

		this._subscription.add(
			this._incidentService.isLastActivityFieldEscalation(this.incidentId).subscribe(result => {
				this.isFieldEscalated = result;
			})
		);
	}

	private getUsersQualityVote() {
		if (!this.incidentId) {
			return;
		}

		this._subscription.add(
			this._incidentService.getIncidentQualityVote(this.incidentId)
				.subscribe(response => {
					if (response) {
						this.userVote = response.vote;
					}
				})
		);
	}

	private getQualityVoteScore() {
		if (!this.incidentId) {
			return;
		}

		this._subscription.add(
			this._incidentService.getIncidentQualityVoteScore(this.incidentId)
				.subscribe(result => {
					this.votedCount = result;
				})
		);
	}

	private voteSubmitted(liked: boolean) {
		this._subscription.add(
			this._incidentService.voteAsync(this.incidentId, this.userVote)
				.subscribe(result => {
					this.userVote = result.vote;

					// check if the user can edit this article
					this._subscription.add(
						this._incidentService.getIncidentQualityVoteScore(this.incidentId)
							.subscribe(result => {
								this.votedCount = result;
							})
					);
				})
		);

	}

	private setUserVote(liked: boolean) {
		if (liked) {
			if (this.userVote <= 0) {
				this.userVote = 1
			}
			else {
				this.userVote = 0
			}
		}
		else {
			if (this.userVote >= 0) {
				this.userVote = -1
			}
			else {
				this.userVote = 0;
			}
		}
	}

	public escalation() {
		const initialState = {
			id: this.incidentId,
			customerName: this.incident.contactName,
			canEscalate: !this.isFieldEscalated && this.isActiveState,
			title: this.incident.title,
			description: this.incident.description,
			status: this.incident.status
		};

		this._modalRef = this._modalService.show(IncidentEscalationConfirmationComponent, { initialState, class: 'modal-lg' });
		this._subscription.add(this._modalRef.content.isEscalated.subscribe(result => {
			this.isFieldEscalated = result;
		}));
	}

	public onVoted(liked: any) {
		console.log("incident-viewer.component.onVoted");

		this.setUserVote(liked);

		this.voteSubmitted(liked);

	}

	public share() {
		let url = `${window.location.origin}/incident/details/${this.incident.ticketNumber}`;

		const initialState = {
			subject: 'Incident Shared',
			type: 'Incident',
			url: url
		};

		const modalParams = { initialState, class: 'modal-lg' };
		this._modalRef = this._modalService.show(ShareComponent, modalParams);
	}

	public reindex(): void {
		if (!this.incidentId) {
			return;
		}

		this._incidentService.reindexIncident(this.incidentId).subscribe();
	}

	public getProfile():void {
		this._subscription.add(
		  this._profileService.getMyProfile()
		  .subscribe(data => {
			this.profile =  data;
			this.getSecondaryContact();
		  })
		)
	  }

	public getSecondaryContact() {
		let response = this._incidentService.getSecondaryContacts(this.profile.email).then(data => {
		  if(data.isSuccess){
			if(data.response && data.response.length !== 0){
			  this.contactId = data.response[0].id;
			}else {
			  this.addSecondaryContact();
			}
		  }else {
			throw new Error(data.error[0]);
		  }
		})
	}

	public addSecondaryContact(){
		let contact = new Contact();
		this.fullName = this.profile.displayName && this.profile.displayName.split(" ");
		if(this.fullName[0]){
		  contact.firstName = this.fullName[0];
		}
		if(this.fullName[1]){
		  contact.lastName = this.fullName[1];
		}
		contact.emailAddress = this.profile.email;
	
		let response = this._incidentService.createContact(contact).then(data => {
		  if(data.isSuccess){
			if(data.response){
			  this.contactId = data.response;
			}
		  }else {
			throw new Error(data.error[0]);
		  }
		});
	  }

	public getCaseSubscriptions(){
		if (!this.incidentId) {
			return;
		}

		this._subscription.add(
			this._incidentService.getCaseSubscriptions(this.incidentId)
				.subscribe(result => {
					let obj = result.value.find(item => item.emailAddress === this.profile.email);
					if(obj){
						this.isCaseSubscribe = true;
					}
			})
		);
	}

	public subscribeToCase() {
		let request = new CaseSubscription();
		request.id = this.contactId;
		this._subscription.add(
			this._incidentService.subscribeToCase(this.incident.id, request)
				.subscribe(result => {
					this.isCaseSubscribe = true;
				})
		);
	}

	public unsubscribeToCase() {
		let request = new CaseSubscription();
		request.id = this.contactId;
		this._subscription.add(
			this._incidentService.unsubscribeToCase(this.incident.id, request)
				.subscribe(result => {
					this.isCaseSubscribe = false;
				})
		);
	}

	public getAccountSubscriptions(){
		this._subscription.add(
			this._incidentService.getAccountSubscriptions(this.incident.accountId)
				.subscribe(result => {
					let obj = result.value.find(item => item.emailAddress === this.profile.email);
					if(obj){
						this.isAccountSubscribe = true;
					}
			})
		);
	}

	public subscribeToAccount() {
		let request = new CaseSubscription();
		request.id = this.contactId;
		this._subscription.add(
			this._incidentService.subscribeToAccount(this.incident.accountId, request)
				.subscribe(result => {
					this.isAccountSubscribe = true;
				})
		);
	}

	public unsubscribeToAccount() {
		let request = new CaseSubscription();
		request.id = this.contactId;
		this._subscription.add(
			this._incidentService.unsubscribeToAccount(this.incident.accountId, request)
				.subscribe(result => {
					this.isAccountSubscribe = false;
				})
		);
	}

	public internalNote(){
		const initialState = {
			id: this.incidentId
		};
		this._modalRef = this._modalService.show(IncidentInternalNoteComponent, {initialState, class: 'modal-lg' });
	}
	
	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

}
