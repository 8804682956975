import { Component, OnInit, ElementRef, Output, Input, EventEmitter, SimpleChange, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, AbstractControl, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-tri-state-checkbox',
	templateUrl: './tri-state-checkbox.component.pug',
	styleUrls: ['./tri-state-checkbox.component.scss']
})
export class TriStateCheckboxComponent implements OnInit, OnDestroy {

	@Input()
	public form: FormGroup;

	@Input()
	public childrenKey: string;

	@Input()
	public appId: string;

	@Output("onChange")
	public onChangeEventEmitter = new EventEmitter<boolean>();

	public indeterminate: boolean = false;

	public isChecked: boolean = false;

	private _childrenControls = new Array<AbstractControl>();

	private _changing: boolean = false;

	private _totalFields: number = 0;

	private _subscriptions = new Subscription();

	constructor() {
	}
	
	ngOnInit() {
		for (let ctrl in this.form.controls) {
			if (ctrl.indexOf(`${this.appId}-`) > -1) {
				let majorVer = ctrl.slice(37).split('-');
				if(majorVer[0] == this.childrenKey){
					this._childrenControls.push(this.form.get(ctrl));
				}
			}
		}

		this.childrenChanged();
	}

	public parentChanged(): void {
		this.isChecked = !this.isChecked;

		if (!this._changing) {
			this._changing = true;
			this._childrenControls.forEach(child => {
				child.patchValue(this.isChecked);
			});

			this.onChangeEventEmitter.emit(true);
			this.indeterminate = false;
			this._changing = false;
		}
	}

	private childrenChanged(): void {
		this._childrenControls.forEach(child => {
			this._subscriptions.add(
				child.valueChanges.subscribe(val => {
					if (!this._changing) {
						this._changing = true;

						let totalChecked = this.checkedCount();

						// if all values are checked then "parent" checkbox should be checked
						// if any checkbox is checked then checkbox should be indetermine
						// if no checkbox is checked, then indeterminate is false
						if (totalChecked == this._totalFields) {
							this.indeterminate = false;
							this.isChecked = true;
						} else if (totalChecked >= 1) {
							this.indeterminate = true;
							this.isChecked = false;
						} else {
							this.indeterminate = false;
							this.isChecked = false;
						}

						this._changing = false;
					}
				})
			);
		});
	}

	private checkedCount(): number {
		let totalChecked = 0;
		this._totalFields = 0;

		this._childrenControls.forEach(child => {
			totalChecked += child.value ? 1 : 0;
			this._totalFields++;
		});

		return totalChecked;
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

}
