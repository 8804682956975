import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AlertService {

	// should this be basic Subject
	private _messageStream = new Subject<string>();

	constructor() {
	}

	public show(message: string) {
		this._messageStream.next(message);
	}

	public messages(): Observable<string> {
		return this._messageStream.asObservable();
	}

}
