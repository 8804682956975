import { Component, Input, forwardRef } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { CheckboxItem } from '../../models/checkbox-item.model';


var noOp = () => { };

export const CHECKBOX_GROUP_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CheckboxGroupComponent),
	multi: true,
};

@Component({
	selector: 'app-checkbox-group',
	templateUrl: './checkbox-group.component.pug',
	styleUrls: ['./checkbox-group.component.scss'],
	providers: [CHECKBOX_GROUP_VALUE_ACCESSOR]
})
export class CheckboxGroupComponent implements ControlValueAccessor {

	private onTouchedCallback: () => void = noOp;

	private onChangeCallback: (_: any) => void = noOp;

	private _model: any;

	//@Input("group")
	//public formGroup: FormGroup;

	@Input()
	groupLabel: string = "11";

	@Input()
	includeSelectAll: boolean = true;

	@Input()
	options: Array<CheckboxItem> = null;

	public get model(): any {
		return this._model;
	}

	public set(value: any) {
		this._model = value;
		this.onChangeCallback(this._model);
	}

	public writeValue(value: any): void {
		this._model = value;
	}

	public registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	public addOrRemove(value: any): void {
		if (this.contains(value)) {
			this.remove(value);
		}
		else {
			this.add(value);
		}
	}

	public contains(value: any): boolean {
		if (this._model instanceof Array) {
			return this._model.indexOf(value) > -1;
		}
		else if (!!this._model) {
			return this._model == value;
		}

		return false;
	}

	private add(value: any): void {
		if (!this.contains(value)) {
			if (this._model instanceof Array) {
				this._model.push(value);
			}
			else {
				this._model = value;
			}

			this.onChangeCallback(this._model);
		}
	}

	private remove(value: any) {
		const index = this._model.indexOf(value);

		if (!this._model || index < 0) {
			return;
		}

		this._model.splice(index, 1);

		this.onChangeCallback(this._model);
	}

}
