import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, NavigationStart, GuardsCheckEnd, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';

import { Observable, Subscription, pipe } from 'rxjs';

import { TabsetComponent } from 'ngx-bootstrap';

import { CanComponentDeactivate } from '../../../../core/services/can-deactivate/can-deactivate.guard';

import { SearchService } from '../../../../core/services/searches/search.service';
import { PermissionService } from '../../../../core/services/permissions/permission.service';
import { UserProfileService } from '../../../../core/services/user-profiles/user-profile.service';

import { SearchRequest } from '../../../../core/models/searches/search.request.model';
import { KeyValue } from '../../../../core/models/searches/key-value.model';
import { SearchRequestFacet } from '../../../../core/models/searches/search-request-facet.model';
import { UnifiedSearchResponse } from '../../../../core/models/searches/unified-search-response.model';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.pug',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy, CanComponentDeactivate {

	@ViewChild('staticTabs')
	public staticTabs: TabsetComponent;

	public dynamicTabsList = new Array<any>();

	public searchForm: FormGroup;

	public searchRequest: SearchRequest;

	public hideSearchTab: boolean = false;

	public unifiedSearchResponse: UnifiedSearchResponse;

	public incidentTabHeader: string;

	public totalRecords: number = 0;

	public displaySearchWarning: boolean = false;

	private _subscriptions = new Subscription();

	private _activeTab: string = "incidents";

	constructor(private _route: ActivatedRoute,
		private _searchService: SearchService,
		private _router: Router,
		private _formBuilder: FormBuilder,
		private _location: Location,
		private _userProfileService: UserProfileService,
		public permissionsService: PermissionService) {
		this.createForm();
	}

	ngOnInit(): void {
		this._userProfileService.getMyProfile().subscribe(response => {
			if (response) {
				// searchDetailsAction 2 is open in new application tab.. we only want to show the tabs if we are opening in a new application tab
				this.hideSearchTab = response.searchDetailsAction != 2;
			}
			else {
				// No Profile return so assume non-tabbed searching
				this.hideSearchTab = true;
			}
		});

		this.searchRequest = new SearchRequest();

		// TODO: Make adjustments to look for both alias values and default property values
		// Move to a private method so it can be unit tested
		// Separate updating the searchRequest object and updating the form (possibly automatically through change detection)
		let queryParamMap = this._route.snapshot.queryParamMap;

		if (queryParamMap.get('q') != undefined && queryParamMap.get('q') != null && queryParamMap.get('q') != '') {
			this.searchRequest.searchTerms = queryParamMap.get('q');
			this.searchForm.get('searchTerms').setValue(queryParamMap.get('q'));
		}

		let facetsToMap = queryParamMap.get('fq');

		if (facetsToMap != undefined && facetsToMap != null && facetsToMap != '') {
			let facetToAdd = new SearchRequestFacet();
			let facetsList = facetsToMap.split(',');
			for (var i = 0; i < facetsList.length; i++) {
				facetToAdd.field = facetsList[i].split(':')[0];
				facetToAdd.value = facetsList[i].split(':')[1];
				this.searchRequest.facets.push(Object.assign(new SearchRequestFacet(), facetToAdd));
			}
		}

		let explicitSearchTermsKeys = queryParamMap.getAll('explicitSearchTermsKeys');
		let explicitSearchTermsValues = queryParamMap.getAll('explicitSearchTermsValues');

		if (explicitSearchTermsKeys && explicitSearchTermsValues) {
			if (explicitSearchTermsKeys.length == explicitSearchTermsValues.length) {
				var explicitSearchTermToAdd = new KeyValue();
				for (var i = 0; i < explicitSearchTermsKeys.length; i++) {
					explicitSearchTermToAdd.key = explicitSearchTermsKeys[i];
					explicitSearchTermToAdd.value = explicitSearchTermsValues[i];
					this.searchRequest.explicitSearchTerms.push(Object.assign(new KeyValue(), explicitSearchTermToAdd));
					this.displaySearchWarning = true;
				}
			}
		}

		this.getSearchResults(this.searchRequest);
	}

	public selectTab(tabId: number) {
		this.staticTabs.tabs[tabId].active = true;
	}

	public onSubmit(): void {
		this.executeSearch();
	}

	private executeSearch(): void {
		if (this.searchRequest.sortOrder && this.searchRequest.sortOrder.length) {
			this.searchForm.patchValue({ "sortOrder": this.searchRequest.sortOrder });
		}

		this.getSearchResults(this.searchForm.value);
		this.displaySearchWarning = false;
	}

	public clearCriteria(): void {
		this.searchForm.patchValue({
			searchTerms: '',
			facets: ''
		});

		this.executeSearch();
	}

	public searchTermsSet(): boolean {
		let searchTerms: string = this.searchForm.get('searchTerms').value;
		return searchTerms && searchTerms.trim().length > 0;
	}

	public isFacetValueSelected(key: string, value: string): boolean {
		if (!this.searchRequest || !this.searchRequest.facets) {
			return false;
		}

		var facet = this.searchRequest.facets.filter(function (obj) {
			return (obj.field === key && obj.value === value);
		});

		// checks that facet is not undefined and that the length of the facet is not 0
		return (!!facet && !!facet.length);
	}

	public isFacetKeySelected(key: string): boolean {
		if (!this.searchRequest || !this.searchRequest.facets) {
			return false;
		}

		var facet = this.searchRequest.facets.filter(function (obj) {
			return (obj.field === key);
		});

		return facet && facet.length > 0;
	}

	public toggleFacet(key: string, value: string): void {
		var selectedFacet = new SearchRequestFacet();
		selectedFacet.field = key;
		selectedFacet.value = value;
		selectedFacet.clusivity = 0

		if (!this.searchRequest.facets) {
			this.searchRequest.facets = new Array<any>();
		}

		var existingFacet = this.searchRequest.facets.filter(obj => {
			return (obj.field === key && obj.value === value);
		})[0];

		if (!existingFacet) {
			this.searchRequest.facets.push(selectedFacet);
		}
		else {
			// removes the facet if it exists
			this.searchRequest.facets = this.searchRequest.facets.filter(obj => {
				return !(obj.field == key && obj.value == value);
			});
		}
		this.searchForm.patchValue({ 'facets': this.searchRequest.facets });

		this.getSearchResults(this.searchRequest);
	}

	public responseHasResults(): boolean {
		return this.unifiedSearchResponse && this.unifiedSearchResponse.request
			&& (this.unifiedSearchResponse.request.searchTerms || (this.unifiedSearchResponse.request.facets && !!this.unifiedSearchResponse.request.facets.length))
			&& !!this.unifiedSearchResponse.totalMatching;
	}

	public responseHasNotices(): boolean {
		return this.unifiedSearchResponse &&
			!!this.unifiedSearchResponse.notices && !!this.unifiedSearchResponse.notices.length;
	}

	public setSortField(field: string): void {
		if (!!this.searchRequest.sortOrder.length) {
			this.searchRequest.sortOrder[0].field = field;
		} else {
			this.searchRequest.sortOrder.push({ field: field, direction: 0 });
		}

		this.getSearchResults(this.searchRequest);
	}

	public setSortDirection(direction: number): void {
		if (!!this.searchRequest.sortOrder.length) {
			this.searchRequest.sortOrder[0].direction = direction;
		} else {
			this.searchRequest.sortOrder.push({ field: '', direction: direction });
		}

		this.getSearchResults(this.searchRequest);
	}

	public setPageSize(size: number): void {
		this.searchRequest.pageSize = size;

		this.getSearchResults(this.searchRequest);
	}

	public change(evt): void {
		if (evt && evt.currentPage) {
			this.searchRequest.pageIndex = evt.currentPage;
			this.getSearchResults(this.searchRequest);
		}
	}

	public activeTab(tab: string): void {
		this._activeTab = tab;
		this.setTotalRecords();

		if (this.totalRecords) {
			if (this.searchRequest.pageIndex > Math.ceil(this.totalRecords / this.searchRequest.pageSize)) {
				this.searchRequest.pageIndex = Math.ceil(this.totalRecords / this.searchRequest.pageSize);
			}
		}
	}

	public onTabRemoved(detail: any): void {
		this.dynamicTabsList = this.dynamicTabsList.filter(x => x != detail);
	}

	public canDeactivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState: RouterStateSnapshot): Observable<boolean> {
		return new Observable<boolean>(observer => {
			this._subscriptions.add(
				this._userProfileService.getMyProfile().subscribe(response => {
					if (response) {
						let searchDetailsActions = response.searchDetailsAction;

						let nextRoute = nextState.root.firstChild;

						// if the user wants to have tabbed search (in app or browser) and they are navigating to a article or incident details page, then we are going to return false
						// and open the page how we want to open it
						if ((searchDetailsActions == 1 || searchDetailsActions == 2)
							&& (nextRoute.routeConfig.path == "article/details/:id/:version" || nextRoute.routeConfig.path == "incident/details/:incidentId" || nextRoute.routeConfig.path == "psr/details/:id")
						) {
							// 1 is open in a new browser tab
							if (searchDetailsActions == 1) {
								let re = new RegExp(/^.*\//);
								let baseUrl = re.exec(window.location.href)[0];
								let detailsUrl = nextRoute.url.map(segment => segment.path).join("/");

								let urlTree = this._router.createUrlTree([detailsUrl], { queryParams: { q: this.searchRequest.searchTerms } });
								// adds the search terms to the keyword since we want to use the keyword to highlight the word found in the activity
								window.open(`${baseUrl}${urlTree.toString()}`);
							}

							// 2 is open in new application tab
							if (searchDetailsActions == 2) {
								let tabType = null;
								switch (nextRoute.routeConfig.path) {
									case 'article/details/:id/:version': {
										tabType = 'article';
										break;
									}
									case 'incident/details/:incidentId': {
										tabType = 'incident';
										break;
									}
									case 'psr/details/:id': {
										tabType = 'psr';
										break;
									}
									default: {
										console.log('Unsupported tabbed results path detected');
										break;
									}
								}

								let tabDetails = {
									type: tabType,
									params: Object.create(nextRoute.params),
									active: true
								};

								this.dynamicTabsList.push(tabDetails);
							}

							observer.next(false);
							observer.complete();
							return false;
						}
						else {
							observer.next(true);
							observer.complete();
							return true;
						}
					}
					else {
						// No profile so just let user navigate away
						observer.next(true);
						observer.complete();
						return true;
					}
				})
			);
		});
	}

	private getSearchResults(searchRequest: SearchRequest): void {
		// if search terms exist, and search terms has non-whitespace text, or if facets exist and facets are selected
		// TODO: need to update page size when pagination was needed
		if ((this.searchRequest.searchTerms && !!this.searchRequest.searchTerms.trim().length) || (this.searchRequest.facets && !!this.searchRequest.facets.length)) {
			this.searchRequest.pageSize = this.searchRequest.pageSize ? this.searchRequest.pageSize : 10;
		} else {
			this.searchRequest.pageSize = 0;
		}

		if (searchRequest.sortOrder && !searchRequest.sortOrder.length) {
			searchRequest.sortOrder.push({ field: 'score', direction: 1 });
		}

		this._subscriptions.add(
			this._searchService.getSearchResults(searchRequest).subscribe(results => {
				// this check was put in place in case we want to do soemthing when the result comes back null or undefined
				if (results) {
					this.unifiedSearchResponse = results;
					if (!this.unifiedSearchResponse.request) {
						this.searchRequest = new SearchRequest();
					} else {
						// make a copy of the request so we have something to send back as new request
						this.searchRequest = (JSON.parse(JSON.stringify(this.unifiedSearchResponse.request)));

						this.setTotalRecords();
					}
				} else {
					this.unifiedSearchResponse = undefined;
				}

				// HttpParams is immutable, we need to create a new HttpParams with what we want instead of deleting what we don't want
				let desiredSearchParamsKeys = this._searchService.params.keys().filter(x => x.toLowerCase().indexOf('explicitsearch'));

				let kvp = new Array<any>();

				for (var i = 0; i < desiredSearchParamsKeys.length; i++) {
					let paramName = desiredSearchParamsKeys[i];
					let valuesToAdd = this._searchService.params.getAll(paramName);

					// for things like facets and sortOrder, we could have multiple values in the query params
					// we can't do add (which is essentially an overload for append for all value types)
					// because appending will not overwrite the original value
					let combinedValue = "";
					for (var v = 0; v < valuesToAdd.length; v++) {
						if (valuesToAdd[v] != undefined && valuesToAdd[v] != null && valuesToAdd[v] != '') {
							combinedValue = combinedValue == "" ? combinedValue.concat(valuesToAdd[v]) : combinedValue.concat(',', valuesToAdd[v]);
						}
					}

					if (combinedValue != '') {
						kvp[paramName] = combinedValue;
					}
				}

				this._router.navigate(['/search'], { queryParams: kvp });
			})
		);
	}

	private setTotalRecords() {
		if (this.unifiedSearchResponse
			&& !!this.unifiedSearchResponse.resultSetsTotalMatching
			&& typeof (this.unifiedSearchResponse.resultSetsTotalMatching[this._activeTab]) != "undefined") {
			this.totalRecords = this.unifiedSearchResponse.resultSetsTotalMatching[this._activeTab];
		}
	}

	private createForm(): void {
		this.searchForm = this._formBuilder.group({
			searchTerms: '',
			facets: '',
			sortOrder: this._formBuilder.array([
				this._formBuilder.control({ field: 'score', direction: 1 })
			])
		});
	}

	public ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
