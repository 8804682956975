export class InternalNote{

    public description: string;

    public taskTypeId: string;

    public incidentId: string;

    public emailAddress: string;

	public userFullName: string;
    
}