import { Injectable } from '@angular/core';

import { Settings } from '../models/settings';

@Injectable({
	providedIn: 'root'
})
export class ConfigurationService {

	constructor() { }

	public loadConfiguration(): Promise<any> {
		return new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			xhr.open('GET', '/api/settings');

			xhr.addEventListener('readystatechange', () => {
				if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
					this.configuration = JSON.parse(xhr.responseText);

					console.info('Configuration loaded successfully.');

					resolve(this.configuration);
				}
				else if (xhr.readyState === XMLHttpRequest.DONE) {
					console.error('Unable to load configuration.');

					reject();
				}
			});

			console.log('Loading configuration...');

			xhr.send(null);
		});
	}

	public configuration: Settings;

}
