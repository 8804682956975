import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.pug',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

	@Input()
	public title: string = "Confirmation";

	@Input()
	public message: string = "Are you sure you want to complete this action?";

	@Input()
	public okText: string = "Yes";

	@Input()
	public cancelText: string = "No";

	@Output()
	public onResponseSelected = new EventEmitter<boolean>();

	constructor(private _bsModalRef: BsModalRef) {
	}

	public response(isPositive: boolean): void {
		this.onResponseSelected.emit(isPositive);
		this._bsModalRef.hide();
	}

}
