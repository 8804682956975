import { Component, Host, Input } from '@angular/core';

import { CheckboxGroupComponent } from '../checkbox-group/checkbox-group.component';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.pug',
	styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {

	@Input()
	value: any;

	constructor(@Host() private _checkboxGroup: CheckboxGroupComponent) {
	}

	public toggleCheck(): void {
		this._checkboxGroup.addOrRemove(this.value);
	}

	public isChecked(): boolean {
		return this._checkboxGroup.contains(this.value);
	}

}
