import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { DataManager, ODataV4Adaptor, Query } from '@syncfusion/ej2-data';

import { AuthService } from '../../../../../core/services/auth/auth.service';
import { Article } from '../../../../../core/models/articles/article.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-technical-reviewer',
  templateUrl: './technical-reviewer.component.pug',
  styleUrls: ['./technical-reviewer.component.scss']
})
export class TechnicalReviewerComponent implements OnInit {

	@Input("group")
	public formGroup: FormGroup;

	@Input("article")
	public article: Article;

	public remoteFields = { text: 'userName', value: 'id' };

	public data: DataManager;

	public query: Query;

	private _subscriptions = new Subscription();

	constructor(private _fb: FormBuilder, private _authService: AuthService) {
	}

	ngOnInit() {
		this.bindData();
		this.createForm();
	}

	private createForm(): void {
		if (this.formGroup) {
			this.formGroup.addControl('technicalReviewerIdForm', this._fb.control(''));
			this.formGroup.addControl("technicalReviewerValidator", new FormControl(false, Validators.requiredTrue));

			this._subscriptions.add(
				this.formGroup.get("technicalReviewerIdForm").valueChanges
					.subscribe(value => {
						if (value.length > 0) {
							this.formGroup.patchValue({ "technicalReviewerValidator": true });
						}
						else {
							this.formGroup.patchValue({ "technicalReviewerValidator": false });
						}
						this.article.technicalReviewerId = value[0];
					})
			);
		}

		if (this.article && this.article.technicalReviewerId) {
			this.formGroup.patchValue({
				"technicalReviewerIdForm": new Array<string>(this.article.technicalReviewerId)
			});
		}
	}

	private bindData(): void {
		this.data = new DataManager({
			url: '/odata/User',
			adaptor: new ODataV4Adaptor,
			crossDomain: false,
			headers: [{ 'Authorization': this._authService.getAuthorizationHeaderValue() }]
		});

		this.query = new Query().select(['id', 'userName']);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

}
