import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { VotingDislikeCommentComponent } from '../voting-dislike-comment/voting-dislike-comment.component';
import { ArticleQualityVote } from '../../../../core/models/articles/article-quality-vote.model';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-voting',
	templateUrl: './voting.component.pug',
	styleUrls: ['./voting.component.scss']
})
export class VotingComponent implements OnInit {

	@Input()
	public count: number;

	@Input()
	public isDislikeComment: boolean = false;

	@Input()
	public userVote: number;

	@Input()
	public disabled: boolean = false;

	@Output()
	public voted = new EventEmitter<any>();

	public modalRef: BsModalRef;

	private _subscription = new Subscription();

	constructor(private _modalService: BsModalService) { }

	ngOnInit() {		
	}

	vote(liked: boolean) {
		if (!liked) {
			if (this.isDislikeComment) {
				if (this.userVote >= 0) {
					this.modalRef = this._modalService.show(VotingDislikeCommentComponent);
					this._subscription.add(this.modalRef.content.onClose.subscribe(result => {
						this.voted.emit(result);
					}));
				}
				else {
					let result = this.setResultObject(false);

					this.voted.emit(result);
				}
			}
			else {
				this.voted.emit(liked);
			}
		}
		else {
			if (this.isDislikeComment) {
				let result = this.setResultObject(true);

				this.voted.emit(result);
			}
			else {
				this.voted.emit(liked);
			}
		}

	}

	private setResultObject(vote: boolean): any {
		let result: any = {
			comment: "",
			isAnonymous: false,
			vote: vote,
			isCancel: false
		};

		return result;
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

}
