import { Component, OnInit } from '@angular/core';

import { ArticleService } from '../../../../core/services/articles/article.service';

import { ArticleDefinition } from '../../../../core/models/articles/article-definition.model';

@Component({
   selector: 'app-create-articles-list',
   templateUrl: './create-articles-list.component.pug',
   styleUrls: ['./create-articles-list.component.scss']
})
export class CreateArticlesListComponent implements OnInit {

   public articleDefinitions: Array<ArticleDefinition>;

   constructor(private _articleService: ArticleService) {
   }

   ngOnInit() {
	  this._articleService.getArticleTypes().subscribe(response => {
		 this.articleDefinitions = response ? response.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) : null;
	  });
   }

}
