import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, AbstractControl, ValidatorFn, ValidationErrors, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ClassificationSelectionService } from '../../../../classification/services/classification-selection/classification-selection.service';

import { GraphNode } from '../../../../classification/models/graph-node.model';

@Component({
	selector: 'app-proposed-node',
	templateUrl: './proposed-node.component.pug',
	styleUrls: ['./proposed-node.component.scss']
})
export class ProposedNodeComponent implements OnInit, OnDestroy {

	@Input("group")
	public formGroup: FormGroup;

	private _subscriptions = new Subscription();

	private _commonIssueNodeSelected: boolean | null = null;

	constructor(private _nodeSelectedService: ClassificationSelectionService) {
		this._subscriptions.add(
			this._nodeSelectedService.getNode()
				.subscribe(node => {
					this.onClassificationNodeSelected(node);
				})
		);
	}

	ngOnInit() {
		this.updateForm();
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

	private updateForm(): void {

		// custom function for determining if Proposed Common Issue Title is valid
		function requiredIfCommonIssueNotSelected(scope: any): ValidatorFn {
			return (control: AbstractControl): ValidationErrors | null => {
				// wish there was a cleaner way to inject the boolean flag that indicates if the selected node
				// is a common issue or not

				// Validation rules -->
				// - if node is a Common Issue then Proposed Common Issue must be blank
				// - if node is NOT a Common Issue then Proposed Common Issue must have a value
				// - if no node is selected then anything is valid (no real way to know this based on a boolean)

				if (scope._commonIssueNodeSelected == null) {
					return null;
				}

				if (scope._commonIssueNodeSelected && !control.value) {
					return null;
				}

				if (!scope._commonIssueNodeSelected && control.value) {
					return null;
				}

				// we are invalid
				let message: string = (scope._commonIssueNodeSelected ?
					'Proposing a new Common Issue is not allowed when the selected Classification is already a Common Issue'
					: 'Proposing a new Common Issue is required when the selected Classification is not a Common Issue');

				return { proposedNode: message }
			}
		}

		if (this.formGroup) {
			this.formGroup.addControl('proposedCommonIssueTitle', new FormControl('', requiredIfCommonIssueNotSelected(this)));
		}
	}

	private onClassificationNodeSelected(selectedNode: GraphNode): void {
		this._commonIssueNodeSelected = (selectedNode.nodeType == 'common-issue');

		if (this._commonIssueNodeSelected) {
			// clear the existing value and disable it
			this.formGroup.patchValue({
				'proposedCommonIssueTitle': ''
			});

			this.formGroup.controls['proposedCommonIssueTitle'].disable();
		}
		else {
			this.formGroup.controls['proposedCommonIssueTitle'].enable();
		}

		this.formGroup.controls['proposedCommonIssueTitle'].updateValueAndValidity();
	}

}
