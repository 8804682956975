import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'values'
})
export class ValuesPipe implements PipeTransform {

	transform(value: any, sort: boolean, key: string = 'key'): Object[] {
		let keyArray = Object.keys(value);
		let dataArray = new Array<any>();
		let keyProperty = key;

		if (sort) {
			keyArray.sort();
		}

		keyArray.forEach(key => {
			let o = {
				value: value[key]
			};

			o[keyProperty] = key;

			dataArray.push(o);
		});

		return dataArray;
	}

}
