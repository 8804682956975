import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PagerModule } from '@syncfusion/ej2-angular-grids';
import { MomentModule } from 'ngx-moment';
import { CollapseModule, BsDropdownModule, TabsModule, AccordionModule } from 'ngx-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TruncateModule } from '@yellowspot/ng-truncate';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faHome, faTable, faShare, faThumbsDown, faThumbsUp, faSync, faMedkit, faFileAlt } from "@fortawesome/pro-solid-svg-icons";

import { SharedModule } from '../../shared/shared.module';

import { SearchRoutingModule } from './search-routing.module';

import { SearchService } from '../../core/services/searches/search.service';

import { SearchComponent } from './components/search/search.component';

import { ArticleSearchResultComponent } from './components/search.result.article/search.result.article.component';
import { IncidentSearchResultComponent } from './components/search.result.incident/search.result.incident.component';
import { PartnerServiceRequestSearchResultComponent } from './components/search.result.psr/search.result.psr.component';

import { ContentViewerModule } from '../content-viewer/content-viewer.module';

library.add(faHome, faTable, faShare, faThumbsDown, faThumbsUp, faSync, faMedkit, faFileAlt);

@NgModule({
   imports: [
	  CommonModule,
	  RouterModule,
	  ReactiveFormsModule,
	  BrowserAnimationsModule,

	  FontAwesomeModule,
	  AccordionModule,
	  TabsModule,
	  BsDropdownModule,
	  CollapseModule,
	  MomentModule,
	  PagerModule,
	  TruncateModule,

	  SharedModule,
	  SearchRoutingModule,
	  ContentViewerModule
   ],
   declarations: [
	  SearchComponent,
	  ArticleSearchResultComponent,
	  IncidentSearchResultComponent,
	  PartnerServiceRequestSearchResultComponent
   ],
   providers: [
	  SearchService
   ]
})
export class SearchModule { }
