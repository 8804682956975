import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';

import { Subscription } from 'rxjs';
import { AccordionPanelComponent, BsModalService } from 'ngx-bootstrap';

import { ClassificationService } from '../../services/classification/classification.service';
import { TroubleshootingInformation } from '../../models/troubleshooting-information.model';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
	selector: 'app-classification-diagnostics-management',
	templateUrl: './classification-diagnostics-management.component.pug',
	styleUrls: ['./classification-diagnostics-management.component.scss']
})
export class ClassificationDiagnosticsManagementComponent implements OnInit, OnDestroy {

	public dataPointsForm: FormGroup;

	public hasBeenSubmitted: boolean;

	private _subscriptions = new Subscription();

	private _items: Array<TroubleshootingInformation>;

	constructor(private _fb: FormBuilder, private _classificationService: ClassificationService, private _modalService: BsModalService) {
	}

	ngOnInit() {
		this.loadData();
	}

	private loadData(): void {
		this._subscriptions.add(
			this._classificationService.getDiagnosticItems()
				.subscribe(response => {
						this._items = response;

						this.createForm();
					}
				)
		);
	}

	private createForm(): void {
		this.dataPointsForm = this._fb.group({});

		let controls: FormGroup[] = [];

		if (this._items) {
			controls = this._items.map(i => this._fb.group({
				troubleshootingInformationId: [i.troubleshootingInformationId],
				whatIsNeeded: [i.whatIsNeeded, Validators.required],
				whyItIsNeeded: [i.whyItIsNeeded, Validators.required],
				howItIsCollected: [i.howItIsCollected, Validators.required]
			}));
		}

		this.dataPointsForm.addControl("items", this._fb.array(controls));
	}

	private initItem(): FormGroup {
		return this._fb.group({
			troubleshootingInformationId: [0],
			whatIsNeeded: ['', Validators.required],
			whyItIsNeeded: ['', Validators.required],
			howItIsCollected: ['', Validators.required]
		});
	}

	public addItem(): void {
		const items = this.dataPointsForm.get('items') as FormArray;
		items.insert(0, this.initItem());
		this._items.unshift(new TroubleshootingInformation());
	}

	public removeItem(i: number): void {
		let question = this._items[i];
		const items = this.dataPointsForm.get('items') as FormArray;

		if (question && question.troubleshootingInformationId) {
			this._classificationService.deleteDiagnosticItem(question.troubleshootingInformationId)
				.subscribe(response => {
					this._items = this._items.filter(x => x != question);
					items.removeAt(i);
				});
		} else {
			this._items = this._items.filter(x => x != question);
			items.removeAt(i);
		}
	}

	public confirmRemove(i: number): void {
		this._modalService.show(ConfirmationDialogComponent, { class: 'modal-lg' })
			.content
			.onResponseSelected
			.subscribe(response => {
				if (response) {
					this.removeItem(i);
				}
			});
	}

	// needed because of a bug reported back in Angular version 5 that does not cast
	public getControls(key: string): any {
		return (<FormArray>this.dataPointsForm.controls[key]).controls;
	}

	public save(item: FormControl, index: number, apc: AccordionPanelComponent): void {
		this.hasBeenSubmitted = true;

		if (this.dataPointsForm.invalid) {
			return;
		}

		let troubleShootingInformation = item.value as TroubleshootingInformation;

		if (troubleShootingInformation.troubleshootingInformationId) {
			this._classificationService.updateDiagnosticItem(troubleShootingInformation)
				.subscribe(response => {
					this.hasBeenSubmitted = false;

					item.patchValue({
						troubleshootingInformationId: response.troubleshootingInformationId,
						whatIsNeeded: response.whatIsNeeded,
						whyItIsNeeded: response.whyItIsNeeded,
						howItIsCollected: response.howItIsCollected
					});

					if (this._items[index]) {
						this._items[index] = response;
					}

					apc.isOpen = false;
				})
		} else {
			this._classificationService.createDiagnosticItem(troubleShootingInformation)
				.subscribe(response => {
					this.hasBeenSubmitted = false;

					item.patchValue({
						troubleshootingInformationId: response.troubleshootingInformationId,
						whatIsNeeded: response.whatIsNeeded,
						whyItIsNeeded: response.whyItIsNeeded,
						howItIsCollected: response.howItIsCollected
					});

					if (this._items[index]) {
						this._items[index] = response;
					}

					apc.isOpen = false;
				})
		}
	}

	public resetFormArrayItem(index: number): void {
		let question = this._items[index];

		if (question) {
			(<FormArray>this.dataPointsForm.controls["items"]).at(index).patchValue({
				troubleshootingInformationId: question.troubleshootingInformationId,
				whatIsNeeded: question.whatIsNeeded,
				whyItIsNeeded: question.whyItIsNeeded,
				howItIsCollected: question.howItIsCollected
			});
		}
	}

	public ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

}
