import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'articleFriendly'
})
export class ArticleFriendlyVersionPipe implements PipeTransform {

	transform(value: number): string {
		if (value < 1) {
			return null;
		}

		let major = Math.floor(value / 512);		// no integer division in JS
		let minor = value % 512;

		return `${major}.${minor}`;
	}

}
