import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Location } from '@angular/common'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { ShareService } from '../../../core/services/share/share.service';
import { ShareViewModel } from '../../../core/models/share/share.model';

@Component({
	selector: 'app-share',
	templateUrl: './share.component.html',
	styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

	@Input()
	public type: string;

	@Input()
	public subject: string;

	@Input()
	public url: string;

	public message: string;

	private _subscription = new Subscription();

	public shareForm = new FormGroup({
		url: new FormControl('', Validators.required),
		recipentName: new FormControl('', Validators.required),
		comment: new FormControl('')
	});

	constructor(private _bsModalRef: BsModalRef,
		private _location: Location,
		private _shareService: ShareService) { }

	ngOnInit() {
		if (!this.url) {
			this.url = window.location.origin + this._location.path();
		}

		this.shareForm.setValue({ url: this.url, recipentName: null, comment: null })
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

	public send(): void {
		var request: ShareViewModel = {
			recipentName: this.shareForm.value.recipentName,
			comment: this.shareForm.value.comment,
			subject: this.subject,
			type: this.type,
			url: this.url,
			senderName: ""
		};

		this._subscription.add(
			this._shareService.share(request)
				.subscribe(response => {
					if (response == true) {
						this._bsModalRef.hide();
					}
					else {
						this.message = "<p>We are sorry. There was a problem sharing the link. Please try again.</p><p>In the meantime, the CEA Team has been notified of the issue.</p>";
					}
				})
		);

	}

	public onCancel(): void {
		this._bsModalRef.hide();
	}

}
