import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
	})
};

@Injectable({
	providedIn: 'root'
})
export class MessageService {

	private _urlBase: string = "api/messages";

	constructor(private _httpClient: HttpClient) { }

	public getMessages(): Observable<Array<string>> {
		return this._httpClient.get<Array<string>>(`${this._urlBase}/messages`);
	}

}
