import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissionService } from './permission.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthPermissionsGuard implements CanActivate {

	constructor(private _permissionService: PermissionService, private _router: Router, private _authService: AuthService) {
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		let roles = route.data['roles'] as Array<string>;

		return new Observable<boolean>(observer => {
			this._authService.isAuthenticated().subscribe(authenticated => {
				if (authenticated) {
					// if no roles are passed in to this route, then we assume eveyone can access
					if (!roles || !roles.length) {
						observer.next(true);
						observer.complete();
						return;
					}

					this._permissionService.getMyPermissions().subscribe(result => {
						let filter = result.filter(res => {
							return roles.indexOf(res.name.toLowerCase()) > -1;
						});

						observer.next(!!filter.length);
						observer.complete();
					});
				} else {
					this._authService.login(state.url);
					observer.next(false);
					observer.complete();
				}
			});
		});
	}

}
