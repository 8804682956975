import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { ArticleQualityVote } from '../../../../../../core/models/articles/article-quality-vote.model';
import { ArticleService } from '../../../../../../core/services/articles/article.service';
import { Article } from '../../../../../../core/models/articles/article.model';

@Component({
	selector: 'app-article-voting',
	templateUrl: './article-voting.component.pug',
	styleUrls: ['./article-voting.component.scss']
})
export class ArticleVotingComponent implements OnInit, OnDestroy {

	@Input()
	public article: Article;

	@Input()
	public disabled: boolean = true;

	@Output("refreshNeeded")
	public refreshNeededEmitter = new EventEmitter<boolean>();

	public articleQualityVote: ArticleQualityVote;

	public url: string;

	public userVote: number = 0;

	public votedCount: number = 0;

	public isDislikeComment: boolean = true;

	private _subscriptions = new Subscription();

	constructor(private _location: Location, private _articleService: ArticleService) { }

	ngOnInit() {
		this.url = window.location.origin + this._location.path();
		this.getArticleQualityVoteById();
	}

	public onVoted(voteDetail: any) {
		if (!voteDetail) {
			return;
		}

		if (voteDetail.isCancel) {
			return;
		}

		this.setUserVote(voteDetail.vote)

		this.voteSubmitted(voteDetail);
	}

	public getArticleQualityVoteById() {
		if (!this.article.articleId) {
			return;
		}

		this._subscriptions.add(this._articleService.getArticleQualityVote(this.article.articleId)
			.subscribe(result => {
				this.articleQualityVote = result.articleQualityVote;
				this.userVote = result.articleQualityVote ? result.articleQualityVote.vote : 0;
				this.votedCount = result.totalScore;
			})
		);
	}

	private voteSubmitted(request: any) {
		let voteRequest: ArticleQualityVote = {
			comment: request.comment,
			vote: this.userVote,
			isAnonymous: request.isAnonymous
		};

		this._subscriptions.add(
			this._articleService.voteAsync(this.article.articleId, this.article.version, voteRequest)
				.subscribe(result => {
					this.articleQualityVote = result.articleQualityVote;
					this.userVote = result.articleQualityVote ? result.articleQualityVote.vote : 0;
					this.votedCount = result.totalScore;
					this.refreshNeededEmitter.emit(true);
				})
		);
	}

	private setUserVote(vote: boolean) {
		if (vote) {
			if (this.userVote <= 0) {
				this.userVote = 1
			}
			else {
				this.userVote = 0
			}
		}
		else {
			if (this.userVote >= 0) {
				this.userVote = -1
			}
			else {
				this.userVote = 0;
			}
		}
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
