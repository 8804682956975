import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { DataManager, ODataV4Adaptor, Query } from '@syncfusion/ej2-data';

import { AuthService } from '../../../../../core/services/auth/auth.service';
import { UsersService } from '../../../../../core/services/users/users.service';
import { AdminService } from '../../../../../core/services/admin/admin.service';

import { ResetUserPublishDate } from '../../../../../core/models/admin/reset-user-publish-date.model';

@Component({
	selector: 'app-reset-user-publish-date',
	templateUrl: './reset-user-publish-date.component.pug',
	styleUrls: ['./reset-user-publish-date.component.scss']
})
export class ResetUserPublishDateComponent implements OnInit, OnDestroy {

	private _subscriptions = new Subscription();

	public rupdFormGroup = new FormGroup({
		users: new FormControl('', Validators.required),
		resetDate: new FormControl('', Validators.required)
	});

	public errorMessage: boolean = false;

	public remoteFields = { text: 'userName', value: 'id' };

	public data: DataManager;

	public query: Query;

	public userIds: Array<string>;

	constructor(private _authService: AuthService,
		private _adminService: AdminService,
		private _route: Router) {
	}

	ngOnInit() {
		this.bindData();

		this._subscriptions.add(
			this.rupdFormGroup.get("users").valueChanges
				.subscribe(value => {
					if (value && value.map) {
						this.userIds = value;
					}
				})
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	private bindData(): void {
		this.data = new DataManager({
			url: '/odata/User',
			adaptor: new ODataV4Adaptor,
			crossDomain: false,
			headers: [{ 'Authorization': this._authService.getAuthorizationHeaderValue() }]
		});

		this.query = new Query().select(['id', 'userName']);
	}

	public onSubmit(): void {
		var request: ResetUserPublishDate = {
			resetDate: this.rupdFormGroup.value.resetDate,
			userIds: this.userIds
		};

		this._subscriptions.add(
			this._adminService.resetUsersPublishDate(request)
				.subscribe(
					response => {
						this._route.navigate(["/reports/render/6DD38373-B8E5-4E47-B6F4-5D9BE28320B6"]);
					},
					error => {
						this.errorMessage = true;
					}
				)
		);
	}

}
