import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthPermissionsGuard } from '../../core/services/permissions/auth-permissions.guard';

import { ReportsGalleryComponent } from './components/reports-gallery/reports-gallery.component';
import { RenderComponent } from './components/render/render.component';

const routes: Routes = [
	{
		path: 'reports', component: ReportsGalleryComponent, canActivate: [AuthPermissionsGuard], data: {
			roles: ['view-reports', 'view-solution-admin-reports']
		}
	},
	{
		path: 'reports/render/:id', component: RenderComponent, canActivate: [AuthPermissionsGuard], data: {
			roles: ['view-reports', 'view-solution-admin-reports']
		}
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ReportsRoutingModule { }
