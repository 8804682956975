import { Component, OnInit, Input, OnDestroy, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { Subscription, Subject } from 'rxjs';
import { Contact } from '../../../../../core/models/incidents/incident-contact.model';
import { IncidentService } from '../../../../../core/services/incidents/incident.service';

@Component({
	selector: 'app-incident-escalation',
	templateUrl: './incident-escalation.component.pug',
	styleUrls: ['./incident-escalation.component.scss']
})
export class IncidentEscalationComponent implements OnInit {

	private _subscription = new Subscription();

	@Input()
	public id: string;

	@Input()
	public customerName: string;

	@Input()
	public title: string;

	@Input()
	public description: string;

	@Input()
	public canEscalate: boolean = false;

	@Output()
	public isEscalated = new EventEmitter<boolean>();

	public isError: boolean = false;

    public results: Contact[];

	public emailCCUsers = [];

	public uiElement: any;

	@ViewChild('dlelement') dlElementRef;

	public formGroup = new FormGroup({
		emailCC: new FormControl(),
		escalationDescription: new FormControl('', Validators.required)
	});

	constructor(private _bsModalRef: BsModalRef,
		private _incidentService: IncidentService) { }

	ngOnInit() {
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

    search(event) {
        this._incidentService.getCCUsers(event.query).subscribe(data => {
            this.results = data;
			this.results.forEach((item: any) => item.name = item.fullName + " (" + item.emailAddress + ")");
        });
    }

	public removeDuplicates = (array, key) => {
		return array.reduce((arr, item) => {
		  const removed = arr.filter(i => i[key] !== item[key]);
		  return [...removed, item];
		}, []);
	};

	public select(event) {
		event.name = event.fullName;
		this.uiElement = this.dlElementRef.value;
		this.emailCCUsers.push(event);
		this.dlElementRef.value = this.removeDuplicates(this.uiElement, 'id')
		this.emailCCUsers = this.removeDuplicates(this.emailCCUsers, 'id');
	}

	public unselect(event){
		const index = this.emailCCUsers.indexOf(event);
		if (index > -1) {
			this.emailCCUsers.splice(index, 1);
		}
	}
	
	public submit(): void {
		this.isError = false;

		this._subscription.add(
			this._incidentService
				.escalateIncident(this.id, this.emailCCUsers, this.formGroup.value.escalationDescription)
				.subscribe(response => {
					this.isEscalated.emit(true);
					this._bsModalRef.hide();

				},
					error => {
						this.isEscalated.emit(false);
						this.isError = true;
					}
				)
		);
	}

	public onCancel(): void {
		this._bsModalRef.hide();
	}

}
