import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';

import { PermissionService } from '../../services/permissions/permission.service';
import { UserProfileService } from "../../services/user-profiles/user-profile.service";
import { AuthService } from '../../services/auth/auth.service';

import { UserProfile } from "../../models/user-profiles/user-profile.model";
import { UserProfileUpdate } from '../../models/user-profiles/user-profile-update.model';
import { Role } from '../../models/roles/role.model';
import { RolesService } from '../../services/roles/roles.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.pug',
})
export class HomeComponent implements OnInit {

	public profile: UserProfile;

	// we need this to ensure that roleSubscriptions are not lost on Home Page update
	public roleSubscriptionIds: Array<string> = [];

	private _subscriptions = new Subscription();

	private profileUpdate: UserProfileUpdate;

	constructor(private _profileService: UserProfileService, private _authService: AuthService, private _rolesService: RolesService, private _router: Router) {
	}

	ngOnInit(): void {
		this._subscriptions.add(
			this._authService.isAuthenticated()
				.subscribe((result) => {
					if (result) {
						forkJoin(this._profileService.getMyProfile(), this._rolesService.getTeams()).subscribe(response => {
							this.profile = response[0];

							let roleSubscriptions = response[1];

							if (this.profile.userRoleSubscriptions) {
								this.profile.userRoleSubscriptions.forEach(mySubscription => {
									let index = roleSubscriptions.findIndex(roleSubscription => roleSubscription.id === mySubscription.roleId);
									if (index >= 0) {
										this.roleSubscriptionIds.push(roleSubscriptions[index].id);
									}
								}
							)}
						})
					}
				})
		);
	}

	selectHome(home: number) {
		// we need ALL the details to be filled in to the UserProfileUpdate
		let update: UserProfileUpdate = {
			id: this.profile.id,
			searchDetailsAction: this.profile.searchDetailsAction,
			enableArticleUpVoteNotifications: this.profile.enableArticleUpVoteNotifications,
			enableArticleReviewNotifications: this.profile.enableArticleReviewNotifications,
			defaultToAnonymousComments: this.profile.defaultToAnonymousComments,
			defaultEditorMode: this.profile.defaultEditorMode,
			homePage: home,
			subscriptions: this.roleSubscriptionIds
		};

		this._profileService.updateMyProfile(update)
			.subscribe(profile => {
				// successful update returns full (and updated) profile
				this.profile = profile;
				this._router.navigate(['/']);
			});
	}

}
