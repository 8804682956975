import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

import { Subscription, Observable, forkJoin } from 'rxjs';
import { delay } from 'rxjs/operators';

import { ArticleService } from '../../../../../core/services/articles/article.service';
import { PermissionService } from '../../../../../core/services/permissions/permission.service';
import { UserProfileService } from '../../../../../core/services/user-profiles/user-profile.service';

import { Article } from '../../../../../core/models/articles/article.model';
import { ArticleMetaData } from '../../../../../core/models/articles/article-metadata.model';
import { ArticleTargetedVersion } from '../../../../../core/models/articles/article-targeted-version.model';

import { CommentThreadsComponent } from '../../../../../shared/components/comments/comment-threads/comment-threads.component';
import { ShareComponent } from '../../../../../shared/components/share/share.component';
import { ArticleHistoryComponent } from '../toolbar/article-history/article-history.component';
import { ArticleVotingComponent } from '../toolbar/article-voting/article-voting.component';
import { RolesService } from '../../../../../core/services/roles/roles.service';
import { ApplicationService } from '../../../../../core/services/applications/application.service';
import { Application } from '../../../../../core/models/applications/application.model';

@Component({
	selector: 'app-articles-viewer',
	templateUrl: './articles-viewer.component.pug',
	styleUrls: ['./articles-viewer.component.scss']
})
export class ArticlesViewerComponent implements OnInit, OnDestroy {

	@Input()
	public articleId: number;

	@Input()
	public articleVersion: number;

	@Input()
	public article: Article;

	@Input()
	public metadata: ArticleMetaData;

	@Input()
	public isPreview: boolean = false;

	public isValid: boolean = true;

	// this is to show/hide the canEdit button
	public canEdit: boolean = false;

	public canNominate: boolean = false;

	public canPromote: boolean = false;

	public editDisabled: boolean = true;

	public canPublish: boolean = false;

	public latestVersion: number = 0;

	public publishedVersion: number = 0;

	public threadId: string = null;

	public applications: Array<Application>;

	@ViewChild(CommentThreadsComponent)
	private _commentThreadsComponent: CommentThreadsComponent;

	@ViewChild(ArticleVotingComponent)
	private _articleVotingComponent: ArticleVotingComponent;

	private _subscription = new Subscription();

	private _modalRef: BsModalRef;

	constructor(private _route: ActivatedRoute,
		private _articleService: ArticleService,
		private _permissionService: PermissionService,
		private _modalService: BsModalService,
		private _profileService: UserProfileService,
		private _applicationService: ApplicationService,
		private _router: Router,
		private _rolesService: RolesService) { }

	ngOnInit() {
		this._subscription.add(
			this._router.events.subscribe((e: any) => {
				if (e instanceof NavigationEnd) {
					if (this._modalRef) {
						this._modalRef.hide();
					}

					this.refresh();
				}
			})
		);

		this._applicationService.getEnabledApplications().subscribe(response => {
			this.applications = response;
		});

		if (this.articleId && this.articleVersion) {
			this.loadArticleData();
		} else {
			this._subscription.add(
				// TODO
				// Change params to paramMaps as params is pretty old and may be deprecated soon

				this._route.params.subscribe(params => {
					let routedArticleId: number = (params['id'] ? +params['id'] : null);
					let routedArticleVersion: number = (params['version'] ? +params['version'] : null);

					if (!routedArticleId || (this.articleId == routedArticleId && this.articleVersion == routedArticleVersion)) {
						// nothing to do if article id was not specified or nothing has changed
						return;
					}

					this.articleId = routedArticleId;
					this.articleVersion = routedArticleVersion;

					this.loadArticleData();
				})
			);
		}
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

	public checkEditDisabled(): void {
		this.editDisabled = false;

		if (this.article && (this.article.deletionId || this.article.version != this.latestVersion)) {
			this.editDisabled = true;
		}
	}

	public refresh(): void {
		this.loadArticleData();
		this._articleVotingComponent.getArticleQualityVoteById();
		this._commentThreadsComponent.getComments();
	}

	public refreshComment(): void {
		this._commentThreadsComponent.getComments();
	}

	public unpublish(): void {
		this._articleService.unpublish(this.article.articleId).subscribe(response => {
			this._router.navigate([`/article/details/${response.articleId}/${response.version}`]);
		});
	}

	public nominate(): void {
		this._articleService.nominateAsSolution(this.article.articleId, this.article.version).subscribe(response => {
			this.refresh();
		});
	}

	public share() {
		let url = `${window.location.origin}/article/details/${this.article.articleId}/${this.article.version}`;

		const initialState = {
			subject: 'Article Shared',
			type: 'Article',
			url: url
		};

		const modalParams = { initialState, class: 'modal-lg' };
		this._modalRef = this._modalService.show(ShareComponent, modalParams);
	}

	public delete(includePrevious: boolean = false): void {
		this._articleService.deleteArticle(this.article.articleId, this.article.version, includePrevious)
			.subscribe(response => {
				this._router.navigate(['/article/management']);
			});
	}

	public viewHistory() {
		const initialState = {
			article: this.article,
		};

		const modalParams = { initialState, class: 'modal-lg' };
		this._modalRef = this._modalService.show(ArticleHistoryComponent, modalParams);
	}

	public hasProperty(obj: any, propertyName: string): boolean {
		// unlike in C#, you cannot check if an object has a property if it is null
		if (!obj) {
			return false;
		}

		return propertyName in obj;
	}

	public articleHasProperty(propertyName: string): boolean {
		return this.hasProperty(this.article, propertyName);
	}

	public metadataHasProperty(propertyName: string): boolean {
		return this.hasProperty(this.metadata, propertyName);
	}

	public groupByMajorVersion(items: Array<ArticleTargetedVersion>): any {
		return items
			.reduce(
				(result, item) => ({
					...result,
					[item['versionMajor'] + ':' + item['versionApplicationId']]: [
						...(result[item['versionMajor'] + ':' + item['versionApplicationId']] || []), item,
					],
				}),
				{},
			);
	}

	public getArrayOfGroupedMajorVersions(items: Array<ArticleTargetedVersion>): Array<Array<ArticleTargetedVersion>> {
		var grps = this.groupByMajorVersion(items);

		// The value return is a single Object with each Group By key a property on the object.
		// Because we want to sort by Major DESC we need to convert to number, sort it, but then request values by string again...

		return Object.keys(grps)
			.map(x => { return x; })
			.sort((a, b): number => {
				let majorVersionNumberA = Number(a.split(':')[0]);
				let majorVersionNumberB = Number(b.split(':')[0]);

				let applicationNameA = '';

				if (a.split(':')[1] === this.applications[0].id) {
					applicationNameA = this.applications[0].name;
				}
				else {
					applicationNameA = this.applications[1].name;
				}

				let applicationNameB = '';

				if (b.split(':')[1] === this.applications[0].id) {
					applicationNameB = this.applications[0].name;
				}
				else {
					applicationNameB = this.applications[1].name;
				}

				if (applicationNameA === applicationNameB) {
					return majorVersionNumberB - majorVersionNumberA;
				}
				else {
					// place Commvault versions first
					return applicationNameA.localeCompare(applicationNameB);
				}

			})
			.map(x =>
				grps[`${x}`]
			);
	}

	public getCombinedVersionsValue(items: Array<ArticleTargetedVersion>): string {
		let applicationName: string;

		let targetedLabels = items.map(x => {

			for (var i = 0; i < this.applications.length; i++) {
				if (this.applications[i].id === x.versionApplicationId) {
					applicationName = this.applications[i].name;
					return ArticleTargetedVersion.versionLabel(x).trim();
				}
			}
		});

		return `${applicationName} ${targetedLabels.join(', ')}`;
	}

	private loadArticleData() {
		if (!this.articleId) {
			// nothing to do if article id was not specified or nothing has changed
			return;
		}

		this.getArticleData();
		this.getArticleMetadata();
	}

	private getArticleMetadata() {
		this._articleService.getArticleMetadata(this.articleId, this.articleVersion).subscribe(response => {
			if (response) {
				this.metadata = response;
			}
		});
	}

	private getArticleData() {
		if (!this.articleId) {
			return;
		}

		this.isValid = true;

		this._articleService.getLatestArticleVersion(this.articleId).subscribe(response => {
			if (response) {
				this.latestVersion = response;
				this.checkEditDisabled();
			}
		});

		this._articleService.getPublishedArticleVersion(this.articleId)
			.subscribe(response => {
				this.publishedVersion = (response ? response : 0);
			});

		this._articleService.getLatestArticleById(this.articleId, this.articleVersion)
			.subscribe(
				articlesData => {
					this.article = articlesData;
					this.threadId = this.article.articleTypeLabel.replace(/\s/g, '').toLowerCase() + this.article.articleId;

					this.checkEditDisabled();
					if (this.article.articleDeflectedIncidents) {
						this.article.articleDeflectedIncidents = this.article.articleDeflectedIncidents.sort((left, right): number => { return (left.incidentId > right.incidentId) ? -1 : ((right.incidentId > left.incidentId) ? 1 : 0); });	// descending
					}

					// check if the user can edit this article
					this._articleService.getArticleTypes().subscribe(response => {
						this.canEdit = !!response.filter(x => x.articleModelType.indexOf(this.article['@odata.type'].replace("#", "")) > -1).length;
					});

					// Role Check for Nominate - must be someone who cannot compose public articles
					// Role Check for Promote - must be someone who can compose public articles AND this article has not been promoted or denied
					this._permissionService.hasPermission(["compose-public-articles"]).subscribe(hasPermission => {
						let canCreateSolution: boolean = hasPermission;

						// STATUS Check for Nominate
						let disallowedStatusValuesForNominate = [
							"TechnotePendingPromotion",
							"TechnotePromoted",
							"TechnotePromotionDenied",
							"TechnotePendingPromotion_PartnerAccessible",
							"TechnotePromoted_PendingPartnerAccessible",
							"TechnotePromoted_PartnerAccessible",
						];
						let statusAllowsNominate: boolean = disallowedStatusValuesForNominate.indexOf(this.article.workflowState) == -1;

						// STATUS Check for Promote
						let disallowedStatusValuesForPromote = [
							"TechnotePromoted",
							"TechnotePromotionDenied",
							"TechnotePromoted_PendingPartnerAccessible",
							"TechnotePromoted_PartnerAccessible",
						];

						let statusAllowsPromote: boolean = disallowedStatusValuesForPromote.indexOf(this.article.workflowState) == -1;

						this.canNominate = !canCreateSolution
							&& (this.article.articleTypeLabel === "Technical Note")
							&& statusAllowsNominate
							&& this.article.version % 512 == 0
							&& this.article.version == this.publishedVersion;

						this.canPromote = canCreateSolution
							&& (this.article.articleTypeLabel === "Technical Note")
							&& statusAllowsPromote
							&& this.article.version % 512 == 0
							&& this.article.version == this.publishedVersion;
					});

					// after a delay, log that the user has read the article. Whatever seconds we set is not enough for the user to
					// actually read an entire article in most cases, but it is long enough for them to not just go to an article get credit
					this._profileService.getMyProfile()
						.pipe(
							delay(15000),
						).subscribe(user => {
							this._articleService.recordArticleInspection(this.articleId, user.id).subscribe()
						});
				},
				error => {
					this.isValid = false;
				}
			);
	}

}
