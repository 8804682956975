import { Component, OnInit, Input, EventEmitter, OnDestroy, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Article } from '../../../../../../../core/models/articles/article.model';
import { ArticleService } from '../../../../../../../core/services/articles/article.service';
import { CommonIssueEditorComponent } from '../../../../../../classification/components/editors/common-issue-editor/common-issue-editor.component';

@Component({
	selector: 'app-approve-publish-confirmation',
	templateUrl: './approve-publish-confirmation.component.pug',
	styleUrls: ['./approve-publish-confirmation.component.scss']
})
export class ApprovePublishConfirmationComponent implements OnInit, OnDestroy {

	@Input()
	public article: Article;

	public submitted: boolean = false;

	@Output()
	public refreshNeeded = new EventEmitter<boolean>();

	private _subscriptions = new Subscription();

	constructor(private _bsModalRef: BsModalRef, private _articleService: ArticleService, private _router: Router, private _modalService: BsModalService) { }

	ngOnInit() {
	}

	public approvePublish(): void {
		this.submitted = true;
		if (this.article && this.article.proposedCommonIssueTitle) {
			const initialState = {
				'title': this.article.proposedCommonIssueTitle,
				'parentNodeId': this.article.classificationCode
			};

			this._subscriptions.add(
				this._modalService.show(CommonIssueEditorComponent, { initialState: initialState, class: 'modal-lg' })
					.content
					.nodeCreated
					.subscribe(response => {
						this._subscriptions.add(
							this._articleService.updateArticleClassification(this.article.articleId, this.article.version, response.id)
								.subscribe(updateClassificationResponse => {
									this.article = updateClassificationResponse;
									this.addApprovalSubscription();
								})
						);
					})
			);
		}
		else if (this.article) {
			this.addApprovalSubscription();
		}
	}

	public close(): void {
		this._bsModalRef.hide();
	}

	public addApprovalSubscription() {
		this._subscriptions.add(
			this._articleService.approvePublish(this.article.articleId, this.article.version).subscribe(response => {
				if (response && response.articleId && response.version) {
					this._router.navigate([`/article/details/${response.articleId}/${response.version}`]);
					this._bsModalRef.hide();
				} else {
					this.submitted = false;
					// this error should not happen as it should be caught by the global error handler. But adding this just in case
					throw new Error("Article approval did not complete successfully");
				}
			})
		);
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
