
export class GraphNode {

	public id: string;

	public nodeType: string;

	public parentId?: string;

	public title: string;

	public children: Array<GraphNode>;

	public team: string;

	public escalationAlias: string;

	public troubleshootingInformationIds: Array<number>;

	public tags: Array<string>;

	public viewOrder: number;

}
