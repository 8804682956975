import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { IncidentActivity } from '../../../../../core/models/incidents/incident-activity.model';
import { IncidentService } from '../../../../../core/services/incidents/incident.service';
import { ConfigurationService } from '../../../../runtime-configuration/services/configuration.service';

@Component({
  selector: 'incident-activities-render',
  templateUrl: './incident-activities-render.component.pug',
  styleUrls: ['./incident-activities-render.component.scss']
})
export class IncidentActivitiesRenderComponent implements OnInit {

  @Input()
	public id: string;

  @Input()
	public activity: IncidentActivity;

  @Input()
  public isEscalated: boolean = false;

  @Input()
	public isMetallic: boolean = false;
  
  constructor(private _configSvc: ConfigurationService, private _incidentService: IncidentService) { }

  ngOnInit() {}

  public replaceLineBreaks(item: any) {
    if (item != null) {
        var strNoLineBreaks = item.replace(/>/g, "&gt;");
        strNoLineBreaks = strNoLineBreaks.replace(/</g, "&lt;");
        strNoLineBreaks = strNoLineBreaks.replace(/\\r\\n/g, "<br />");
        strNoLineBreaks = strNoLineBreaks.replace(/\n/g, "<br />");
        return strNoLineBreaks;
    }
  }

  public replaceImgSrc(item: any){
    let maBaseUrl = this._configSvc.configuration.maBaseUrl;
    if (item != null) {
      var imgMatches = item.match(new RegExp("<img.+?src=[\"'](.+?)[\"'].+?>", 'gi'));
      if (imgMatches) {
        imgMatches.forEach(imgMatch => {
          if(this.isEscalated){
            item = item.replace(imgMatch, (imgMatch.slice(0, 5) + "width=800px " + imgMatch.slice(5)))
          }
          var regexSrc = /img.*?src=("|')(.*?)\1/i;
          var regexAttach = /img.*?data-attachment-id=("|')(.*?)\1/i;
          var srcToReplace = imgMatch.match(regexSrc)?imgMatch.match(regexSrc)[2]:"";
          var attachmentId = imgMatch.match(regexAttach)?imgMatch.match(regexAttach)[2]:null;
          if (attachmentId != "undefined" && attachmentId != null) {
            item = item.replace(srcToReplace, (maBaseUrl + '/crmNAorgff31b/Activities/Attachment/download.aspx?AttachmentId=' + attachmentId));
          }
        })
      }
      return item;
    }
  }
}
