import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { IncidentActivity } from '../../../../../core/models/incidents/incident-activity.model';
import { IncidentService } from '../../../../../core/services/incidents/incident.service';
import { ConfigurationService } from '../../../../runtime-configuration/services/configuration.service';
import { IncidentEscalationComponent } from '../incident-escalation/incident-escalation.component';

@Component({
  selector: 'app-incident-escalation-confirmation',
  templateUrl: './incident-escalation-confirmation.component.pug',
  styleUrls: ['./incident-escalation-confirmation.component.scss']
})
export class IncidentEscalationConfirmationComponent implements OnInit {

	@Input()
	public id: string;

	@Input()
	public customerName: string;

	@Input()
	public title: string;

	@Input()
	public status: string;

	@Input()
	public description: string;

	@Input()
	public canEscalate: boolean = false;

	public isEscalated: boolean = true;

	public incidentDetailsUrl: string;

	private _subscriptions = new Subscription();

	public lastActivity: IncidentActivity;

	public formGroup = new FormGroup({
		isConfirmedChecked: new FormControl('', Validators.requiredTrue)
	});

	constructor(private _bsModalRef: BsModalRef, private _modalService: BsModalService, private _configSvc: ConfigurationService, private _incidentService: IncidentService) { }

	ngOnInit() {
		this.incidentDetailsUrl = this._configSvc.configuration.baseUrl + `/incident/details/${this.id}`;
		this._subscriptions.add(
			this._incidentService.getActivities(this.id, null, 1, null)
				.subscribe(activityData => {
					this.lastActivity = activityData[0];
				})
		);
	}

	public confirm(): void {
		this._bsModalRef.hide();
		const initialState = {
			id: this.id,
			description: this.description,
			title: this.title,
			customerName: this.customerName,
			canEscalate: this.canEscalate
		};
		this._bsModalRef = this._modalService.show(IncidentEscalationComponent, { initialState, class: 'modal-lg' });
	}

	public onCancel(): void {
		this._bsModalRef.hide();
	}

}
