export class ArticleTargetedVersion {

	public articleId: number;

	public version: number;

	public versionApplicationId: string;

	public versionMajor: number;

	public versionMinor: number;

	public versionBuild: number;

	public versionRevision: number;

	public versionServicePack: string;

	public static versionLabel(articleTargetedVersion: ArticleTargetedVersion): string {
		var queue = [];

		queue.push(articleTargetedVersion.versionMajor);
		queue.push(articleTargetedVersion.versionMinor);
		queue.push(articleTargetedVersion.versionBuild);
		queue.push(articleTargetedVersion.versionRevision);

		while (queue[queue.length - 1] == 0) {
			queue.pop();
		}

		var label = queue.join(".");

		if (articleTargetedVersion.versionServicePack !== '' && articleTargetedVersion.versionServicePack !== '*') {
			label = `${label} ${articleTargetedVersion.versionServicePack}`;
		}

		return label;
	}
}
