import { ArticleTargetRole } from "./article-target-role.model";
import { ArticleAuthor } from "./article-author.model";

export class ArticleMetaData {

	public targetedRoles: Array<ArticleTargetRole>;

	public technicalReviewer: ArticleAuthor; // should this change to be a more generic model? possibly update User?

}
