import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { FilterSettingsModel, PageSettingsModel, SortSettingsModel, ExcelExportService, ToolbarService, SelectionService, ToolbarItems, EditService, GridComponent, ExcelExportProperties } from '@syncfusion/ej2-angular-grids';
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';

import { AuthService } from '../../../../../core/services/auth/auth.service';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { ReportDefinition } from '../../../../reports/models/report-definition.model';

@Component({
	selector: 'app-delete-unused-common-issues',
	templateUrl: './delete-unused-common-issues.component.pug',
	styleUrls: ['./delete-unused-common-issues.component.scss'],
	providers: [SelectionService, ToolbarService, EditService]
})

export class DeleteUnusedCommonIssuesComponent implements OnInit {

	private _pageSizes: number[] = [10, 15];

	public unusedCommonIssue: DataManager;

	public definition: ReportDefinition;

	public filterOptions: FilterSettingsModel;

	public pageOptions: PageSettingsModel;

	public sortOptions: SortSettingsModel;

	public queryForm: FormGroup;

	public errorMessage: boolean = false;

	public selectOptions: Object;

	public editSettings: Object;

	public toolbarOptions: ToolbarItems[];

	@ViewChild('grid')
	public grid: GridComponent;

	constructor(private _fb: FormBuilder, private _authService: AuthService) {
	}

	ngOnInit() {
		this.createForm();
		this.initializeGrid();
	}

	private createForm(): void {
		this.queryForm = this._fb.group({
			startDate: ['', Validators.required],
			endDate: ['', Validators.required],
			minClassificationDays: ['', Validators.required],
			includeInternal: ['']
		});
	}

	private initializeGrid(): void {
		this.pageOptions = { pageCount: 10, pageSize: this._pageSizes[0], pageSizes: this._pageSizes };

		this.filterOptions = {
			type: 'Menu'
		};

		this.toolbarOptions = ['Delete', 'ExcelExport'];

		this.sortOptions = { columns: [{ field: 'createdOn', direction: 'Descending' }] };
	}

	private getDataManager(startDate: Date, endDate: Date, numberDays: number, includeInternal: boolean): DataManager {
		let query = `startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&minimumAge=${numberDays}&includeInternal=${includeInternal}`;

		return new DataManager({
			batchUrl: `api/classification/batchunusedcommonIssue`,
			url: `api/classification/getunusedcommonissue?${query}`,
			removeUrl: `api/classification/batchunusedcommonIssue`,
			adaptor: new UrlAdaptor,
			crossDomain: true,
			headers: [{ 'Authorization': this._authService.getAuthorizationHeaderValue() }],
		});
	}

	public onSubmit(): void {
		this.errorMessage = false;

		let values = this.queryForm.value;
		this.queryForm.disable();

		this.unusedCommonIssue = this.getDataManager(values.startDate, values.endDate, values.minClassificationDays, !!values.includeInternal);
	}

	public actionFailure(e): void {
		this.queryForm.enable();
		this.errorMessage = true;
	}

	public actionComplete(e): void {
		this.queryForm.enable();

		this.editSettings = { allowDeleting: true };
	}

	public onToolbarClick(args: ClickEventArgs): void {
		if (args.item.id.endsWith('_excelexport')) {
			let exportProperties: ExcelExportProperties = {
				fileName: 'DeleteUnusedCommonIssues.xlsx'
			};

			this.grid.excelExport(exportProperties);
		}
	}

}
