import { Component, OnInit, OnDestroy, DoCheck } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Subscription, of, Observable, forkJoin } from 'rxjs';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';

import { ArticleService } from '../../../../../core/services/articles/article.service';
import { ClassificationService } from '../../../../classification/services/classification/classification.service';
import { IncidentService } from '../../../../../core/services/incidents/incident.service';
import { RolesService } from '../../../../../core/services/roles/roles.service';
import { UsersService } from '../../../../../core/services/users/users.service';
import { UserProfileService } from '../../../../../core/services/user-profiles/user-profile.service';
import { ApplicationService } from '../../../../../core/services/applications/application.service';
import { TransformService } from '../../../../../core/services/transform/transform.service';

import { PublishConfirmationComponent } from '../../publish-confirmation/publish-confirmation.component';
import { ArticlesViewerModalPreviewComponent } from '../../../../content-viewer/components/articles/articles-viewer-modal-preview/articles-viewer-modal-preview.component';

import { Article } from '../../../../../core/models/articles/article.model';
import { ArticleAuthor } from '../../../../../core/models/articles/article-author.model';
import { ArticleContributor } from '../../../../../core/models/articles/article-contributor.model';
import { ArticleDefinition } from '../../../../../core/models/articles/article-definition.model';
import { ArticleTargetRole } from '../../../../../core/models/articles/article-target-role.model';
import { BestPracticesArticle } from '../../../../../core/models/articles/article-best-practices.model';
import { FrequentlyAskedQuestionArticle } from '../../../../../core/models/articles/article-faq.model';
import { SolutionArticle } from '../../../../../core/models/articles/article-solution.model';
import { TechnicalNoteArticle } from '../../../../../core/models/articles/article-technical-note.model';
import { ArticleMetaData } from '../../../../../core/models/articles/article-metadata.model';
import { TechnicalReferenceArticle } from '../../../../../core/models/articles/article-technical-reference.model';
import { TroubleshootingArticle } from '../../../../../core/models/articles/article-troubleshooting.model';
import { Role } from '../../../../../core/models/roles/role.model';
import { Application } from '../../../../../core/models/applications/application.model';
import { AlertService } from '../../../../../core/services/alert/alert.service';

@Component({
	selector: 'app-create-edit-articles',
	templateUrl: './create-edit-articles.component.pug',
	styleUrls: ['./create-edit-articles.component.scss']
})
export class CreateEditArticlesComponent implements OnInit, DoCheck, OnDestroy {

	private _subscriptions = new Subscription();

	private _type: string | null = null;

	private _oldId: number | null = null;

	private _oldVersion: number | null = null;

	private _oldType: string | null = null;

	private _oldArticle: Article | null = null;

	private _modalRef: BsModalRef;

	private _sourceIncidentId: string = null;

	private _transform: boolean = false;

	public hasBeenSubmitted: boolean = false;

	public articleFormGroup: FormGroup;

	public id: number | null = null;

	public version: number | null = null;

	public audiences: Array<string>;

	public threadId: string = null;

	public get type(): string {
		return this._type;
	}

	public set type(value: string | null) {
		this._type = value;
	}

	public article: Article = null;

	public articleMetadata = new ArticleMetaData();

	public markdownOptions: any = {
		initialEditType: 'wysiwyg',
		height: '30rem'
	};

	public definition: ArticleDefinition = null;

	public applications: Array<Application> = null;

	public commvaultApplication: Application = null;

	public hedvigApplication: Application = null;

	constructor(private _router: Router,
		private _route: ActivatedRoute,
		private _fb: FormBuilder,
		private _articleService: ArticleService,
		private _profileService: UserProfileService,
		private _modalService: BsModalService,
		private _classificationService: ClassificationService,
		private _usersService: UsersService,
		private _rolesService: RolesService,
		private _incidentService: IncidentService,
		private _applicationService: ApplicationService,
		private _transformService: TransformService,
		private _alertService: AlertService) {
	}

	ngOnInit(): void {
		// I wonder if having this here even makes sense...
		//   This **is** the pattern we use elsewhere but in this case we do not even know
		//   **what** type of article we are editing so the form will **always** be incomplete.
		this.createForm();

		this._subscriptions.add(
			this._profileService.getMyProfile()
				.subscribe(profile => {
					let mode = (profile && profile.defaultEditorMode === 0 ? 'markdown' : 'wysiwyg');

					this.markdownOptions = {
						...this.markdownOptions, ...{ initialEditType: mode }
					};
				})
		);

		this._subscriptions.add(
			this._route.paramMap
				.subscribe((params: ParamMap) => {
					if (params.has('id')) {
						this._oldId = this.id;
						this.id = +params.get('id');
					}

					if (params.has('sourceArticleId')) {
						this._oldId = this.id;
						this.id = +params.get('sourceArticleId');
						this._transform = true;
					}

					if (params.has('version')) {
						this._oldVersion = this.version;
						this.version = +params.get('version');
					}

					if (params.has('type')) {
						this._oldType = this.type;
						this.type = params.get('type');
					}

					if (params.has('sourceIncidentId')) {
						this._sourceIncidentId = params.get('sourceIncidentId');
					}
				})
		);

		this._applicationService.getEnabledApplications()
			.subscribe(response => {
				this.applications = response;
			});
	}

	ngDoCheck() {
		if (
			this.id !== this._oldId
			|| this.version !== this._oldVersion
			|| this.type !== this._oldType
			|| this.article !== this._oldArticle
		) {
			this.loadArticle();

			this._oldId = this.id;
			this._oldVersion = this.version;
			this._oldType = this.type;
			this._oldArticle = this.article;
		}
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

	private createForm(): void {
		this.articleFormGroup = this._fb.group({
			title: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(300)]],
			classificationCode: [''],
			incidentReference: ['', Validators.maxLength(20)],
			audience: ['', Validators.required],
			expiration: [''],
			isManualPublish: [false]
		});
	}

	private loadArticle(): void {
		if (this.article !== this._oldArticle) {
			this.id = this._oldId = null;
			this.version = this._oldVersion = null;
			this.type = this._oldType = null;
			this._oldArticle = this.article;
		}
		else if (!!this._sourceIncidentId) {
			// we are transforming an incident to an article type
			this.transformArticle().subscribe(
				response => {
					if (response) {
						this._oldArticle = this.article = response;
						this.threadId = this.article.articleTypeLabel.replace(/\s/g, '').toLowerCase() + this.article.articleId;

						this._articleService.getArticleType(this.type).subscribe(definition => {
							this.definition = definition;
							this.audiences = definition.audiences;

							// this seems soooooo wrong
							setTimeout(() => { this.updateForm(); }, 0);
						});
					}
				},
				() => {
					// incident could not be found, default to create new article
					// TODO make this a bit cleaner, possibly add toast message at the top stating 404 message?
					this._alertService.show('An unexpected error seems to have occurred. Please try the action again or refresh your page. If the problem continues, you can contact the <a href="mailto:BATS-Alexandria@commvault.com">BATS-Alexandria</a> team for assistance.');
					this.transformArticle()
						.subscribe((result: Article) => {
							this._oldArticle = this.article = Object.assign(result, this.articleFormGroup.value);
							this.threadId = this.article.articleTypeLabel.replace(/\s/g, '').toLowerCase() + this.article.articleId;

							// this seems soooooo wrong
							setTimeout(() => { this.updateForm(); }, 0);
						});
				}
			);
		}
		else if (this.id !== this._oldId || this.version !== this._oldVersion) {
			// check to see if we want to transform this to a different article type
			if (!!this._transform) {
				this._subscriptions.add(
					this.transformArticle().subscribe(
						response => {
							if (response) {
								this._oldArticle = this.article = response;
								this.article.sourceArticleId = this.id;
								this.threadId = this.article.articleTypeLabel.replace(/\s/g, '').toLowerCase() + this.article.articleId;

								this._articleService.getArticleType(this.type).subscribe(definition => {
									this.definition = definition;
									this.audiences = definition.audiences;

									// this seems soooooo wrong
									setTimeout(() => { this.updateForm(); }, 0);
								});
							}
						},
						() => {
							// major version of article could not be found, default to create new article
							// TODO make this a bit cleaner, possibly add toast message at the top stating 404 message?
							this._subscriptions.add(
								this.transformArticle()
									.subscribe((result: Article) => {
										this._oldArticle = this.article = Object.assign(result, this.articleFormGroup.value);
										this.threadId = this.article.articleTypeLabel.replace(/\s/g, '').toLowerCase() + this.article.articleId;

										// this seems soooooo wrong
										setTimeout(() => { this.updateForm(); }, 0);
									})
							);
						})
				);
			}
			else {
				// we are editing an existing article to create a new version of the same article type
				this._subscriptions.add(
					this.retrieveExistingArticle().subscribe(response => {
						if (response && !!response.length) {
							// article result.. this, in theory, should never be null if response exists
							var result = response && response[0] ? response[0] : null;

							// gets the technical note meta data
							var metadata = response && response[1] ? response[1] : null;

							// go get the type based on the result's odata type
							let articleType = this.updateArticleType(result['@odata.type']);
							this._oldArticle = this.article = result;
							this.threadId = this.article.articleTypeLabel.replace(/\s/g, '').toLowerCase() + this.article.articleId;

							if (metadata) {
								this.articleMetadata = metadata;
								Object.assign(this.article, metadata);
							}

							this._articleService.getArticleType(articleType).subscribe(definition => {
								this.definition = definition;
								this.audiences = definition.audiences;

								// this seems soooooo wrong
								setTimeout(() => { this.updateForm(); }, 0);
							});
						}
					})
				);
			}
		}
		else if (this.type !== this._oldType) {
			this._subscriptions.add(
				this.transformArticle().subscribe(response => {
					if (response) {
						this._oldArticle = this.article = response;
						this.article.sourceArticleId = this.id;
						this.threadId = this.article.articleTypeLabel.replace(/\s/g, '').toLowerCase() + this.article.articleId;

						this._articleService.getArticleType(this.type).subscribe(definition => {
							this.definition = definition;
							this.audiences = definition.audiences;

							// this seems soooooo wrong
							setTimeout(() => { this.updateForm(); }, 0);
						});
					}
				})

			);
		}
	}

	private updateArticleType(_articletype: string): string {
		switch (_articletype) {
			case "#Alexandria.Entities.Articles.TechnicalNoteArticle":
			case "Technical Note":
				return "technicalnote";
			case "#Alexandria.Entities.Articles.SolutionArticle":
			case "Solution":
				return "solution";
			case "#Alexandria.Entities.Articles.TroubleshootingArticle":
			case "Troubleshooting":
				return "troubleshooting";
			case "#Alexandria.Entities.Articles.FrequentlyAskedQuestionArticle":
			case "Frequently Asked Question":
				return "frequentlyaskedquestion";
			case "#Alexandria.Entities.Articles.BestPracticesArticle":
			case "Best Practices":
				return "bestpractices";
			case "#Alexandria.Entities.Articles.TechnicalReferenceArticle":
			case "Technical Reference":
				return "technicalreference";
			default:
				return "";
		}
	}

	private retrieveExistingArticle(): Observable<[Article, ArticleMetaData]> {
		this.type = this._oldType = null;
		this.article = this._oldArticle = null;

		// need to go get data
		if (!this.id) {
			console.debug("create-edit-articles.component.getArticleData | Skipping due to missing article key");

			return;
		}

		let expand = 'articleDeflectedIncidents, articleTargetedVersions, articleLogPhrases, articleContributors($expand = user), *';

		var articleSubscription = this._articleService.getArticles(this.id, this.version, expand);
		var getMetadata = this._articleService.getArticleMetadata(this.id, this.version);

		return forkJoin(articleSubscription, getMetadata);
	}

	private transformArticle(): Observable<Article> {
		this.article = this._oldArticle = null;

		if (!!this._sourceIncidentId) {
			return this._transformService.getArticleTransform(this._sourceIncidentId, 'Incident', this.type);
		}

		if (!!this.id) {
			return this._transformService.getArticleTransform(this.id.toString(), 'Article', this.type);
		}

		return this._articleService.getDefaultedArticle(this.type);
	}

	private updateForm(): void {
		this.articleFormGroup.patchValue({
			'title': this.article.title,
			'incidentReference': this.article.incidentReference,
			'classificationCode': this.article.classificationCode,
			'audience': this.article.audience,
			'expiration': this.article.expiration,
			'isManualPublish': this.article.isManualPublish
		});

		switch (this.definition.id) {
			case "technicalnote":
				this.patchTechnicalNoteValues(this.article);
				break;
			case "solution":
				this.patchSolutionValues(this.article);
				break;
			case "troubleshooting":
				this.patchTroubleshootingValues(this.article);
				break;
			case "frequentlyaskedquestion":
				this.patchFrequentlyAskedQuestionValues(this.article);
				break;
			case "bestpractices":
				this.patchBestPracticesValues(this.article);
				break;
			case "technicalreference":
				this.patchTechnicalReferenceValues(this.article);
				break;
		}
	}

	// The patch methods SHOULD ONLY patch the values that are explicity used in this COMPONENT
	// the children component should handle their own
	private patchSolutionValues(article: Article): void {
		let solutionArticle = (article as SolutionArticle);

		this.articleFormGroup.controls.classificationCode.setValidators([Validators.required]);

		this.articleFormGroup.patchValue({
			'content': solutionArticle.content,
			'proposedCommonIssueTitle': solutionArticle.proposedCommonIssueTitle
		});

		// While the function FormGroup.updateValueAndValidity() exists it does not traverse down to children
		this.articleFormGroup.controls.classificationCode.updateValueAndValidity();

		this.type = this._oldType = "solution";
	}

	// The patch methods SHOULD ONLY patch the values that are explicity used in this COMPONENT
	// the children component should handle their own
	private patchFrequentlyAskedQuestionValues(article: Article): void {
		let faqArticle = (article as FrequentlyAskedQuestionArticle);

		this.articleFormGroup.controls.expiration.setValidators([Validators.required]);

		this.articleFormGroup.patchValue({
			'content': faqArticle.content,
			'categoryId': faqArticle.categoryId
		});

		// While the function FormGroup.updateValueAndValidity() exists it does not traverse down to children
		this.articleFormGroup.controls.expiration.updateValueAndValidity();

		this.type = this._oldType = "frequentlyaskedquestion";
	}

	// The patch methods SHOULD ONLY patch the values that are explicity used in this COMPONENT
	// the children component should handle their own
	private patchBestPracticesValues(article: Article): void {
		let bestPracticesArticle = (article as BestPracticesArticle);

		this.articleFormGroup.controls.expiration.setValidators([Validators.required]);

		this.articleFormGroup.patchValue({
			'content': bestPracticesArticle.content,
			'categoryId': bestPracticesArticle.categoryId
		});

		// While the function FormGroup.updateValueAndValidity() exists it does not traverse down to children
		this.articleFormGroup.controls.expiration.updateValueAndValidity();

		this.type = this._oldType = "bestpractices";
	}

	// The patch methods SHOULD ONLY patch the values that are explicity used in this COMPONENT
	// the children component should handle their own
	private patchTechnicalReferenceValues(article: Article): void {
		let technicalReferenceArticle = (article as TechnicalReferenceArticle);

		this.articleFormGroup.controls.expiration.setValidators([Validators.required]);

		this.articleFormGroup.patchValue({
			'content': technicalReferenceArticle.content,
			'categoryId': technicalReferenceArticle.categoryId
		});

		// While the function FormGroup.updateValueAndValidity() exists it does not traverse down to children
		this.articleFormGroup.controls.expiration.updateValueAndValidity();

		this.type = this._oldType = "technicalreference";
	}

	// The patch methods SHOULD ONLY patch the values that are explicity used in this COMPONENT
	// the children component should handle their own
	private patchTechnicalNoteValues(article: Article): void {
		let technicalNoteArticle = (article as TechnicalNoteArticle);

		this.articleFormGroup.controls.classificationCode.setValidators([Validators.required]);

		this.articleFormGroup.patchValue({
			'symptoms': technicalNoteArticle.symptoms,
			'resolution': technicalNoteArticle.resolution,
			'additionalResources': technicalNoteArticle.additionalResources,
			'moreInformation': technicalNoteArticle.moreInformation
		});

		// While the function FormGroup.updateValueAndValidity() exists it does not traverse down to children
		this.articleFormGroup.controls.classificationCode.updateValueAndValidity();

		this.type = this._oldType = "technicalnote";
	}

	// The patch methods SHOULD ONLY patch the values that are explicity used in this COMPONENT
	// the children component should handle their own
	private patchTroubleshootingValues(article: Article): void {
		let troubleshootingArticle = (article as TroubleshootingArticle);

		this.articleFormGroup.controls.expiration.setValidators([Validators.required]);

		this.articleFormGroup.patchValue({
			'title': troubleshootingArticle.title,
			'cause': troubleshootingArticle.cause,
			'resolution': troubleshootingArticle.resolution,
			'categoryId': troubleshootingArticle.categoryId,
			'symptoms': troubleshootingArticle.symptoms,
			'description': troubleshootingArticle.description
		});

		// While the function FormGroup.updateValueAndValidity() exists it does not traverse down to children
		this.articleFormGroup.controls.expiration.updateValueAndValidity();

		this.type = this._oldType = "troubleshooting";
	}

	private mapFormToArticle(): any {
		let articleToSend: any = new Object();

		Object.assign(articleToSend, this.article, this.articleFormGroup.value);
		// we need to alter the type if we're editing an existing article,
		// and this.article does not have a type property
		articleToSend.type = this.type;

		delete articleToSend.category;

		delete articleToSend.technicalReviewerForm;
		delete articleToSend.technicalReviewerValidator;
		articleToSend.affectedApplications = this.applications.filter(r => this.article.articleTargetedVersions.map(item => item.versionApplicationId).indexOf(r.id) >= 0).map(item => item.name).sort().join(', ');
		return articleToSend;
	}

	private getArticleToPreview(): any {
		let articleToPreview = this.mapFormToArticle();

		articleToPreview.articleTypeLabel = this.type; //we need this to display values for article specific fields when creating a new article

		// need to get values for classification breadcrumb, collaborator names, targeted role names, and properly set log phrases
		if (!!this.articleFormGroup.value['classificationCode']) {
			this._subscriptions.add(
				this._classificationService.getBreadcrumb(articleToPreview.classificationCode)
					.subscribe(response => {
						articleToPreview.classificationCode = articleToPreview.classificationCode;
						articleToPreview.classificationText = response;
					})
			);
		}

		let contributors = new Array<ArticleContributor>();
		for (var i = 0; i < articleToPreview.collaborators.length; i++) {
			this._subscriptions.add(
				this._usersService.getUserById(articleToPreview.collaborators[i])
					.subscribe(response => {
						let newContributor = new ArticleContributor();
						newContributor.user = new ArticleAuthor(); // all the users in the contributors collection are of type ArticleAuthor
						newContributor.user.id = response[0].id;
						newContributor.user.userName = response[0].userName;

						contributors.push(newContributor);
					})
			);
		}

		articleToPreview.articleContributors = contributors;
		return articleToPreview;
	}

	// this helper method will be used for previewing metadata in modal
	private getArticleMetadata(article: Article): ArticleMetaData {
		let metadata = new ArticleMetaData();

		if (this.type == 'technicalnote') {
			this._subscriptions.add(
				this._rolesService.getTeams().subscribe(response => {
					let tempRoles = new Array<ArticleTargetRole>();
					for (var i = 0; i < this.articleFormGroup.value['targetedRoles'].length; i++) {
						let tempTeamRole = new Role();
						tempTeamRole.id = this.articleFormGroup.value['targetedRoles'][i].roleId;
						tempTeamRole.name = response.filter(x => x.id == tempTeamRole.id)[0].name;

						let tempArticleRole = new ArticleTargetRole();
						tempArticleRole.role = tempTeamRole;
						tempRoles.push(tempArticleRole);
					}
					metadata.targetedRoles = tempRoles;
				})
			);
		}
		else {
			// all other articles that use metadata are KB type, so casting as any KB type should give the result for technicalReviewer
			metadata.technicalReviewer = (article as TroubleshootingArticle).technicalReviewer;
		}

		return metadata;
	}

	private getAudience(audience: string): string {
		switch (audience) {
			case "Internal":
				return "1";
			case "Partners":
				return "2";
			case "Customers":
				return "3";
		};
	}

	public hasProperty(obj: any, propertyName: string): boolean {
		// unlike in C#, you cannot check if an object has a property if it is null
		if (!obj) {
			return false;
		}

		return propertyName in obj;
	}

	public articleHasProperty(propertyName: string): boolean {
		return this.hasProperty(this.article, propertyName);
	}

	public saveArticle(isMajorVersion: boolean = false, isMinorRevision: boolean = false): void {
		this.hasBeenSubmitted = true;

		if (this.articleFormGroup.invalid) {
			return;
		}

		this._modalRef = this._modalService.show(PublishConfirmationComponent);
		this._subscriptions.add(
			this._modalRef.content.onClose.subscribe(result => {
				if (result.isCancelled) {
					return;
				}

				let articleToSend = this.mapFormToArticle();

				articleToSend.isMinorRevision = isMinorRevision;
				articleToSend.audience = this.getAudience(articleToSend.audience);
				articleToSend.comment = result.comment;

				this._articleService.saveArticle(articleToSend, isMajorVersion)
					.subscribe(response => {
						this._router.navigate([`/article/details/${response.articleId}/${response.version}`]);
					});
			})
		);
	}

	public openPreviewModal() {
		let previewArticle = this.getArticleToPreview();
		let metadata: ArticleMetaData = null;

		if (this.type != 'solution') {
			metadata = this.getArticleMetadata(previewArticle);
		}

		const initialState = {
			article: previewArticle,
			isPreview: true,
			metadata: metadata
		}

		this._modalRef = this._modalService.show(ArticlesViewerModalPreviewComponent, { initialState: initialState, class: 'modal-xlg' });
	}

}
