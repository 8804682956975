import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { ArticleService } from '../../../../../../../core/services/articles/article.service';
import { Article } from '../../../../../../../core/models/articles/article.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-reject-review-confirmation',
  templateUrl: './reject-review-confirmation.component.pug',
  styleUrls: ['./reject-review-confirmation.component.scss']
})
export class RejectReviewConfirmationComponent implements OnInit, OnDestroy {

	@Input()
	public article: Article;

	public formGroup: FormGroup;

	public submitted: boolean = false;

	private _subscriptions = new Subscription();

	constructor(private _fb: FormBuilder, private _bsModalRef: BsModalRef, private _articleService: ArticleService, private _router: Router) { }

	ngOnInit() {
		this.formGroup = this._fb.group({
			denialReason: ['', [Validators.required, Validators.minLength(5)]]
		});
	}

	public rejectReview(): void {
		if (this.article) {
			this.submitted = true;
			this._subscriptions.add(
				this._articleService.denyReview(this.article.articleId, this.article.version, this.formGroup.value["denialReason"])
					.subscribe(response => {
						if (response && response.articleId && response.version) {
							this._router.navigate([`/article/details/${response.articleId}/${response.version}`]);
							this._bsModalRef.hide();
						} else {
							// this error should not happen as it should be caught by the global error handler. But adding this just in case
							this.submitted = false;
							throw new Error("Article approval did not complete successfully");
						}
					})
			);
		}
	}

	public close(): void {
		this._bsModalRef.hide();
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
