import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ArticleQualityVote } from '../../../../core/models/articles/article-quality-vote.model';
import { UserProfileService } from '../../../../core/services/user-profiles/user-profile.service';

@Component({
	selector: 'app-voting-dislike-comment',
	templateUrl: './voting-dislike-comment.component.html',
	styleUrls: ['./voting-dislike-comment.component.scss']
})
export class VotingDislikeCommentComponent implements OnInit {

	public commentForm = new FormGroup({
		commentInput: new FormControl('', Validators.required),
		commentIsAnonymous: new FormControl('')
	});

	public onClose: Subject<ArticleQualityVote>;

	private _subscriptions = new Subscription();

	constructor(private _bsModalRef: BsModalRef, private _profileService: UserProfileService) { }

	ngOnInit() {
		this.onClose = new Subject();
		this.getUserProfile();		
	}

	public submit(): void {
		let vote: any = {
			comment: this.commentForm.value.commentInput,
			isAnonymous: this.commentForm.value.commentIsAnonymous == "" ? false : this.commentForm.value.commentIsAnonymous,
			vote: false,
			isCancel: false
		};

		this.onClose.next(vote);

		this._bsModalRef.hide();
	}

	public onCancel(): void {
		let vote: any = {
			comment: "",
			isAnonymous: false,
			vote: false,
			isCancel: true
		};

		this.onClose.next(vote);
		this._bsModalRef.hide();
	}

	private getUserProfile() {
		this._subscriptions.add(
			this._profileService.getMyProfile()
				.subscribe(user => {					
					this.commentForm.setValue({ commentInput: "", commentIsAnonymous: user.defaultToAnonymousComments })
				})
		);
	}
}
