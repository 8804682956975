import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthPermissionsGuard } from '../../core/services/permissions/auth-permissions.guard';

import { ClassificationTreeManagementComponent } from './components/classification-tree-management/classification-tree-management.component';
import { ClassificationDiagnosticsManagementComponent } from './components/classification-diagnostics-management/classification-diagnostics-management.component';

const routes: Routes = [
	{
		path: 'classification', canActivate: [AuthPermissionsGuard], children: [
			{ path: '', component: ClassificationTreeManagementComponent },
			{ path: 'management', component: ClassificationTreeManagementComponent },
			{
				path: 'diagnostics', component: ClassificationDiagnosticsManagementComponent, canActivate: [AuthPermissionsGuard], data: {
					roles: ['manage-classification']
				}
			}
		]
	}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClassificationRoutingModule { }
