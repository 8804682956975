// this file is improperly being used as a base class on version.ts and needs to be cleaned up in the future

export class ArticleFixedVersion {

	public articleId: number;

	public version: string;

	public versionApplicationId: string;

	public versionMajor: number;

	public versionMinor: number;
		
	public versionBuild: number;

	public versionRevision: number;

	public versionServicePack: string;

	public versionIsReleased: boolean;

}
