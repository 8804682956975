import { CommentThread } from "./comment-thread.model";

export class Comment {

	public id: number;

	public userId: string;

	public content: string;

	public thread: CommentThread;

	public created: Date;

	public lastUpdated: Date;

	public parent: Comment;

	public replies: Array<Comment>;

	public userName: string;

	public userEmail: string;

	public isAnonymous: boolean;

}
