import { Article } from "./article.model";
import { Category } from "../categories/category.model";

export class FrequentlyAskedQuestionArticle
	extends Article {

	public type: string = "frequentlyaskedquestion";

	public content: string = null;

	public categoryId: number = null;

	public category: Category = null;

}
