import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, ValidatorFn } from '@angular/forms';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { BsModalRef } from 'ngx-bootstrap';

import { ClassificationService } from '../../../services/classification/classification.service';

import { GraphNode } from '../../../models/graph-node.model';
import { SubmittedGraphNode } from '../../../models/submitted-graph-node.model';

@Component({
	selector: 'app-function-editor',
	templateUrl: './function-editor.component.pug',
	styleUrls: ['./function-editor.component.scss']
})
export class FunctionEditorComponent implements OnInit, OnDestroy {

	@Input()
	public nodeId: number;

	@Input()
	public parentNodeId: number;

	@Output()
	public nodeCreated = new EventEmitter<GraphNode>();

	@Output()
	public nodeEdited = new EventEmitter<GraphNode>();

	public node: GraphNode;

	public nodeForm: FormGroup;

	public hasBeenSubmitted: boolean;

	public isCollapsed: boolean = true;

	private _subscriptions = new Subscription();

	constructor(private _bsModalRef: BsModalRef, private _fb: FormBuilder, private _classificationService: ClassificationService) {
	}

	ngOnInit() {
		this.createForm();

		if (this.nodeId) {
			this.loadNode();
		}
		else {
			this.node = new GraphNode();
		}
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

	private loadNode(): void {
		this._subscriptions.add(
			this._classificationService.getNode(this.nodeId)
				.subscribe(response => {
					if (response && response.value) {
						if (!response.value.length) {
							throw new Error(`Unable to retrieve Classification Node '${this.nodeId}'.`);
						}
						else if (response.value.length > 1) {
							// this is odd...
							throw new Error(`More than one result for Classification Node '${this.nodeId}'. This should not be possible.`);
						}
						else {
							this.node = response.value[0];

							this.updateFormFromModel();
						}
					}
				})
		);
	}

	private createForm(): void {
		this.nodeForm = this._fb.group({
			id: [''],
			title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
			isInternal: [false],
			isSaas: [false],
			maturityLevel: [''],
			feedbackPrompt: [false]
		});
	}

	private getModelFromForm(): SubmittedGraphNode {
		let node = new SubmittedGraphNode();

		let values = this.nodeForm.value;

		node.id = (values.id ? values.id : null);
		node.parentId = this.parentNodeId;

		node.nodeType = 'function';

		node.title = values.title;

		node.tags = new Array<string>();

		node.tags.push('Function');

		if (values.isInternal) {
			node.tags.push('Private');
		}
		else {
			node.tags.push('Public');
		}

		// need to figure out how feedback is handled

		return node;
	}

	private updateFormFromModel(): void {
		let node = this.node;

		if (!node) {
			node = new GraphNode();
		}

		let isInternal = node.tags.map(t => t.toLowerCase()).includes('private');
		let isSaas = node.tags.map(t => t.toLowerCase()).includes('saas');
		let maturityLevel = node.tags.filter(t => t.toLowerCase().endsWith('release'));

		this.nodeForm.setValue({
			id: this.node.id,
			title: this.node.title,
			isInternal: isInternal,
			isSaas: isSaas,
			maturityLevel: maturityLevel,
			feedbackPrompt: false
		});
	}

	public close() {
		this._bsModalRef.hide();
	}

	public submit() {
		this.hasBeenSubmitted = true;

		if (this.nodeForm.invalid) {
			return;
		}

		let node = this.getModelFromForm();

		this.nodeForm.disable();

		this._subscriptions.add(
			this._classificationService.saveNode(node)
				.pipe(
					finalize(() => {
						this.nodeForm.enable();
					})
				)
				.subscribe(response => {
					if (!this.node.id) {
						this.nodeCreated.emit(response);
					}
					else {
						this.nodeEdited.emit(response);
					}
					this.close();
				})
		);
	}

}

function minSelected(min = 1) {
	const validator: ValidatorFn = (formArray: FormArray) => {
		const totalSelected = formArray.controls
			.map(control => control.value)
			.reduce((prev, next) => next ? prev + next : prev, 0);

		return totalSelected >= min ? null : { required: true };
	};

	return validator;
}
