import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { LoggingService } from './logging.service';
import { AlertService } from '../alert/alert.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

	constructor(private _injector: Injector) { }

	handleError(error: Error | HttpErrorResponse) {
		let alertService = this._injector.get(AlertService);

		if (error instanceof HttpErrorResponse) {
			if (!navigator.onLine) {
				alertService.show('Network connectivity seems to be down. Please resolve and then try action again.');
			} else if (error.status == 401 || error.status == 404) {
				// swallow the error since this in not an error we want to output
				return;
			} else {
				// all other errors, show an error message
				// logs to the alert service so a component listening on the subscription can show the error
				alertService.show('An unexpected error seems to have occurred. Please try the action again or refresh your page. If the problem continues, you can contact the <a href="mailto:BATS-Alexandria@commvault.com">BATS-Alexandria</a> team for assistance.');
			}
		}
		else {
			let loggingService = this._injector.get(LoggingService);
			loggingService.logError(error, 'ErrorHandlerService');
		}

		// log on the server
		throw error;
	}

}
