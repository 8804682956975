import { NgModule, APP_INITIALIZER } from '@angular/core';

import { ConfigurationService } from './services/configuration.service';

export function initConfig(configurationService: ConfigurationService) {
	return () => configurationService.loadConfiguration();
}

@NgModule({
	providers: [
		ConfigurationService,
		{
			provide: APP_INITIALIZER,
			useFactory: initConfig,
			deps: [ConfigurationService],
			multi: true,
		}
	]
})
export class RuntimeConfigurationModule {
}
