import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ShareViewModel } from '../../models/share/share.model';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
	})
};

@Injectable({
	providedIn: 'root'
})
export class ShareService {

	private _urlBase: string = "api/share";
	
	constructor(private _httpClient: HttpClient) { }

	public share(request: ShareViewModel): Observable<boolean> {		
		return this._httpClient
			.post<boolean>(this._urlBase, request, httpOptions);
	}
	
}
