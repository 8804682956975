﻿export class FieldEscalationSubmission {

	public escalationDescription: string;

	public emailAddress: string;

	public userFullName: string;

	public emailCC: string[];

}
