import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { SpinnerService } from './core/services/spinner/spinner.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.pug',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {

	constructor(public spinnerService: SpinnerService, private _router: Router) {
	}

	ngOnInit(): void {
		let hash = window.location.hash;

		// handle hashbang redirects here... if the hashbang starts with !/, then let's go through the paces to redirect
		if (hash.indexOf("#!/") == 0) {
			// redirect for articles
			if (hash.indexOf("#!/article/details/") > -1) {
				let urlSplit = hash.split("/");
				let version = urlSplit[urlSplit.length - 1];
				let article = urlSplit[urlSplit.length - 2];

				this._router.navigateByUrl(`article/details/${article}/${version}`);
			} else if (hash.indexOf("#!/incident/details/") > -1) {
				// handles redirect for incidents and escalations
				// in incidents and escalations one of the features is for the passed in search term to be highlighted on the incident activities
				// our query string for the search terms changed from 'searchTerms' to 'q'. this updates the URL to the correct query string before redirecting
				hash = hash.replace(/(\;searchTerms=)/, '?q=');
				hash = hash.replace(/(\?|&)(searchTerms=)/, '$1q=');

				// handles incidents as well as directly redirects all other URLs that we previously used to the new URL format
				this._router.navigateByUrl(hash.replace("#!/", ""));
			} else {
				// does a direct replacement for hashbang to just strictly /. This allows for many bookmarked links to still work appropriately
				this._router.navigateByUrl(hash.replace("#!/", ""));
			}
		}
	}

}
