import { Component, Input } from "@angular/core";

@Component({
	selector: "app-widget",
	templateUrl: "./widget.component.html",
	styleUrls: ["./widget.component.scss"]
})
export class WidgetComponent {

	@Input()
		caption: string;

	@Input()
		cardBody: boolean = true;
}
