import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from "rxjs";
import { catchError, map, tap, share, shareReplay } from "rxjs/operators";

import { SearchResult } from "../../models/searches/search.result.model";
import { UnifiedSearchResponse } from "../../models/searches/unified-search-response.model";
import { SearchRequest } from "../../models/searches/search.request.model";
import { KeyValue } from "../../models/searches/key-value.model";

@Injectable({
	providedIn: "root"
})
export class SearchService {

	private _urlBase: string = "api/search";

	// as long as we are using this in search.component this needs to be public
	public params: HttpParams;

	constructor(private http: HttpClient) { }

	public getSearchResults(searchRequest: SearchRequest): Observable<UnifiedSearchResponse> {
		// This explicit filter is to prevent any incidents or escalations with more than 2 downvotes from showing
		if (searchRequest.explicitSearchTerms) {
			const explicitIncidentVotesIndex = (explicitSearchTerms) => explicitSearchTerms.key == 'incidents' && explicitSearchTerms.value == 'votes:[-2 TO *]';
			if (searchRequest.explicitSearchTerms.findIndex(explicitIncidentVotesIndex) < 0) {
				searchRequest.explicitSearchTerms.push({ key: 'incidents', value: 'votes:[-2 TO *]' });
			}

			const explicitEscalationVotesIndex = (explicitSearchTerms) => explicitSearchTerms.key == 'escalations' && explicitSearchTerms.value == 'votes:[-2 TO *]';
			if (searchRequest.explicitSearchTerms.findIndex(explicitEscalationVotesIndex) < 0) {
				searchRequest.explicitSearchTerms.push({ key: 'escalations', value: 'votes:[-2 TO *]' });
			}
		}
		else {
			searchRequest.explicitSearchTerms = new Array<KeyValue>();
			searchRequest.explicitSearchTerms.push({ key: 'incidents', value: 'votes:[-2 TO *]' });
			searchRequest.explicitSearchTerms.push({ key: 'escalations', value: 'votes:[-2 TO *]' });
		}

		let newRequest = searchRequest;

		this.params = this.searchRequestToAliasedParams(searchRequest);

		const url = searchRequest && typeof (searchRequest.searchTerms) != "undefined" ? `${this._urlBase}` : `${this._urlBase}`;

		return this.http
			.get<UnifiedSearchResponse>(url, { params: this.params })
			.pipe(
				map(resp => {
					// i don't like hardcoding the collection name here, but result.resultSets[0] will not work here - need to revisit

					// can't seem to find a cleaner way to do this right now, (possibly revisit later)
					// but we need to transform the object '$values' into an array to be used in the ngFor statements
					// on the search results view

					// values for article results
					if (resp.resultSets.articles) {
						resp.resultSets.articles = resp.resultSets.articles['$values'];
						for (var i = 0; i < resp.resultSets.articles.length; i++) {
							resp.resultSets.articles[i].articleTypeId = resp.resultSets.articles[i].articleType == "TechnicalNote" ? 1 : 3;
						}
					}

					// values for incident results
					if (resp.resultSets.incidents) {
						resp.resultSets.incidents = resp.resultSets.incidents['$values'];
					}

					// values for escalation results
					if (resp.resultSets.escalations) {
						resp.resultSets.escalations = resp.resultSets.escalations['$values'];
					}

					// values for partner service requests results
					if (resp.resultSets.psrs) {
						resp.resultSets.psrs = resp.resultSets.psrs['$values'];
					}

					for (var i = 0; i < resp.facets.length; i++) {
						resp.facets[i].items = resp.facets[i].items['$values'];
						resp.facets[i].maxItemLength = 0;

						for (let item of resp.facets[i].items) {
							resp.facets[i].maxItemLength = Math.max(resp.facets[i].maxItemLength, item.key.length + item.value.toString().length + 3);
						}
					}

					return resp;
				})
			);
	}

	private isJsObject(o): boolean {
		return o !== null && (typeof o === 'function' || typeof o === 'object');
	}

	private searchRequestToAliasedParams(searchRequest: SearchRequest): HttpParams {
		let params = new HttpParams();

		let add = function (key: string, value: any) {
			if (typeof (value) == "undefined") {
				value = "";
			}

			value = typeof value === 'function' ? value() : (value === null ? "" : value);
			params = params.append(key, value);
		};

		if (!!searchRequest.searchTerms) {
			add('q', searchRequest.searchTerms);
		}

		if (!!searchRequest.explicitSearchTerms) {
			for (var i = 0; i < searchRequest.explicitSearchTerms.length; i++) {
				add(`explicitSearchTerms[${i}].key`, searchRequest.explicitSearchTerms[i].key);
				add(`explicitSearchTerms[${i}].value`, searchRequest.explicitSearchTerms[i].value);
			}
		}

		if (!!searchRequest.facets) {
			let combinedFacetValue = '';
			for (var i = 0; i < searchRequest.facets.length; i++) {
				if (searchRequest.facets[i] != undefined && searchRequest.facets[i] != null && searchRequest.facets[i].field != '') {
					combinedFacetValue = combinedFacetValue == '' ? combinedFacetValue.concat(`${searchRequest.facets[i].field}:${searchRequest.facets[i].value}`) : combinedFacetValue.concat(`,`, `${searchRequest.facets[i].field}:${searchRequest.facets[i].value}`);
				}
			}
			if (!!combinedFacetValue) {
				add('fq', combinedFacetValue);
			}
		}

		if (!!searchRequest.sortOrder) {
			let combinedSortValue = '';
			for (var i = 0; i < searchRequest.sortOrder.length; i++) {
				if (searchRequest.sortOrder[i] != undefined && searchRequest.sortOrder[i] != null && searchRequest.sortOrder[i].field != '') {
					combinedSortValue = combinedSortValue == '' ? combinedSortValue.concat(`${searchRequest.sortOrder[i].field}:${searchRequest.sortOrder[i].direction}`) : combinedSortValue.concat(`,`, `${searchRequest.sortOrder[i].field}:${searchRequest.sortOrder[i].direction}`);
				}
			}
			if (combinedSortValue != '') {
				add('s', combinedSortValue);
			}
		}

		if (!searchRequest.escapeSearchTerms) {
			searchRequest.escapeSearchTerms = true;
		}
		add('escapeSearchTerms', searchRequest.escapeSearchTerms);

		if (!!searchRequest.pageIndex) {
			add('pi', searchRequest.pageIndex);
		}

		if (!!searchRequest.includeFilteredFacets) {
			add('includeFilteredFacets', searchRequest.includeFilteredFacets);
		}

		if (!!searchRequest.maxPaginationSize) {
			add('maxPaginationSize', searchRequest.maxPaginationSize);
		}

		if (!!searchRequest.normalizeScores) {
			add('normalizeScores', searchRequest.normalizeScores);
		}

		if (!!searchRequest.pageSize) {
			add('ps', searchRequest.pageSize);
		}

		return params;
	}

	// at some point we should move these to a more generic location where they can be used
	// for purposes of alias routing we needed to use the specific implementation above
	private objectToSearchParams(object: any): HttpParams {
		let params = new HttpParams();

		let add = function (key: string, value: any) {
			if (typeof (value) == "undefined") {
				value = "";
			}

			value = typeof value === 'function' ? value() : (value === null ? "" : value);
			params = params.append(key, value);
		};

		if (Array.isArray(object) || !this.isJsObject(object)) {
			Object.keys(object)
				.map((key) => add(key, object[key]));
		}
		else {
			Object.keys(object)
				.map((key) => this.toSearchParam(key, object[key], add));
		}

		return params;
	}

	private toSearchParam(prefix: string, obj: any, add: Function): void {
		if (Array.isArray(obj)) {
			Object.keys(obj)
				.map((key) => this.toSearchParam(prefix + "[" + key + "]", obj[key], add));
		}
		else if (this.isJsObject(obj)) {
			Object.keys(obj)
				.map((key) => this.toSearchParam(prefix + "." + key, obj[key], add));
		}
		else {
			// add scalar
			add(prefix, obj);
		}
	}

}
