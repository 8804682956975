import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-metallic-ticket-confirmation',
  templateUrl: './metallic-ticket-confirmation.component.pug',
  styleUrls: ['./metallic-ticket-confirmation.component.scss']
})
export class MetallicTicketConfirmationComponent implements OnInit {
  public caseID;
  
  constructor(private _bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  public onCancel(): void {
		this._bsModalRef.hide();
	}

}
