import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { CollapseModule, AccordionModule } from 'ngx-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ClassificationRoutingModule } from './classification-routing.module';

import { SharedModule } from '../../shared/shared.module';

import { ClassificationService } from './services/classification/classification.service';
import { ClassificationSelectionService } from './services/classification-selection/classification-selection.service';

import { ClassificationTreeComponent } from './components/classification-tree/classification-tree.component';
import { ClassificationTreeManagementComponent } from './components/classification-tree-management/classification-tree-management.component';
import { ClassificationDiagnosticsManagementComponent } from './components/classification-diagnostics-management/classification-diagnostics-management.component';
import { ProductEditorComponent } from './components/editors/product-editor/product-editor.component';
import { FunctionEditorComponent } from './components/editors/function-editor/function-editor.component';
import { CommonIssueEditorComponent } from './components/editors/common-issue-editor/common-issue-editor.component';

@NgModule({
   imports: [
	  CommonModule,
	  ReactiveFormsModule,
	  BrowserAnimationsModule,

	  TreeViewModule,
	  ListViewModule,
	  AccordionModule,
	  CollapseModule,
	  FontAwesomeModule,

	  ClassificationRoutingModule,

	  // ** App
	  SharedModule
   ],
   declarations: [
	  ClassificationTreeComponent,
	  ClassificationTreeManagementComponent,
	  ClassificationDiagnosticsManagementComponent,
	  ProductEditorComponent,
	  FunctionEditorComponent,
	  CommonIssueEditorComponent
   ],
   entryComponents: [
	  ProductEditorComponent,
	  FunctionEditorComponent,
	  CommonIssueEditorComponent
   ],
   exports: [
	  ClassificationTreeComponent
   ],
   providers: [
	  ClassificationService,
	  ClassificationSelectionService
   ]
})
export class ClassificationModule { }
