import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { finalize } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

import { ArticleWorkflowService } from '../../../../../../core/services/articles/article-workflow.service';

import { AcceptWithCommentComponent } from '../notices-and-confirmations/accept-with-comment/accept-with-comment.component';
import { RejectWithCommentComponent } from '../notices-and-confirmations/reject-with-comment/reject-with-comment.component';

import { Article } from '../../../../../../core/models/articles/article.model';
import { WorkflowState } from '../../../../../../core/models/workflow/workflow-state.model';
import { WorkflowTransition } from '../../../../../../core/models/workflow/workflow-transition.model';

@Component({
   selector: 'app-workflow-transition',
   templateUrl: './workflow-transition.component.pug',
   styleUrls: ['./workflow-transition.component.scss']
})
export class WorkflowTransitionComponent implements OnInit {

   readonly confirmationWithCustomerDataWarningAndRequiredComment = 'customerDataWarningAndRequiredComment';
   readonly confirmationWithCustomerDataWarningAndOptionalComment = 'customerDataWarningAndOptionalComment';

   private _modalRef: BsModalRef;

   @Input()
   public article: Article;

   public states: Array<WorkflowState>;

   public disableAllActions: boolean = false;

   constructor(private _workflowService: ArticleWorkflowService, private _modalService: BsModalService, private _router: Router) { }

   ngOnInit() {
	  // Considering that `article` is an `Input` I should probably put this logic in the event handler for changed values
	  this.getAllowedTransitions();
   }

   private sanitizeAllowedTransitions(): void {
	  if (!this.states) {
		 return;
	  }

	  // Not all workflow states and transitions will be supported or go unaltered in this component.
	  //
	  // For some, the reason is because we offer "non-workflow" buttons for trigger actions (for
	  // example, saving and publishing).
	  //
	  // For others, we need to hide "legacy" transitional states since only certain article types
	  // can use this component.

	  // filtering out States we do not support -- 1 Draft, 5 Reviewed (Published)
	  this.states = this.states.filter(state => [1, 5].indexOf(state.id) === -1);

	  // updating labels as needed
	  this.states = this.states.map(state => {
		 if (state.id === 4) {
			state.name = 'Pending Publish';
		 }

		 return state;
	  });
   }

   private getAllowedTransitions(): void {
	  this.disableAllActions = true;
	  this.states = null;

	  if (this.article) {
		 this._workflowService.getValidTransitions(this.article.articleId, this.article.version)
			.subscribe(response => {
			   this.states = response;
			   this.sanitizeAllowedTransitions();

			   this.disableAllActions = false;
			});
	  }
   }

   private updateConfirmationType(transition: WorkflowTransition): void {
	  if (!transition) {
		 return;
	  }

	  // Since displaying a specific type of confirmation dialog (or not one at all) is
	  // strictly a client-side UI thing, the logic of determining which confirmation to
	  // show based state/trigger combination will be mapped client-side too.
	  //
	  // As a future enhancement, instead of showing a specific confirmation we could
	  // shift towards a pattern of letting the API tell the client which additional
	  // fields are needed and then dynamically build the dialog.

	  switch (transition.id) {
		 case 1:		// Approve
			transition.confirmationType = this.confirmationWithCustomerDataWarningAndOptionalComment;
			break;
		 case 2:		// Reject
			transition.confirmationType = this.confirmationWithCustomerDataWarningAndRequiredComment;
			break;
	  }
   }

   private submitTransition(transition: WorkflowTransition, comment: string): void {
	  if (!transition) {
		 throw new Error("Argument `transition` cannot be null or empty");
	  }

	  if (!this.article) {
		 console.debug('No article was specified so unable to transition workflow state.');
	  }

	  // May need to add additional property to distinguish between button label and underlying trigger

	  // May want to update method to accept optional model instead of hard-coded comment parameter

	  this.disableAllActions = true;
	  this._workflowService.setWorkflowTransition(this.article.articleId, this.article.version, transition.name, comment)
		 .pipe(
			finalize(() => { this.disableAllActions = false; })
		 )
		 .subscribe(response => {
			this.getAllowedTransitions();

			// This approach seems really, really wrong... just seems dirty and presumptive
			this._router.navigate([`/article/details/${response.articleId}/${response.version}`]);
		 });
   }

   public onTransitionClick(transition: WorkflowTransition): void {
	  if (!transition) {
		 throw new Error('Argument `transition` cannot be null or empty');
	  }

	  this.disableAllActions = true;

	  this.updateConfirmationType(transition);

	  if (transition.confirmationType) {
		 // Show confirmation modal before submitting transition
		 switch (transition.confirmationType) {
			case this.confirmationWithCustomerDataWarningAndOptionalComment:
			   this._modalRef = this._modalService.show(AcceptWithCommentComponent, { class: 'modal-lg' });
			   break;
			case this.confirmationWithCustomerDataWarningAndRequiredComment:
			   this._modalRef = this._modalService.show(RejectWithCommentComponent, { class: 'modal-lg' });
			   break;
			default:
			   throw new Error(`Confirmation type '${transition.confirmationType}' is not supported.`);
		 }

		 this._modalRef.content.onClose
			.pipe(
			   finalize(() => {
				  this.disableAllActions = false;
				  this._modalRef = null;
			   })
			)
			.subscribe(result => {
			   if (!result.isCancelled) {
				  this.submitTransition(transition, result.comment);
			   }
			});
	  }
	  else {
		 // Just submit transition
		 this.submitTransition(transition, null);
	  }
   }

}
