import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'app-markdown-property-editor',
	templateUrl: './markdown-property-editor.component.pug',
	styleUrls: ['./markdown-property-editor.component.scss']
})
export class MarkdownPropertyEditorComponent implements OnInit {

	@Input('group')
	public formGroup: FormGroup;

	@Input()
	public controlName: string;

	@Input()
	public isRequired: boolean;

	@Input()
	public options: any;

	constructor() {
	}

	ngOnInit() {
		this.updateForm();
	}

	private updateForm(): void {
		if (this.formGroup) {
			if (this.isRequired) {
				this.formGroup.addControl(this.controlName, new FormControl('', Validators.required));
			}
			else {
				this.formGroup.addControl(this.controlName, new FormControl(''));
			}
		}
	}

}
