import { Component, OnInit, Input } from '@angular/core';

import { ArticleService } from '../../../core/services/articles/article.service';

import { LatestArticles } from '../../../core/models/articles/latest-articles.model';

@Component({
	selector: 'app-latest-articles',
	templateUrl: './latest-articles.component.pug',
	styleUrls: ['./latest-articles.component.scss']
})
export class LatestArticlesComponent implements OnInit {

	@Input()
	isFlush: boolean = true;

	public articles: Array<LatestArticles>;

	public lastUpdated: Date;

	constructor(private _articleService: ArticleService) {
	}

	ngOnInit() {
		this._articleService.getLatestArticles()
			.subscribe(data => {
				this.articles = data;
				this.lastUpdated = new Date();
			});
	}

}
