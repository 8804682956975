import { Component, ViewChild, AfterViewInit } from '@angular/core';

import { Subject } from 'rxjs';

import { Confirmation } from '../../../../../../content-manager/models/confirmation.model';
import { ConfirmationWithCommentComponent } from '../confirmation-with-comment/confirmation-with-comment.component';

@Component({
   selector: 'app-reject-with-comment',
   templateUrl: './reject-with-comment.component.pug',
   styleUrls: ['./reject-with-comment.component.scss']
})
export class RejectWithCommentComponent implements AfterViewInit {

   public onClose: Subject<Confirmation>;

   @ViewChild(ConfirmationWithCommentComponent)
   private child: ConfirmationWithCommentComponent;

   constructor() { }

   ngAfterViewInit() {
	  this.onClose = new Subject();

	  this.child.onClose.subscribe(result => {
		 this.onClose.next(result);
		 this.onClose.complete();
	  })
   }

}
