import { Component, Input } from '@angular/core';

import { FrequentlyAskedQuestionArticle } from '../../../../../core/models/articles/article-faq.model';

@Component({
  selector: 'app-articles-viewer-frequently-asked-question',
  templateUrl: './articles-viewer-frequently-asked-question.component.pug',
  styleUrls: ['./articles-viewer-frequently-asked-question.component.scss']
})
export class ArticlesViewerFrequentlyAskedQuestionComponent {

	@Input("article")
	public article: FrequentlyAskedQuestionArticle;

}
