import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isBoolean } from 'ngx-bootstrap/chronos/utils/type-checks';

@Component({
	selector: 'app-simple-search',
	templateUrl: './simple-search.component.pug',
	styleUrls: ['./simple-search.component.scss']
})
export class SimpleSearchComponent {

	@Input("incidentsOnly")
	public incidentsOnly: boolean = false;

	public searchForm: FormGroup;
		
	constructor(private fb: FormBuilder, private router: Router) {
		this.createForm();
	}

	private createForm(): void {
		this.searchForm = this.fb.group({
			searchTerms: ['', Validators.required]
		});
	}

	public onSubmit(): void {
		var searchValue = { q: this.searchForm.controls['searchTerms'].value, fq: '', explicitSearchTermsKeys: new Array<string>(), explicitSearchTermsValues: new Array<string>() };
		if (this.incidentsOnly) {
			searchValue.fq = "repository:Incidents";

			// Complex Arrays cannot be sent through queryParams in the router
			// for example in this scenario the other end will simply find explicitSearchTerms[0] = [object Object]
			// to combat this separate them out to an array of keys and corresponding array of values
			searchValue.explicitSearchTermsKeys.push('incidents');
			searchValue.explicitSearchTermsValues.push('-incidentStatus:Closed');
			searchValue.explicitSearchTermsKeys.push('incidents');
			searchValue.explicitSearchTermsValues.push('-incidentStatus:Archived');
		}

		this.router.navigate(['/search'], { queryParams: searchValue });
	}

	public clearSearch(): void {
		this.searchForm.reset({
			searchTerms: null
		});
	}

}
