import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { GridModule } from '@syncfusion/ej2-angular-grids';

import { AdminRoutingModule } from './admin-routing.module';

import { NavComponent } from './components/nav/nav.component';

import { RolesPermissionsComponent } from './components/roles-permissions/roles-permissions.component';
import { AdminToolComponent } from './components/admin-tools/admin-tool/admin-tool.component';
import { ResetUserPublishDateComponent } from './components/admin-tools/reset-user-publish-date/reset-user-publish-date.component';
import { DeleteUnusedCommonIssuesComponent } from './components/admin-tools/delete-unused-common-issues/delete-unused-common-issues.component';
import { DeleteUnusedSolutionsComponent } from './components/admin-tools/delete-unused-solutions/delete-unused-solutions.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TabsModule,
		FontAwesomeModule,
		MultiSelectModule,
		DatePickerModule,
		GridModule,
		AdminRoutingModule
	],
	declarations: [
		NavComponent,
		RolesPermissionsComponent,
		AdminToolComponent,
		ResetUserPublishDateComponent,
		DeleteUnusedCommonIssuesComponent,
		DeleteUnusedSolutionsComponent
	],
	exports: [
		AdminRoutingModule
	]
})
export class AdminModule { }
