import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ManageArticlesComponent } from './components/manage-articles/manage-articles.component';
import { CreateArticlesListComponent } from './components/create-articles-list/create-articles-list.component';
import { CreateEditArticlesComponent } from './components/create-edit-articles/create-edit-articles/create-edit-articles.component';
import { AuthPermissionsGuard } from '../../core/services/permissions/auth-permissions.guard';

const routes: Routes = [
	{
		path: 'article/create', component: CreateArticlesListComponent, canActivate: [AuthPermissionsGuard], data: {
			roles: ['compose-internal-articles', 'compose-private-articles', 'compose-public-articles']
		}
	},
	{
		path: 'article/management', component: ManageArticlesComponent, canActivate: [AuthPermissionsGuard], data: {
			roles: ['compose-internal-articles', 'compose-private-articles', 'compose-public-articles']
		}
	},
	{
		path: 'article/management/:view', component: ManageArticlesComponent, canActivate: [AuthPermissionsGuard], data: {
			roles: ['compose-internal-articles', 'compose-private-articles', 'compose-public-articles']
		}
	},
	{
		path: 'article/create/:type', component: CreateEditArticlesComponent, canActivate: [AuthPermissionsGuard], data: {
			roles: ['compose-internal-articles', 'compose-private-articles', 'compose-public-articles']
		}
	},
	{
		path: 'article/edit/:id/:version', component: CreateEditArticlesComponent, canActivate: [AuthPermissionsGuard], data: {
			roles: ['compose-internal-articles', 'compose-private-articles', 'compose-public-articles']
		}
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ContentManagerRoutingModule { }
