import { ArticleAuthor } from "./article-author.model";

export class ArticleContributor {

	public articleId: number;

	public version: number;

	public userId: string;

	public timestamp: Date;

	// WebAPI sends Enum label while WebApi sends Enum int value
	public contributorType: string;

	public user: ArticleAuthor;

}
