import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forEach } from '@angular/router/src/utils/collection';
import { IncidentService } from '../../../../../core/services/incidents/incident.service';
import { IncidentActivity } from '../../../../../core/models/incidents/incident-activity.model';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../../../../runtime-configuration/services/configuration.service';

@Component({
	selector: 'incident-activities-viewer',
	templateUrl: './incident-activities-viewer.component.pug',
	styleUrls: ['./incident-activities-viewer.component.scss']
})
export class IncidentActivitiesViewerComponent implements OnInit, OnDestroy {
	@Input()
	public id: string;

	@Input()
	public searchTerms: string;

	@Input()
	public isMetallic: boolean = false;

	public showFriendlyTimestamps: boolean = true;

	public activityFilterForm: FormGroup;

	public activities: Array<IncidentActivity>;

	public hasRemainingActivities: boolean = true;

	public internalExcludedTaskIds: number[];

	public internalFilter: string = "(Type eq 'TaskActivity'";

	public escalationFilter: string = "(Type eq 'UpdateActivity' and CV.Common.Entities.Incidents.UpdateActivity/UpdateType eq 'Email')";

	private _subscriptions = new Subscription();

	private _currentFilter: string = '';

	private _currentPageSize: number = 15;

	private readonly _defaultPageSize: number = 15;

	public isEscalated: boolean = false;

	constructor(private _incidentService: IncidentService, private _formBuilder: FormBuilder, private _route: ActivatedRoute, private _configSvc: ConfigurationService) {
		this.createForm();
	}

	ngOnInit() {
		let queryParams = this._route.snapshot.queryParams;

		this.searchTerms = this.searchTerms ? this.searchTerms : (queryParams['q'] ? queryParams['q'] : null);
		this.updateActivityData(this._currentFilter);
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

	private createForm() {
		// setup the alternate values supplied to the activityFilter
		this.internalExcludedTaskIds = this._configSvc.configuration.incidentInternalFilterExcludedTaskTypeIds;

		this.internalExcludedTaskIds.forEach(taskId => {
			this.internalFilter = `${this.internalFilter} and CV.Common.Entities.Incidents.TaskActivity/TaskTypeId ne ${taskId}`;
		});

		this.internalFilter = `${this.internalFilter} or ${this.escalationFilter})`;

		// set the activityFilter to the default of All results
		this.activityFilterForm = this._formBuilder.group({
			activityFilter: ''
		});

		this._subscriptions.add(
			this.activityFilterForm.controls['activityFilter'].valueChanges.subscribe(filterChange => {
				this._currentPageSize = this._defaultPageSize;
				this._currentFilter = filterChange;
				this.hasRemainingActivities = true;
				this.updateActivityData(this._currentFilter);
			})
		);
	}

	private updateActivityData(filter: string): void {
		this._subscriptions.add(
			this._incidentService.getActivities(this.id, filter, this._currentPageSize, this.searchTerms)
				.subscribe(activityData => {
					this.activities = activityData;
				})
		);
	}

	public timeToggle() {
		this.showFriendlyTimestamps = !this.showFriendlyTimestamps;
	}

	public expandAllActivities(e: any) {
		this.activities.forEach(activity => activity.isDisplayOpen = true);
	}

	public collapseAllActivities(e: any) {
		this.activities.forEach(activity => activity.isDisplayOpen = false);
	}

	public retrieveAllActivities(e: any) {
		this._currentPageSize = null; //do this to say we want to get everything
		this.hasRemainingActivities = false;
		this.updateActivityData(this._currentFilter);
	}

	public openChange(activity: IncidentActivity, e: any) {
		if (activity.isDisplayOpen != e) {
			activity.isDisplayOpen = e;
		}
	}	
}
