import { Article } from "./article.model";

export class SolutionArticle
	extends Article {

	public type: string = "solution";

	public content: string = null;

	public proposedCommonIssueTitle: string = null;

}
