import { Team } from "../teams/team.model";
import { IncidentActivity } from "./incident-activity.model";
import { IncidentResolutionActivity } from "./incident-resolution-activity.model";
import { IncidentDevelopmentEscalationActivity } from "./incident-development-escalation-activity.model";
import { ApplicationVersion } from "../applications/application-version.model";
import { Account } from "../accounts/account.model";
import { CommCell } from "../comm-cells/comm-cell.model";

export class Incident {

	public owningTeamId: string;

	public owningTeam: Team;

	public initialResponseTimestamp: Date;

	public timeToRespondTimestamp: Date;

	public supportType: string;

	public classificationId: string;

	public classificationText: string;

	public patchReference: string;

	public owningUserName: string;

	public patchName: string;

	public modifiedOn: Date;

	public deflectionResult: string;

	public deflectionComments: string;

	public isEscalated: boolean;

	public resolution: IncidentResolutionActivity;

	public developmentEscalation: IncidentDevelopmentEscalationActivity;

	public voteTally: number;

	public activities: Array<IncidentActivity>;

	public createdOn: Date;

	public isFieldEscalationAllowed: boolean;

	public owningUserId: string;

	public desiredOutcome: string;

	public id: string;

	public source: string;

	public ticketNumber: string;

	public title: string;

	public description: string;

	public commCellId: string;

	public commCell: CommCell;

	public originatorId: string;

	public incidentSummary: string;

	public originator: Account;

	public contactDetails: string;

	public version: ApplicationVersion;

	public severity: string;

	public errorCode: string;

	public pendingFailure: string;

	public status: string;

	public state: string;

	public businessImpact: string;

	public contactName: string;

	public lastActivity: IncidentActivity;

	public accountName: string;

	public supportTeamName: string;

	public supportPartnerName: string;

	public applicationMajorVersion: string;

	public applicationServicePack: string;

	public commCellAlias: string;

	public tenantID: string;

	public contactId: string;

	public subscriptionType: string;

	public productType: string;

	public caseOriginCode: string;

	public secondaryContactId: string;

	public preferredContactMethodId: string;

	public preferredLanguage: string;

	public accountId: string;

	public supportTeamId: string;

	public caseSubmittedBy: string;

	public taxonomyPath: string;

}
