import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { Subject } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap';

import { Confirmation } from '../../models/confirmation.model';

@Component({
   selector: 'app-publish-confirmation',
   templateUrl: './publish-confirmation.component.pug',
   styleUrls: ['./publish-confirmation.component.scss']
})
export class PublishConfirmationComponent implements OnInit {

   public formGroup: FormGroup;

   public submittedOnce: boolean;

   public onClose: Subject<Confirmation>;

   constructor(private _bsModalRef: BsModalRef, private _fb: FormBuilder) {
   }

   public ngOnInit(): void {
	  this.submittedOnce = false;

	  this.formGroup = this._fb.group({
		 comment: ['', [Validators.minLength(5)]]
	  });

	  this.onClose = new Subject();
   }

   public onConfirm(): void {
	  this.submittedOnce = true;

	  if (this.formGroup.invalid) {
		 return;
	  }

	  const comment = this.formGroup.controls['comment'].value.replace(/<[^>]*>/g, '');

	  this.onClose.next({ isCancelled: false, comment: comment });
	  this._bsModalRef.hide();
   }

   public onCancel(): void {
	  this.onClose.next({ isCancelled: true, comment: null });
	  this._bsModalRef.hide();
   }

}
