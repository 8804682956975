import { Component, Input } from '@angular/core';

import { TechnicalNoteArticle } from '../../../../../core/models/articles/article-technical-note.model';

@Component({
	selector: 'app-articles-viewer-technicalnote',
	templateUrl: './articles-viewer-technicalnote.component.pug',
	styleUrls: ['./articles-viewer-technicalnote.component.scss']
})
export class ArticlesViewerTechnicalNoteComponent {

	@Input("article")
	public article: TechnicalNoteArticle;

}
