import { Article } from "./article.model";
import { Category } from "../categories/category.model";

export class BestPracticesArticle
	extends Article {

	public type: string = "bestpractices";

	public content: string = null;

	public categoryId: number = null;

	public category: Category = null;

}
