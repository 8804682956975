import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import * as moment from 'moment';

import { PeriodService } from '../../../core/services/periods/period.service';
import { RolesService } from '../../../core/services/roles/roles.service';
import { ArticleService } from '../../../core/services/articles/article.service';

import { AllPeriodsResult } from '../../../core/models/periods/all-periods-result.model';
import { UserContribution } from '../../../core/models/articles/user-contribution.model';
import { PeriodOption } from '../../../core/models/periods/period-option.model';
import { Role } from '../../../core/models/roles/role.model';

@Component({
	selector: 'app-top-contributors',
	templateUrl: './top-contributors.component.pug',
	styleUrls: ['./top-contributors.component.scss']
})
export class TopContributorsComponent implements OnInit, OnDestroy {

	private _subscription = new Subscription();

	public contributorForm: FormGroup;

	public roles: Array<Role>;

	public allPeriods: AllPeriodsResult;

	public contributions: Array<UserContribution>;

	public lastUpdated: Date;

	constructor(
		private _periodService: PeriodService,
		private _articleService: ArticleService,
		private _fb: FormBuilder,
		private _roleService: RolesService) {

		this.contributorForm = this._fb.group({
			roleFilter: [''],
			periodFilter: [''],
		});
	}

	ngOnInit() {
		this._subscription.add(
			this._periodService.getPeriods()
				.subscribe(result => {
					this.allPeriods = result;
				})
		);

		this._subscription.add(
			this._roleService.getTeams()
				.subscribe(results => {
					this.roles = results.sort((left, right): number => { return (left.name > right.name) ? 1 : ((right.name > left.name) ? -1 : 0); });;
				})
		);

		this.getContributors(null, null);
	}

	private getContributors(roleId: string, periodFilter: PeriodOption) {
		this._subscription.add(
			this._articleService.getTopContributors(periodFilter, roleId).subscribe(topContributorsData => {
				this.contributions = topContributorsData;
				this.lastUpdated = new Date();
			})
		);
	}

	filterChange(): void {
		const formModel = this.contributorForm.value;

		let periodFilter = <PeriodOption>formModel["periodFilter"];

		let role = <string>formModel["roleFilter"];

		this.getContributors(role, periodFilter);
	}

	ngOnDestroy(): void {
		this._subscription.unsubscribe();
	}

}
