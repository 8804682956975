import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { BsModalService, ModalDirective } from 'ngx-bootstrap';

import { ProductEditorComponent } from '../editors/product-editor/product-editor.component';
import { FunctionEditorComponent } from '../editors/function-editor/function-editor.component';
import { CommonIssueEditorComponent } from '../editors/common-issue-editor/common-issue-editor.component';

import { TreeNode } from '../../models/tree-node.model';
import { GraphNode } from '../../models/graph-node.model';

import { PermissionService } from '../../../../core/services/permissions/permission.service';
import { ClassificationService } from '../../services/classification/classification.service';
import { SubmittedGraphNode } from '../../models/submitted-graph-node.model';

@Component({
	selector: 'app-classification-tree-management',
	templateUrl: './classification-tree-management.component.pug',
	styleUrls: ['./classification-tree-management.component.scss']
})
export class ClassificationTreeManagementComponent implements OnInit, OnDestroy {

	@ViewChild('tree') treeview: any;

	public selectedNode: GraphNode;

	public productEnabled: boolean = false;

	public functionEnabled: boolean = false;

	public commonIssueEnabled: boolean = false;

	public canManageClassification: boolean;

	public deletedNodes: any[] = new Array<any>();

	private _subscriptions = new Subscription();

	constructor(private _modalService: BsModalService, private _permissionService: PermissionService, private _classificationService: ClassificationService) {
	}

	ngOnInit() {
		this._subscriptions.add(
			this._permissionService.canManageClassification()
				.subscribe(response => {
					this.canManageClassification = response;
				})
		);
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

	private bindEditorEvents(editor: any): void {
		this._subscriptions.add(
			editor.nodeCreated.subscribe(node => {
				this.onNodeCreated(node);
			})
		);
		this._subscriptions.add(
			editor.nodeEdited.subscribe(node => {
				this.onNodeEdited(node);
			})
		);
	}

	private checkNodeCreationRules(nodeType: string): void {
		switch (nodeType) {
			case 'product': {
				this.productEnabled = true;
				this.functionEnabled = true;
				this.commonIssueEnabled = true;
				break;
			}

			case 'function': {
				this.productEnabled = false;
				this.functionEnabled = false;
				this.commonIssueEnabled = true;
				break;
			}

			case 'common-issue': {
				this.productEnabled = false;
				this.functionEnabled = false;
				this.commonIssueEnabled = false;
				break;
			}
		}
	}

	private getEditor(nodeType: string): any {
		switch (nodeType) {
			case 'product': {
				return ProductEditorComponent;
			}

			case 'function': {
				return FunctionEditorComponent;
			}

			case 'common-issue': {
				return CommonIssueEditorComponent;
			}
		}

		return null;
	}

	private showEditor(editor: any, initialState: any): void {
		if (editor) {
			let modalRef = this._modalService.show(editor, { initialState: initialState, class: 'modal-lg' });
			this.bindEditorEvents(modalRef.content);
		}
	}

	private onNodeCreated(graphnode: GraphNode): void {
		let selectednode = this.treeview.tree.selectedNodes[0];
		let node = new TreeNode();
		Object.assign(node, graphnode);
		node.hasChildren = (node.id != '-1'),
			node.iconCss = `cea-icon-${node.nodeType}`;
		this.treeview.tree.addNodes([node], selectednode, null);
	}

	private onNodeEdited(graphnode: GraphNode): void {
		this.treeview.tree.refresh();
	}

	public onClassificationNodeSelected(selectedNode: GraphNode): void {
		this.selectedNode = selectedNode;
		this.checkNodeCreationRules(selectedNode.nodeType);
	}

	public onNewClick(nodeType: string): void {
		if (!this.selectedNode) {
			return;
		}

		if (!nodeType) {
			return;
		}

		var editor = this.getEditor(nodeType);
		this.showEditor(editor, { parentNodeId: this.selectedNode.id });
	}

	public onEditClick(): void {
		if (!this.selectedNode) {
			return;
		}

		var editor = this.getEditor(this.selectedNode.nodeType);
		this.showEditor(editor, { nodeId: this.selectedNode.id });
	}

	public onDeleteClick(): void {
		if (!this.selectedNode) {
			return;
		}

		this._subscriptions.add(
			this._classificationService.deleteNode(parseInt(this.selectedNode.id))
				.subscribe(node => {
					var selectedNodeNodeId = this.treeview.tree.selectedNodes[0];
					this.treeview.tree.disableNodes([selectedNodeNodeId]);
					this.deletedNodes.push(selectedNodeNodeId);
				})
		);
	}

	public onUndoDeleteClick(): void {
		var nodeToAddBack = this.deletedNodes.pop();

		this._classificationService.undeleteNode(parseInt(nodeToAddBack)).subscribe(response => {
			this.treeview.tree.enableNodes([nodeToAddBack]);
		})
	}

}
