import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from "./core/components/home/home.component";
import { UserProfileComponent } from "./core/components/user-profile/user-profile.component";
import { AuthCallbackComponent } from "./core/components/auth-callback/auth-callback.component";
import { NotFoundComponent } from "./core/components/not-found/not-found.component";

import { AuthPermissionsGuard } from "./core/services/permissions/auth-permissions.guard";
import { EscalationComponent } from "./core/components/escalation/escalation.component";

const routes: Routes = [
	{
		path: '', canActivate: [AuthPermissionsGuard], children: [
			{ path: "", component: HomeComponent, pathMatch: "full" },
			{ path: "profile", component: UserProfileComponent },
			{ path: "escalations", component: EscalationComponent, pathMatch: "full"}
		],
		runGuardsAndResolvers: 'always'
	},
	{ path: 'auth-callback', component: AuthCallbackComponent },
	// runGuardsAndResolvers attribute added below to accommodate the idea of navigating to the same page to essentially refresh
	// https://medium.com/engineering-on-the-incline/reloading-current-route-on-click-angular-5-1a1bfc740ab2
	{ path: "**", component: NotFoundComponent, runGuardsAndResolvers: 'always' },
]

@NgModule({
	imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
