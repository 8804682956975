import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PartnerServiceRequest } from '../../models/psrs/psrs.model';
import { PartnerServiceRequestActivity } from '../../models/psrs/psrs-activity.model';

@Injectable({
	providedIn: 'root'
})
export class PartnerService {
	private _urlBase: string = "api/PartnerServiceRequest";

	constructor(private _httpClient: HttpClient) { }

	public getPartnerServiceRequest(id: string): Observable<PartnerServiceRequest> {
		return this._httpClient.get<PartnerServiceRequest>(`${this._urlBase}/${id}`);
	}

	public getPartnerServiceRequestActivities(id: string): Observable<Array<PartnerServiceRequestActivity>> {		
		return this._httpClient.get<Array<PartnerServiceRequestActivity>>(`${this._urlBase}/${id}/Activities`);
	}
}
