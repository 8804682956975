import { Component, Input } from '@angular/core';

import { TechnicalReferenceArticle } from '../../../../../core/models/articles/article-technical-reference.model';

@Component({
	selector: 'app-articles-viewer-technical-reference',
	templateUrl: './articles-viewer-technical-reference.component.pug',
	styleUrls: ['./articles-viewer-technical-reference.component.scss']
})
export class ArticlesViewerTechnicalReferenceComponent {

	@Input("article")
	public article: TechnicalReferenceArticle;

}
