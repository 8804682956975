import { Component, Input } from '@angular/core';

import { TroubleshootingArticle } from '../../../../../core/models/articles/article-troubleshooting.model';

@Component({
	selector: 'app-articles-viewer-troubleshooting',
	templateUrl: './articles-viewer-troubleshooting.component.pug',
	styleUrls: ['./articles-viewer-troubleshooting.component.scss']
})
export class ArticlesViewerTroubleshootingComponent {

	@Input("article")
	public article: TroubleshootingArticle;

}
