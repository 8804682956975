import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Article } from '../../../../../core/models/articles/article.model';
import { Subscription, Observable, interval } from 'rxjs';
import { ArticleService } from '../../../../../core/services/articles/article.service';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { map } from 'rxjs/internal/operators/map';

@Component({
	selector: 'app-article-version-indicator',
	templateUrl: './article-version-indicator.component.pug',
	styleUrls: ['./article-version-indicator.component.scss']
})
export class ArticleVersionIndicatorComponent implements OnInit, OnDestroy {

	@Input()
	public article: Article;

	// we can pass it as input as well since we get the latest version on the article viewer
	@Input()
	public latestVersion: number;

	private _subscriptions = new Subscription();

	constructor(private _articleService: ArticleService) { }

	ngOnInit() {
		this._subscriptions.add(
			interval(30000)
				.pipe(
					switchMap(() => this._articleService.getLatestArticleVersion(this.article.articleId)),
					map(res => {
						this.latestVersion = res;
						return res;
					})
				).subscribe()
		);
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
