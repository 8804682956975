import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { GraphNode } from '../../models/graph-node.model';
import { TroubleshootingInformation } from '../../models/troubleshooting-information.model';
import { SubmittedGraphNode } from '../../models/submitted-graph-node.model';

import { ODataResult } from '../../../../core/models/oData/odata-result.model';

@Injectable({
	providedIn: 'root'
})
export class ClassificationService {

	private _urlBase: string = 'api/classification';

	private _urlOdataBase: string = 'odata/classification';

	constructor(private _httpClient: HttpClient) {
	}

	public getNode(id: number): Observable<ODataResult<Array<GraphNode>>> {
		// ideally we should be using '/url(id)' pattern
		let url = `${this._urlOdataBase}?$filter=id eq ${id}`;

		return this._httpClient.get<ODataResult<Array<GraphNode>>>(url);
	}

	public saveNode(node: SubmittedGraphNode): Observable<GraphNode> {
		if (!node) {
			throw new Error('Node is expected when calling saveNode() method.');
		}

		let url = `${this._urlBase}/nodes`;
		if (node.id) {
			url = `${url}/${node.id}`;
		}

		return this._httpClient.post<GraphNode>(url, node);
	}

	public undeleteNode(id: number): Observable<GraphNode> {
		let url = `${this._urlBase}/nodes/${id}`;

		return this._httpClient.patch<GraphNode>(url, null);
	}

	public moveNode(node: SubmittedGraphNode): Observable<GraphNode> {
		if (!node) {
			throw new Error('Argument "node" is required.');
		}

		const url = `${this._urlBase}/nodes/${node.id}/move`;

		const patch = [
			{ op: 'replace', path: '/viewOrder', value: node.viewOrder }
		];

		return this._httpClient.patch<GraphNode>(url, patch);
	}

	public deleteNode(id: number): Observable<GraphNode> {
		let url = `${this._urlBase}/node/`;
		if (id) {
			url = `${url}${id}`;
		}

		return this._httpClient.delete<GraphNode>(url);
	}

	public getParentHierarchy(id: string): Observable<Array<GraphNode>> {
		return this._httpClient.get<Array<GraphNode>>(`${this._urlBase}/nodes/${id}/lineage`);
	}

	public getDiagnosticItems(): Observable<Array<TroubleshootingInformation>> {
		return this._httpClient.get<Array<TroubleshootingInformation>>(`${this._urlBase}/diagnostics`);
	}

	public createDiagnosticItem(item: TroubleshootingInformation): Observable<TroubleshootingInformation> {
		return this._httpClient.post<TroubleshootingInformation>(`${this._urlBase}/diagnostics`, item);
	}

	public updateDiagnosticItem(item: TroubleshootingInformation): Observable<TroubleshootingInformation> {
		return this._httpClient.post<TroubleshootingInformation>(`${this._urlBase}/diagnostics/${item.troubleshootingInformationId}`, item);
	}

	public deleteDiagnosticItem(id: number): Observable<number> {
		return this._httpClient.delete<number>(`${this._urlBase}/diagnostics/${id}`);
	}

	public getBreadcrumb(id: string): Observable<string> {
		return this._httpClient.get<string>(this._urlBase + `/breadcrumb/${id}`);
	}

	public getUnusedCommonIssues(startDate: Date, endDate: Date, minClassificationDays: number, includeInternal: boolean): any {
		throw new Error("Method not implemented.");
	}

}
