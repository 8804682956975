import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap';

import { Article } from '../../../../../../../core/models/articles/article.model';
import { ArticleService } from '../../../../../../../core/services/articles/article.service';

@Component({
	selector: 'app-approve-partner-confirmation',
	templateUrl: './approve-partner-confirmation.component.pug',
	styleUrls: ['./approve-partner-confirmation.component.scss']
})
export class ApprovePartnerConfirmationComponent implements OnInit {

	@Input()
	public article: Article;

	public submitted: boolean = false;

	@Output()
	public refreshNeeded = new EventEmitter<boolean>();

	private _subscriptions = new Subscription();

	constructor(private _bsModalRef: BsModalRef, private _articleService: ArticleService, private _router: Router) { }

	ngOnInit() {
	}

	public approvePartner(): void {
		this.submitted = true;
		if (this.article) {
			this._subscriptions.add(
				this._articleService.approvePartner(this.article.articleId, this.article.version).subscribe(response => {
					if (response && response.articleId && response.version) {
						this.refreshNeeded.emit(true);
						this._bsModalRef.hide();
					} else {
						this.submitted = false;
						// this error should not happen as it should be caught by the global error handler. But adding this just in case
						throw new Error("Article approval did not complete successfully");
					}
				})
			);
		}
	}

	public close(): void {
		this._bsModalRef.hide();
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
