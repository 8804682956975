export class MetallicTicketModel {
    tenantID: string;
    company: string;
    contact: string;
    accountId: string;
    lastName?: string;
    firstName?: string;
    phone?: string;
    email?: string;
    contactMethod: string;
    contactDetails: string;
    subscriptionType: string;
    productType: string;
    severity: string;
    briefDes: string;
    detailedDes: string;
    visibility?: string;
    businessImpact?: string;
}