import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { Article } from '../../../../../../core/models/articles/article.model';
import { PermissionService } from '../../../../../../core/services/permissions/permission.service';
import { RejectPromotionConfirmationComponent } from '../notices-and-confirmations/reject-promotion-confirmation/reject-promotion-confirmation.component';

@Component({
	selector: 'app-reject-promotion',
	templateUrl: './reject-promotion.component.pug',
	styleUrls: ['./reject-promotion.component.scss']
})
export class RejectPromotionComponent implements OnInit {

	@Input()
	public article: Article;

	@Output("refreshNeeded")
	public refreshNeededEmitter = new EventEmitter<boolean>();

	public canApprove: boolean;

	private _subscriptions = new Subscription();

	constructor(private _modalService: BsModalService, private _permissions: PermissionService) { }

	ngOnInit() {
		this._subscriptions.add(
			// Approve and Reject are a part of the same permission, make sure this user has this access
			this._permissions.canApproveArticles()
				.subscribe(response => {
					this.canApprove = response;
				})
		);
	}

	public confirmRejection(): void {
		const initialState = {
			'article': this.article
		};

		this._subscriptions.add(
			this._modalService.show(RejectPromotionConfirmationComponent, { initialState: initialState, class: 'modal-lg' })
				.content
				.refreshNeeded
				.subscribe(response => {
					this.refreshNeededEmitter.emit(true);
				})
		);
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
