import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Article } from '../../models/articles/article.model';
import { ArticleTargetedVersion } from '../../models/articles/article-targeted-version.model';
import { IncidentActivity } from '../../models/incidents/incident-activity.model';
import { IncidentDevelopmentEscalationActivity } from '../../models/incidents/incident-development-escalation-activity.model';
import { IncidentResolutionActivity } from '../../models/incidents/incident-resolution-activity.model';
import { TechnicalNoteArticle } from '../../models/articles/article-technical-note.model';

import { ApplicationVersionsService } from '../application-versions/application-versions.service';
import { ConfigurationService } from '../../../modules/runtime-configuration/services/configuration.service';
import { IncidentService } from '../incidents/incident.service';
import { TroubleshootingArticle } from '../../models/articles/article-troubleshooting.model';

@Injectable({
	providedIn: 'root'
})
export class TransformService {

	private _urlBase: string = "api/articles";

	constructor(private _httpClient: HttpClient, private _configSvc: ConfigurationService, private _incidentSvc: IncidentService, private _applicationVersionService: ApplicationVersionsService) {
	}

	private getTransformedArticleFromArticle(articleId: number, transformType: string): Observable<Article> {
		return this._httpClient.get<Article>(`${this._urlBase}/${articleId}/transform/${transformType}`)
			.pipe(
				map(
					response => {
						let article = <any>response;

						// ** A better solution would be server side but that would involve new ViewModels

						article.created = null;
						article.modified = null;

						if (article.hasOwnProperty('categoryId') && article.categoryId === 0) {
							article.categoryId = null;
						}

						return response;
					}
				)
			);
	}

	private getTransformedArticleFromIncident(incidentId: string, transformType: string): Observable<Article> {
		return forkJoin(this._incidentSvc.getIncident(incidentId).pipe(incident => { return incident }),
			this._incidentSvc.getResolution(incidentId)
				.pipe(
					catchError(err => {
						console.error(`Could not determine Resolution for '${incidentId}'`, err)
						let resolution: IncidentResolutionActivity;
						return of(resolution)
					})
				),
			this._incidentSvc.getActivities(incidentId, "(Type eq 'DevelopmentEscalationActivity')", 1, null)
				.pipe(
					catchError(err => {
						console.error(`Could not get DevelopmentEscalationActivity for '${incidentId}'`, err)
						let activities: IncidentActivity[]
						return of(activities)
					})
				),
			this._applicationVersionService.getApplicationVersions(this._configSvc.configuration.commvaultApplicationId, true, null, false).pipe(commvaultVersions => { return commvaultVersions }),
			this._applicationVersionService.getApplicationVersions(this._configSvc.configuration.hedvigApplicationId, true, null, false).pipe(hedvigVersions => { return hedvigVersions }),
			this._applicationVersionService.getApplicationVersions(this._configSvc.configuration.metallicApplicationId, true, null, false).pipe(metallicVersions => { return metallicVersions })
		).pipe(
			map(response => {
				let incident = response[0];
				let resolution = response[1];
				let devEscalation = response[2][0];
				let commvaultVersions = response[3];
				let hedvigVersions = response[4];
				let metallicVersions = response[5];

				incident.resolution = resolution;
				incident.developmentEscalation = devEscalation as IncidentDevelopmentEscalationActivity;

				switch (transformType.toLowerCase()) {
					case "technicalnote":
						// if we don't set the transformedArticle to the specific type at the start the type will NOT be determined correctly
						// in create-edit-article.component resulting in missing required fields
						let transformedArticle = new TechnicalNoteArticle();
						transformedArticle.articleTargetedVersions

						let targetedVersions = new Array<ArticleTargetedVersion>();

						if (commvaultVersions.find(x => x.major == parseInt(incident.applicationMajorVersion) && x.servicePack == incident.applicationServicePack)) {
							let targetedVersionViewModel = commvaultVersions.find(x => x.major == parseInt(incident.applicationMajorVersion) && x.servicePack == incident.applicationServicePack);
							let targetedVersion = new ArticleTargetedVersion();
							targetedVersion.versionApplicationId = targetedVersionViewModel.applicationId;
							targetedVersion.versionMajor = targetedVersionViewModel.major;
							targetedVersion.versionMinor = targetedVersionViewModel.minor;
							targetedVersion.versionBuild = targetedVersionViewModel.build;
							targetedVersion.versionRevision = targetedVersionViewModel.revision;
							targetedVersion.versionServicePack = targetedVersionViewModel.servicePack;
							targetedVersions.push(targetedVersion);
						}

						if (hedvigVersions.find(x => x.major == parseInt(incident.applicationMajorVersion) && x.servicePack == incident.applicationServicePack)) {
							let targetedVersionViewModel = hedvigVersions.find(x => x.major == parseInt(incident.applicationMajorVersion) && x.servicePack == incident.applicationServicePack);
							let targetedVersion = new ArticleTargetedVersion();
							targetedVersion.versionApplicationId = targetedVersionViewModel.applicationId;
							targetedVersion.versionMajor = targetedVersionViewModel.major;
							targetedVersion.versionMinor = targetedVersionViewModel.minor;
							targetedVersion.versionBuild = targetedVersionViewModel.build;
							targetedVersion.versionRevision = targetedVersionViewModel.revision;
							targetedVersion.versionServicePack = targetedVersionViewModel.servicePack;
							targetedVersions.push(targetedVersion);
						}

						// can't imagine this will work as intended..technically Metallic has no version..
						if (metallicVersions.find(x => x.major == parseInt(incident.applicationMajorVersion) && x.servicePack == incident.applicationServicePack)) {
							let targetedVersionViewModel = metallicVersions.find(x => x.major == parseInt(incident.applicationMajorVersion) && x.servicePack == incident.applicationServicePack);
							let targetedVersion = new ArticleTargetedVersion();
							targetedVersion.versionApplicationId = targetedVersionViewModel.applicationId;
							targetedVersion.versionMajor = targetedVersionViewModel.major;
							targetedVersion.versionMinor = targetedVersionViewModel.minor;
							targetedVersion.versionBuild = targetedVersionViewModel.build;
							targetedVersion.versionRevision = targetedVersionViewModel.revision;
							targetedVersion.versionServicePack = targetedVersionViewModel.servicePack;
							targetedVersions.push(targetedVersion);
						}

						transformedArticle.articleTargetedVersions = targetedVersions;

						transformedArticle.title = incident.developmentEscalation ? incident.developmentEscalation.escalationPurpose : incident.title;
						transformedArticle.symptoms = incident.developmentEscalation ? incident.developmentEscalation.issueSummary : incident.incidentSummary;
						transformedArticle.resolution = incident.resolution ? incident.resolution.findings : '';
						transformedArticle.incidentReference = incident.ticketNumber;
						transformedArticle.articleTypeLabel = "Technical Note";
						transformedArticle.classificationCode = incident.classificationId;
						transformedArticle.classificationText = incident.classificationText;
						transformedArticle.audience = 'Internal';

						let expirationDate = new Date();
						expirationDate.setHours(0, 0, 0, 0);
						expirationDate.setDate(expirationDate.getDate() + 365);
						transformedArticle.expiration = expirationDate;

						return transformedArticle;

					case "troubleshooting":
						let troubleshootingArticle = new TroubleshootingArticle()
						let technicalReviewerId = this._configSvc.configuration.defaultTechnicalReviewerId;
						let troubleshootingTargetedVersions = new Array<ArticleTargetedVersion>();
						let applicationId = this._configSvc.configuration.versionApplicationId;
						let major = this._configSvc.configuration.versionMajor;

						if (metallicVersions.find(x => x.applicationId == applicationId && x.major == parseInt(major))) {
							let targetedVersionViewModel = metallicVersions.find(x => x.applicationId == applicationId && x.major == parseInt(major));
							let targetedVersion = new ArticleTargetedVersion();
							targetedVersion.versionApplicationId = targetedVersionViewModel.applicationId;
							targetedVersion.versionMajor = targetedVersionViewModel.major;
							targetedVersion.versionMinor = targetedVersionViewModel.minor;
							targetedVersion.versionBuild = targetedVersionViewModel.build;
							targetedVersion.versionRevision = targetedVersionViewModel.revision;
							targetedVersion.versionServicePack = targetedVersionViewModel.servicePack;
							troubleshootingTargetedVersions.push(targetedVersion);
						}
						troubleshootingArticle.technicalReviewerId = technicalReviewerId;
						troubleshootingArticle.articleTargetedVersions = troubleshootingTargetedVersions;
						troubleshootingArticle.title = incident.resolution ? incident.resolution.caseSubject : "";
						troubleshootingArticle.description = incident.resolution ? incident.resolution.summarizeCustomerExperience : "";
						troubleshootingArticle.symptoms = incident.resolution ? incident.resolution.rootCauseOfIssue : "";
						troubleshootingArticle.resolution = incident.resolution ? incident.resolution.howToResolve : ""
						troubleshootingArticle.incidentReference = incident ? incident.ticketNumber : "";
						troubleshootingArticle.articleTypeLabel = "Troubleshooting";
						troubleshootingArticle.audience = "Customers";

						let troubleshootingExpirationDate = new Date();
						troubleshootingExpirationDate.setHours(0, 0, 0, 0);
						troubleshootingExpirationDate.setDate(troubleshootingExpirationDate.getDate() + 365);
						troubleshootingArticle.expiration = troubleshootingExpirationDate;

						return troubleshootingArticle;	
					default:
						break;
				}

			})
		)
	}

	public getArticleTransform(sourceId: string, sourceType: string, destinationType: string): Observable<Article> {
		switch (sourceType.toLowerCase()) {
			case 'incident':
				return this.getTransformedArticleFromIncident(sourceId, destinationType);
			case 'article':
				return this.getTransformedArticleFromArticle(parseInt(sourceId), destinationType);
		}
	}

}
