import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

import { Observable, of } from "rxjs";
import { catchError, map, tap, share, shareReplay } from "rxjs/operators";

import { AlertService } from "../alert/alert.service";

import { UserProfile } from "../../models/user-profiles/user-profile.model";
import { UserProfileUpdate } from "../../models/user-profiles/user-profile-update.model";

@Injectable({
	providedIn: "root"
})
export class UserProfileService {

	private _urlBase: string = "api/profiles";

	private _myProfile: UserProfile;

	private _myObservable: Observable<UserProfile>;

	constructor(private _http: HttpClient, private _alertService: AlertService) { }

	private handleError<T>(message: string, result?: T) {
		return (error: any): Observable<T> => {
			this._alertService.show(message);

			return of(result as T);
		}
	}

	public getMyProfile(): Observable<UserProfile> {
		console.debug('ProfileService | getMyProfile()');

		if (this._myProfile) {
			return of(this._myProfile);
		}
		else if (this._myObservable) {
			return this._myObservable;
		}
		else {
			const url = `${this._urlBase}/my`;

			this._myObservable = this._http
				.get<UserProfile>(url)
				.pipe(
					tap(profile => {
						this._myProfile = profile
					}),
					share(),
					catchError(this.handleError('We are sorry. We are unable to find your Profile. Please refresh your browser or contact Support.', null))
				);

			return this._myObservable;
		}
	}

	public updateMyProfile(profile: UserProfileUpdate): Observable<UserProfile> {
		console.debug('ProfileService | updateProfile()');

		if (profile == null) {
			// throw error
		}

		const url = `${this._urlBase}/my`;

		return this._http
			.post<UserProfile>(url, profile)
			.pipe(
				tap(profile => this._myProfile = profile),
				share(),
				catchError(this.handleError('updateProfile', null))
			);
	}

}
