export class SubmittedGraphNode {

	public id: number;

	public nodeType: string;

	public parentId: number;

	public title: string;

	public team: string;

	public escalationAlias: string;

	public troubleshootingIds: Array<number>;

	public tags: Array<string>;

	public viewOrder: number;

}
