import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forEach } from '@angular/router/src/utils/collection';

import { forkJoin, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Article } from '../../models/articles/article.model';
import { ArticleTargetedVersion } from '../../models/articles/article-targeted-version.model';
import { Incident } from '../../models/incidents/incident.model';
import { IncidentActivity } from '../../models/incidents/incident-activity.model';
import { IncidentDevelopmentEscalationActivity } from '../../models/incidents/incident-development-escalation-activity.model';
import { IncidentQualityVote } from '../../models/incidents/incident-quality-vote.model';
import { IncidentResolutionActivity } from '../../models/incidents/incident-resolution-activity.model';
import { FieldEscalationSubmission } from '../../models/incidents/incident-field-escalation-submission.model';
import { TechnicalNoteArticle } from '../../models/articles/article-technical-note.model';

import { ApplicationVersionsService } from '../application-versions/application-versions.service';
import { IncidentActivitiesService } from '../incident-activities/incident-activities.service';

import { AuthService } from '../auth/auth.service';
import { ConfigurationService } from '../../../modules/runtime-configuration/services/configuration.service';
import { Contact, ContactRes } from '../../models/incidents/incident-contact.model';
import { CaseSubscription } from '../../models/incidents/incident-subscription.model';
import { InternalNote } from '../../models/incidents/incident-internal-note.model';
import { IncidentApiResponse, IncidentApiResponseCategories, IncidentApiResponseCommcell } from '../../../shared/models/api-response.model';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
	})
};

@Injectable({
	providedIn: 'root'
})
export class IncidentService {

	private _urlBase: string = "api/incidents";

	private _activitiesService: IncidentActivitiesService = new IncidentActivitiesService();

	constructor(private _httpClient: HttpClient, private _configSvc: ConfigurationService, private _authService: AuthService, private _applicationVersionService: ApplicationVersionsService) { }

	public getIncident(id: string): Observable<Incident> {
		return this._httpClient.get<Incident>(this._configSvc.configuration.incidentBaseUrl + `/Incidents/${id}`);
	}

	public getResolution(id: string): Observable<IncidentResolutionActivity> {
		return this._httpClient.get<IncidentResolutionActivity>(this._configSvc.configuration.incidentBaseUrl + `/Incidents/${id}/resolution`);
	}

	public getActivities(incidentId: string, filter: string, pageSize?: number, searchTerms?: string): Observable<Array<IncidentActivity>> {
		let queryParams: Array<string> = new Array<string>();

		queryParams.push(`$filter=${[filter, 'statecode eq 1'].filter(n => n).join(' and ')}`);
		if (filter) {

			// if we are adding a filter on the API, it's possible we may miss the target expected pageSize when we apply a client-side filter
			// to achieve the proper pageSize, triple the pageSize to be requested externally
			if (pageSize) {
				pageSize = pageSize * 3;
			}
		}

		let query = '';
		if (queryParams.length != 0) {
			query = `${queryParams[0]}`;

			for (var i = 1; i < queryParams.length; i++) {
				query = `${query}&${queryParams[i]}`;
			}
		}

		let incidentApiQuery = `${this._configSvc.configuration.incidentBaseUrl}/Incidents/${incidentId}/Activities?${query}&$orderby=modifiedon desc`;
		if (pageSize) {
			incidentApiQuery = `${incidentApiQuery}&$top=${pageSize}`;
		}

		return this._httpClient.get(incidentApiQuery).pipe(
			map(activities => {
				let incidentActivities = new Array<IncidentActivity>();
				activities['value'].map(activity => {
					activity.type = activity['@odata.type'].replace('#CV.Common.Entities.Incidents.', '');
					if (searchTerms) {
						activity = this._activitiesService.formatActivity((activity as IncidentActivity), searchTerms);
					}
					incidentActivities.push(activity);
				})

				if (filter) {
					let filteredActivities = new Array<IncidentActivity>();

					if (filter.match('\'Email\'')) {
						this._activitiesService.filterEscalationEmailActivities(incidentActivities, this._configSvc.configuration.incidentEscalationFilterEmailAliases)
							.forEach(activity => {
								filteredActivities.push(activity);
							});
					}

					if (filter.match('\'TaskActivity\'')) {
						let types = new Array<string>();
						types.push('TaskActivity');

						this._activitiesService.filterActivityType(incidentActivities, types)
							.forEach(activity => {
								filteredActivities.push(activity);
							});
					}

					if (filter.match('\'DevelopmentEscalationActivity\'')) {
						let types = new Array<string>();
						types.push('DevelopmentEscalationActivity');

						this._activitiesService.filterActivityType(incidentActivities, types)
							.forEach(activity => {
								filteredActivities.push(activity);
							});
					}

					// make sure the activities are in the right order
					filteredActivities.sort((a, b) => (a.modifiedOn < b.modifiedOn) ? 1 : ((b.modifiedOn < a.modifiedOn) ? -1 : 0));

					if (pageSize) {
						// we need to reduce the result size to a maximum of the original pageSize value
						return filteredActivities.slice(0, pageSize / 3);
					}
					else {
						return filteredActivities;
					}
				}

				return incidentActivities;
			}
			)
		);

	}

	public getIncidentQualityVote(incidentId: string): Observable<IncidentQualityVote> {
		return this._httpClient.get<IncidentQualityVote>(this._urlBase + `/${incidentId}/votes`);
	}

	public getIncidentQualityVoteScore(incidentId: string): Observable<number> {
		return this._httpClient.get<number>(this._urlBase + `/${incidentId}/votes/count`);
	}

	public voteAsync(incidentId: string, vote: number): Observable<IncidentQualityVote> {
		return this._httpClient
			.post<IncidentQualityVote>(this._urlBase + `/${incidentId}/votes`, vote, httpOptions);
	}

	public isLastActivityFieldEscalation(incidentId: string): Observable<boolean> {
		return this.getActivities(incidentId, null, 1, null).pipe(
			map(activities => {
				if (activities[0] && activities[0].type == 'FieldEscalationActivity') {
					return true;
				}
				else {
					return false;
				}
			})
		);
	}

	public escalateIncident(incidentId: string, emailCC: string[], escalationDescription: string): Observable<void> {
		let fieldEscalationSubmission = new FieldEscalationSubmission();
		fieldEscalationSubmission.emailAddress = this._authService.user.profile.email;
		fieldEscalationSubmission.userFullName = this._authService.user.profile.name;
		fieldEscalationSubmission.emailCC = emailCC;
		fieldEscalationSubmission.escalationDescription = escalationDescription;

		return this._httpClient
			.post<void>(this._configSvc.configuration.incidentBaseUrl + `/Incidents/${incidentId}/escalate`, JSON.stringify(fieldEscalationSubmission), httpOptions);
	}

	public reindexIncident(incidentId: string): Observable<void> {
		return this._httpClient.post<void>(this._configSvc.configuration.incidentBaseUrl + `/Incidents/${incidentId}/Reindex`, null, httpOptions);
	}

	public async getCommcell(searchParam: string): Promise<IncidentApiResponseCommcell> {
		return await this._httpClient.get<IncidentApiResponseCommcell>(this._configSvc.configuration.incidentBaseUrl + `/Commcell/${searchParam}`).toPromise();
	}

	public async getCategories(searchParam: string): Promise<IncidentApiResponseCategories> {
		return await this._httpClient.get<IncidentApiResponseCategories>(this._configSvc.configuration.incidentBaseUrl + `/Category/${searchParam}/ChildCategories`).toPromise();
	}

	public getMatchingContacts(accountId: string, searchString: string): Observable<IncidentApiResponse> {
		let paramaters = {
			"searchString": searchString + ""
		};
		return this._httpClient.get<IncidentApiResponse>(this._configSvc.configuration.incidentBaseUrl + `/Account/${accountId}/GetContacts`, { params: paramaters });
	}

	public async getSecondaryContacts(email: string): Promise<IncidentApiResponse> {
		return await this._httpClient.get<IncidentApiResponse>(this._configSvc.configuration.incidentBaseUrl + `/Contact/${email}`).toPromise();
	}

	public async createContact(contact: Contact): Promise<IncidentApiResponse> {
		return await this._httpClient.post<IncidentApiResponse>(this._configSvc.configuration.incidentBaseUrl + `/Contact/Create`, contact, httpOptions).toPromise();
	}

	public async createTicket(incident: Incident): Promise<IncidentApiResponse> {
		return await this._httpClient.post<IncidentApiResponse>(this._configSvc.configuration.incidentBaseUrl + `/Incidents/Create`, incident, httpOptions).toPromise();
	}

	public createInternalNote(incidentId: string, request: InternalNote): Observable<void> {
		return this._httpClient.post<void>(this._configSvc.configuration.incidentBaseUrl + `/Incidents/${incidentId}/Note`, request, httpOptions);
	}

	public getCaseSubscriptions(incidentId: string): Observable<any> {
		return this._httpClient.get<any>(this._configSvc.configuration.incidentBaseUrl + `/Incidents/${incidentId}/Subscriptions`);
	}

	public subscribeToCase(incidentId: string, caseSubscription: CaseSubscription): Observable<void> {
		return this._httpClient.post<void>(this._configSvc.configuration.incidentBaseUrl + `/Incidents/${incidentId}/Subscribe`, caseSubscription, httpOptions);
	}

	public unsubscribeToCase(incidentId: string, caseSubscription: CaseSubscription): Observable<void> {
		return this._httpClient.post<void>(this._configSvc.configuration.incidentBaseUrl + `/Incidents/${incidentId}/Unsubscribe`, caseSubscription, httpOptions);
	}

	public getAccountSubscriptions(incidentId: string): Observable<any> {
		return this._httpClient.get<any>(this._configSvc.configuration.incidentBaseUrl + `/Account/${incidentId}/Subscriptions`);
	}

	public subscribeToAccount(accountId: string, caseSubscription: CaseSubscription): Observable<void> {
		return this._httpClient.post<void>(this._configSvc.configuration.incidentBaseUrl + `/Account/${accountId}/Subscribe`, caseSubscription, httpOptions);
	}

	public unsubscribeToAccount(accountId: string, caseSubscription: CaseSubscription): Observable<void> {
		return this._httpClient.post<void>(this._configSvc.configuration.incidentBaseUrl + `/Account/${accountId}/Unsubscribe`, caseSubscription, httpOptions);
	}

	public getCCUsers(searchString: string): Observable<Array<Contact>> {
		return this._httpClient.get<Array<Contact>>(this._configSvc.configuration.incidentBaseUrl + `/Contact/${searchString}/SystemUsers`);
	}
}
