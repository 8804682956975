import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { retryWhen, concatMap, delay } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

	constructor() {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request)
			.pipe(
				retryWhen(errors => errors
					.pipe(
						concatMap((error, count) => {
							// `count` starts at 0
							// `status` values were chosen based on what `HandleTransientHttpError()` does in **Polly**
							if (count < 1 && (error.status >= 500 || error.status === 408)) {
								return of(error.status);
							}

							return throwError(error);
						}),
						delay(500)
					)
				)
			);
	}

}
