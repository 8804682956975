import { Component, Input } from '@angular/core';
import { Article } from '../../../../../core/models/articles/article.model';
import { BsModalRef } from 'ngx-bootstrap';
import { ArticleMetaData } from '../../../../../core/models/articles/article-metadata.model';

@Component({
	selector: 'app-articles-viewer-modal-preview',
	templateUrl: './articles-viewer-modal-preview.component.pug',
	styleUrls: ['./articles-viewer-modal-preview.component.scss']
})
export class ArticlesViewerModalPreviewComponent {

	@Input()
	public article: Article;

	@Input()
	public isPreview: boolean = false;

	@Input()
	public metadata: ArticleMetaData;

	constructor(private _bsModalRef: BsModalRef) { }

	public close(): void {
		this._bsModalRef.hide();
	}

}
