import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PeriodOption } from '../../models/periods/period-option.model';
import { AllPeriodsResult } from '../../models/periods/all-periods-result.model';

@Injectable({
  providedIn: 'root'
})
export class PeriodService {

	private _urlBase: string = "api/period";

	constructor(private _httpClient: HttpClient) { }

	public getPeriods(): Observable<AllPeriodsResult> {
		return this._httpClient.get<AllPeriodsResult>(this._urlBase + "/all").pipe(
			map(data => {
				return data;
			})
		);
	}

}
