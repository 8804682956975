import { Component, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Incident } from '../../../core/models/incidents/incident.model';
import { IncidentService } from '../../../core/services/incidents/incident.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { IncidentEscalationConfirmationComponent } from '../../../modules/content-viewer/components/incidents/incident-escalation-confirmation/incident-escalation-confirmation.component';
import { IncidentActivity } from '../../../core/models/incidents/incident-activity.model';

@Component({
	selector: 'app-quick-escalation',
	templateUrl: './quick-escalation.component.pug',
	styleUrls: ['./quick-escalation.component.scss']
})
export class QuickEscalationComponent {

	private _modalRef: BsModalRef;

	private incident: Incident;

	public incidentId: string;

	public escalateForm: FormGroup;

	public submitted: boolean = false;

	public isActiveState: boolean = false;

	public isFieldEscalated: boolean = false;

	public lastActivity: IncidentActivity;

	constructor(private fb: FormBuilder, private _incidentService: IncidentService, private _modalService: BsModalService) {
		this.createForm();
	}

	private createForm(): void {
		this.escalateForm = this.fb.group({
			escalateIncident: ['', Validators.required]
		});
	}

	private async isLastActivityFieldEscalation(incidentId): Promise<boolean> {
		if (!incidentId) {
			return;
		}

		return await this._incidentService.isLastActivityFieldEscalation(incidentId).toPromise();
	}

 	public onSubmit(): void {
		this.incidentId = this.escalateForm.controls['escalateIncident'].value;

		if (!this.incidentId) {
			return;
		}

		this._incidentService.getIncident(this.incidentId).subscribe(
			async response => {
				this.submitted = true;
				this.incident = response;

				if (!this.incident) {
					return;
				}

				this.isActiveState = this.incident.state.toLowerCase() == 'active' ? true : false;

				if (!this.isActiveState) {
					return;
				}

				// make sure the last activity was not a field escalation
				this.isFieldEscalated = await this.isLastActivityFieldEscalation(this.incident.id);

				if (this.isFieldEscalated) {
					return;
				}

				const initialState = {
					id: this.incidentId,
					description: this.incident.description,
					title: this.incident.title,
					customerName: this.incident.contactName,
					canEscalate: !this.isFieldEscalated && this.isActiveState,
					status: this.incident.status
				};

				this._modalRef = this._modalService.show(IncidentEscalationConfirmationComponent, { initialState, class: 'modal-lg' });
			},
			error => {
				// this will display the error message undernath the input
				this.submitted = true;
				this.isActiveState = false;
			}
		);
	}

	public clearIncident(): void {
		this.escalateForm.reset({
			incidentId: null
		});

		this.submitted = false;
		this.isFieldEscalated = false;
		this.isActiveState = false;

		this.escalateForm.markAsPristine();
	}

}
