import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { FilterSettingsModel, SortSettingsModel, ToolbarService, ExcelExportService, ToolbarItems, GridComponent, Column, ColumnModel, ExcelExportProperties } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { Internationalization } from '@syncfusion/ej2-base';

import { ReportsService } from '../../services/reports.service';

import { ReportDefinition } from '../../models/report-definition.model';
import { ReportParameter } from '../../models/report-parameter.model';

@Component({
	selector: 'app-render',
	templateUrl: './render.component.pug',
	styleUrls: ['./render.component.scss'],
	providers: [ToolbarService, ExcelExportService]
})
export class RenderComponent implements OnInit, OnDestroy {
	public reportId: string;

	public definition: ReportDefinition;

	public visibleParameters: Array<ReportParameter>;

	public showCalendar: boolean = false;

	public maxDate: Date = new Date();

	public report: Array<any>;

	public filterOptions: FilterSettingsModel = {
		type: 'Menu'
	};

	public sortOptions: SortSettingsModel = {
		columns: [
			// need to come up with a way to set initial sort order specific to the incoming data
			//{ field: 'score', direction: 'Ascending' }
		]
	};

	public toolbarOptions: ToolbarItems[];

	public pageSettings = { pageSize: 25, pageSizes: ["All", 10, 15, 25, 50, 100] };

	@ViewChild('grid')
	public grid: GridComponent;

	private _subscriptions = new Subscription();

	constructor(private _route: ActivatedRoute, private _reportsService: ReportsService) { }

	ngOnInit() {
		this._subscriptions.add(
			this._route.params.subscribe(params => {
				this.reportId = params['id'];

				this._subscriptions.add(
					this._reportsService.getReport(this.reportId).subscribe(response => {
						this.definition = response;

						let canRenderReport = this.extractReportParametersFromRoute();

						if (canRenderReport) {
							this.renderReport();
						}
					})
				);
			})
		);

		this.toolbarOptions = ['ExcelExport'];
	}

	public renderReport(): void {
		this._subscriptions.add(
			this._reportsService.renderReport(this.reportId, this.definition.parameters)
				.subscribe(reportRenderResponse => {
					this.report = reportRenderResponse;
				})
		);
	}

	public onToolbarClick(args: ClickEventArgs): void {
		if (args.item.id.endsWith('_excelexport')) {
			let exportProperties: ExcelExportProperties = {
				fileName: `${this.definition.exportFilename}.xlsx`
			};

			this.grid.excelExport(exportProperties);
		}
	}

	public onGridLoad(args) {
		var atag = args.cell.querySelector('a');

		if (!!atag) {
			var href = atag.href;
			// look for DateTime values listed in anchor tag references and replace with a shortened date only parameter
			// NOTE: If there is a report that utilizes a specific TIME value this functionality will break that usage - revisit at a later date
			var matches = href.match(/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\-|\+)\d{2}:\d{2}(-\d{2}:\d{2})?((-|\+)\d{2}:\d{2})?)/g) as Array<string>;
			if (!!matches) {
				for (let match of matches) {
					var strippedDateValue = match.substr(0, match.lastIndexOf(':') - 3);
					var strippedDate = new Date(strippedDateValue);
					var formattedValue = new Internationalization().formatDate(strippedDate, { skeleton: 'yMd', type: 'date' });
					href = href.replace(match, formattedValue);
				}
				atag.href = href;
			}
		}
	}

	// Loads the definition paramters with the values from the query string. If all values are not set, we will show the fields so the user can enter the values
	// that need to be entered. Returns true or false depending on whether all values were loaded or not
	private extractReportParametersFromRoute(): boolean {
		if (!this.definition.parameters) {
			return true;
		}

		this.visibleParameters = this.definition.parameters.filter(x => !x.hidden);

		for (let key of this.definition.parameters) {
			let value = this._route.snapshot.queryParams[key.name];

			if (value) {
				switch (key.type) {
					case 'DateTime':
						key.value = new Date(value);
						break;
					// These types may need changing in the future when actual numeric parameters are used
					case 'Int32':
					case 'Double':
						key.value = Number(value);
						break;
					default:
						key.value = value;
						break;
				}
			}
			else {
				if (!key.hidden) {
					return false;
				}
			}
		}

		return true;
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
