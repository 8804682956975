import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { ArticleService } from '../../../../../../../core/services/articles/article.service';
import { Article } from '../../../../../../../core/models/articles/article.model';

@Component({
	selector: 'app-reject-publish-confirmation',
	templateUrl: './reject-publish-confirmation.component.pug',
	styleUrls: ['./reject-publish-confirmation.component.scss']
})
export class RejectPublishConfirmationComponent implements OnInit, OnDestroy {

	@Input()
	public article: Article;

	public formGroup: FormGroup;

	public submitted: boolean = false;

	@Output()
	public refreshNeeded = new EventEmitter<boolean>();

	private _subscriptions = new Subscription();

	constructor(private _fb: FormBuilder, private _bsModalRef: BsModalRef, private _articleService: ArticleService, private _router: Router) { }

	ngOnInit() {
		this.formGroup = this._fb.group({
			denialReason: ['', [Validators.required, Validators.minLength(5)]]
		});
	}

	public close(): void {
		this._bsModalRef.hide();
	}

	public submit(): void {
		if (this.formGroup.valid) {
			this.submitted = true;
			this._subscriptions.add(
				this._articleService.denyPublish(this.article.articleId, this.article.version, this.formGroup.value["denialReason"])
					.subscribe(response => {
						if (response && response.articleId && response.version) {
							this.refreshNeeded.emit(true);
							this._bsModalRef.hide();
						} else {
							// this error should not happen as it should be caught by the global error handler. But adding this just in case
							this.submitted = false;
							throw new Error("Article approval did not complete successfully");
						}
					})
			);
		}
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
