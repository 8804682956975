import { Injectable } from '@angular/core';

import { AppInsights } from 'applicationinsights-js';

import { ConfigurationService } from '../../../modules/runtime-configuration/services/configuration.service';

@Injectable({
	providedIn: 'root'
})
export class LoggingService{

	private props: any;

	private config: Microsoft.ApplicationInsights.IConfig;

	constructor(private _configSvc: ConfigurationService) {
		const settings = this._configSvc.configuration;

		this.props = {
			'CvApp': settings.logging.appName,
			'CvEnvironment': settings.logging.environment,
			'CvMachineName': 'Browser',
		};

		this.config = {
			instrumentationKey: settings.logging.instrumentationKey
		}

		if (!AppInsights.config) {
			AppInsights.downloadAndSetup(this.config);
		};

		//The following code is recommended for initialization:
		//https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#addtelemetryinitializer
		(<any>window).appInsights = AppInsights;

		// Add telemetry initializer
		AppInsights.queue.push(function () {
			AppInsights.context.addTelemetryInitializer(function (envelope) {
				var telemetryItem = envelope.data.baseData;

				telemetryItem.properties = telemetryItem.properties || {};
				telemetryItem.properties["CvApp"] = settings.logging.appName;
				telemetryItem.properties["CvEnvironment"] = settings.logging.environment;
				telemetryItem.properties["CvMachineName"] = 'Browser';							
			});
		});
	}

	logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number) {
		if (properties !== undefined && properties !== null) {
			this.props = properties;
		}
		AppInsights.trackPageView(name, url, this.props, measurements, duration);
	}

	logEvent(name: string, properties?: any, measurements?: any) {
		if (properties !== undefined && properties !== null) {
			this.props = properties;
		}
		AppInsights.trackEvent(name, this.props, measurements);
	}

	logError(exception: Error, handledAt?: string) {
		AppInsights.trackException(exception, handledAt, this.props);
	}

}
