import { ArticleType } from "../../../shared/enums/article-type.enum";

export class ArticleDefinition {

	id: string;

	name: string;

	description: string;

	articleType: ArticleType;

	audiences: Array<string>;

	articleModelType: string;

}
