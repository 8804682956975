import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

@Component({
	selector: 'app-incident-search-result',
	templateUrl: './search.result.incident.component.pug',
	styleUrls: ['./search.result.incident.component.scss']
})

export class IncidentSearchResultComponent implements OnInit{

	@Input()
	public result: any; // update this to proper model?

	@Input()
	public searchTerms: string;

	public resolutionLabel: string = "Resolution";

	ngOnInit(): void {
		if(this.result && this.result.resolutionFindings) {
			let metallicCase = this.result.serviceType && this.result.serviceType.match(/Metallic/i);
			this.resolutionLabel = metallicCase && metallicCase.length > 0 ? "Final Update" : "Resolution";
		}
	}
}
