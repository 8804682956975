import { Component, OnInit, OnDestroy, } from '@angular/core';

import { UserProfileService } from "../../services/user-profiles/user-profile.service";
import { AuthService } from '../../services/auth/auth.service';

import { UserProfile } from "../../models/user-profiles/user-profile.model";
import { PermissionService } from '../../services/permissions/permission.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArticleService } from '../../services/articles/article.service';
import { MessageService } from '../../services/messages/message.service'
import { forEach } from '@angular/router/src/utils/collection';

@Component({
	selector: 'app-nav-menu',
	templateUrl: './nav-menu.component.pug',
	styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit, OnDestroy {

	public isCollapsed = true;

	public profile: UserProfile;

	public viewReports: boolean = false;

	public viewAdminReports: boolean = false;

	public manageArticles: boolean = false;

	public manageClassification: boolean = false;

	public adminTools: boolean = false;

	public showTeamPendingApproval: boolean = false;

	public announcementMessages: string[] = new Array<string>();

	private _subscriptions = new Subscription();

	constructor(private _profileService: UserProfileService, private _authService: AuthService, private _permissionService: PermissionService, private _articleService: ArticleService, private _messageService: MessageService) {
	}

	ngOnInit(): void {
		this._subscriptions.add(
			this._authService.isAuthenticated()
				.subscribe((result) => {
					if (result) {
						this._profileService
							.getMyProfile()
							.subscribe(
								profile => {
									this.profile = profile;
									this.checkPermissions();
								}
							);
						this._messageService.getMessages().subscribe(response => {
							for (var i = 0; i < response.length; i++) {
								this.announcementMessages.push(response[i]);
							}
						})
					}
				})
		);
	}

	public logOut(): void {
		this._authService.logout();
	}

	private checkPermissions(): void {
		this._subscriptions.add(
			this._permissionService.hasPermission(["compose-internal-articles", "compose-public-articles", "compose-private-articles"])
				.subscribe(response => {
					this.manageArticles = response;
				})
		);

		this._subscriptions.add(
			this._permissionService.hasPermission(["view-reports"])
				.subscribe(response => {
					this.viewReports = response;
				})
		);

		this._subscriptions.add(
			this._permissionService.hasPermission(["view-solution-admin-reports"])
				.subscribe(response => {
					this.viewAdminReports = response;
				})
		);

		this._subscriptions.add(
			this._permissionService.hasPermission(["manage-classification"])
				.subscribe(response => {
					this.manageClassification = response;
				})
		);

		this._subscriptions.add(
			this._permissionService.hasPermission(["manage-application-admin-tools"])
				.subscribe(response => {
					this.adminTools = response;
				})
		);

		this._subscriptions.add(
			this._articleService.getViews()
				.subscribe(response => {
					this.showTeamPendingApproval = !!response.filter(x => x.key == "teampendingapproval").length;
				})
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

}
