import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';
import { DataManager, ODataV4Adaptor } from '@syncfusion/ej2-data';

import { CategoryService } from '../../../../../core/services/categories/category.service';
import { AuthService } from '../../../../../core/services/auth/auth.service';

@Component({
	selector: 'app-category-selector',
	templateUrl: './category-selector.component.pug',
	styleUrls: ['./category-selector.component.scss']
})
export class CategorySelectorComponent implements OnInit {

	@Input("group")
	public formGroup: FormGroup;

	public categoryFields: Object = { text: 'name', value: 'id' };

	public data: DataManager;

	constructor(private _authService: AuthService) { }

	ngOnInit() {
		// We cannot use our own service since it _seems_ that SyncFusion clears the
		// currently selected item when [dataSource] is bound to static array. So instead,
		// we use their DataManager to our own oData endpoint.
		this.data = new DataManager({
			url: '/odata/category',
			adaptor: new ODataV4Adaptor(),
			headers: [{ 'Authorization': this._authService.getAuthorizationHeaderValue() }]
		});

		this.updateForm();
	}

	private updateForm(): void {
		if (this.formGroup) {
			this.formGroup.addControl('categoryId', new FormControl(null, Validators.required));
		}
	}

}
