import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ResetUserPublishDate } from '../../models/admin/reset-user-publish-date.model';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
	})
};

@Injectable({
	providedIn: 'root'
})
export class AdminService {

	private _urlBase: string = "api/admin";

	constructor(private _http: HttpClient) {
	}

	public resetUsersPublishDate(request: ResetUserPublishDate): Observable<void> {
		return this._http
			.patch<void>(`${this._urlBase}/resetpublisheddate`, request, httpOptions);
	}

}
