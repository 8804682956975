import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { SearchComponent } from "./components/search/search.component";
import { AuthPermissionsGuard } from "../../core/services/permissions/auth-permissions.guard";
import { CanDeactivateGuard } from "../../core/services/can-deactivate/can-deactivate.guard";

const routes: Routes = [
	{ path: "search", component: SearchComponent, canActivate: [AuthPermissionsGuard], canDeactivate: [CanDeactivateGuard] },
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class SearchRoutingModule { }
