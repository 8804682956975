import { Injectable, PACKAGE_ROOT_URL } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, ReplaySubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Role } from '../../models/roles/role.model';
import { ODataResult } from '../../models/oData/odata-result.model';

@Injectable({
	providedIn: 'root'
})
export class RolesService {

	private _urlBase: string = "api/roles";

	private _odataUrlBase: string = '/odata/role';

	private _doRolesFetch: boolean = true;

	private _rolesStream = new ReplaySubject<Array<Role>>(1);

	private _myRolesStream = new ReplaySubject<Array<Role>>(1);

	constructor(private _http: HttpClient) {
		this.fetchMyRoles();
	}

	private fetchRoles(): void {
		const url = `${this._urlBase}`;

		console.debug(`RolesService | fetchRoles() | Fetching '${url}'...`);
		this._http
			.get<Array<Role>>(url)
			.subscribe(perms => {
				this._rolesStream.next(perms);
			});
	}

	private fetchMyRoles(): void {
		const url = `${this._urlBase}/my`;

		console.debug(`RolesService | fetchMyRoles() | Fetching '${url}'...`);
		this._http
			.get<Array<Role>>(url)
			.subscribe(perms => {
				this._myRolesStream.next(perms);
			});
	}

	public getRoles(): Observable<Array<Role>> {
		console.debug('RolesService | getRoles()');

		// this is stupid...I cannot seem to find a way to *check* if the stream has a value
		// therefore I am stuck with using this flag
		if (this._doRolesFetch) {
			this._doRolesFetch = false;
			this.fetchRoles();
		}

		return this._rolesStream.asObservable();
	}

	public getTeams(): Observable<Array<Role>> {
		console.debug('RolesService | getTeams()');

		const url = `${this._odataUrlBase}?$select=id,name&$filter=isApplicationRole eq false`;

		return this._http
			.get <ODataResult<Array<Role>>>(url)
			.pipe(
				map(result => result.value)
			);
	}

	public getMyRoles(): Observable<Array<Role>> {
		console.debug('RolesService | getMyRoles()');

		return this._myRolesStream.asObservable();
	}

}
