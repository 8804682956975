import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { Subscription } from 'rxjs';

import { PartnerService } from '../../../../../core/services/psrs/psrs.service';
import { AlertService } from '../../../../../core/services/alert/alert.service';

import { PartnerServiceRequestActivity } from '../../../../../core/models/psrs/psrs-activity.model';

@Component({
	selector: 'app-psr-activities-viewer',
	templateUrl: './psr-activities-viewer.component.pug',
	styleUrls: ['./psr-activities-viewer.component.scss']
})
export class PsrActivitiesViewerComponent implements OnInit, OnDestroy {

	@Input()
	public id: string;

	@Input()
	public searchTerms: string;

	public showFriendlyTimestamps: boolean = true;

	public activityFilterForm: FormGroup;

	public activities: Array<PartnerServiceRequestActivity>;

	public hasRemainingActivities: boolean = true;

	// Used to indicate when the entity does not exist OR the user does not have access
	public wasNotFound: boolean;

	private _subscriptions = new Subscription();

	private _currentPageSize: number = 15;

	private readonly _defaultPageSize: number = 15;

	constructor(private _psrsService: PartnerService, private _formBuilder: FormBuilder, private _alertService: AlertService) {
		this.createForm();
	}

	ngOnInit() {
		this.updateActivityData();
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

	private createForm() {
		this.activityFilterForm = this._formBuilder.group({
			activityFilter: ''
		});

		this._subscriptions.add(
			this.activityFilterForm.controls['activityFilter'].valueChanges.subscribe(() => {
				this._currentPageSize = this._defaultPageSize;
				this.hasRemainingActivities = true;
				this.updateActivityData();
			})
		);
	}

	private updateActivityData(): void {
		this._subscriptions.add(
			this._psrsService.getPartnerServiceRequestActivities(this.id)
				.subscribe(
					response => {
						this.wasNotFound = false;

						this.activities = response;
					},
					err => {
						// Hate the idea of having to add code to **conditionally** handle this...specifically show an error if **not** a 401 or 404. Ideally,
						// we would only get here if we needed to handle the 401 or 404...
						if (err instanceof HttpErrorResponse && (err.status == 401 || err.status == 404)) {
							this.wasNotFound = true;
						}
						else {
							this._alertService.show('An unexpected error seems to have occurred. Please try the action again or refresh your page. If the problem continues, you can contact the <a href="mailto:BATS-Alexandria@commvault.com">BATS-Alexandria</a> team for assistance.');
						}
					}
				)
		);
	}

	public timeToggle() {
		this.showFriendlyTimestamps = !this.showFriendlyTimestamps;
	}

	public expandAllActivities(e: any) {
		this.activities.forEach(activity => activity.isDisplayOpen = true);
	}

	public collapseAllActivities(e: any) {
		this.activities.forEach(activity => activity.isDisplayOpen = false);
	}

	public retrieveAllActivities(e: any) {
		this._currentPageSize = null; //do this to say we want to get everything
		this.hasRemainingActivities = false;
		this.updateActivityData();
	}

	public openChange(activity: PartnerServiceRequestActivity, e: any) {
		if (activity.isDisplayOpen != e) {
			activity.isDisplayOpen = e;
		}
	}

}
