import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'incident-share',
	templateUrl: './incident-share.component.pug',
	styleUrls: ['./incident-share.component.scss']
})
export class IncidentShareComponent implements OnInit {

	@Input()
	public id: string;

	constructor() { }

	ngOnInit() {
	}

}
