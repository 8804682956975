import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

import { Observable, of, ReplaySubject } from "rxjs";

import { Permission } from "../../models/permissions/permission.model";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class PermissionService {

	private _urlBase: string = "api/permissions";

	private _doPermissionsFetch: boolean = true;

	private _doMyPermissionsFetch: boolean = true;

	private _permissionsStream = new ReplaySubject<Array<Permission>>(1);

	private _myPermissionsStream = new ReplaySubject<Array<Permission>>(1);

	constructor(private _http: HttpClient) {
	}

	private fetchPermissions(): void {
		const url = `${this._urlBase}`;

		console.debug(`PermissionService | fetchPermissions() | Fetching '${url}'...`);
		this._http
			.get<Array<Permission>>(url)
			.subscribe(perms => {
				this._permissionsStream.next(perms);
			});
	}

	private fetchMyPermissions(): void {
		const url = `${this._urlBase}/my`;

		console.debug(`PermissionService | fetchMyPermissions() | Fetching '${url}'...`);
		this._http
			.get<Array<Permission>>(url)
			.subscribe(perms => {
				this._myPermissionsStream.next(perms);
			});
	}

	public getPermissions(): Observable<Array<Permission>> {
		console.debug('PermissionService | getPermissions()');

		// this is stupid...I cannot seem to find a way to *check* if the stream has a value
		// therefore I am stuck with using this flag
		if (this._doPermissionsFetch) {
			this._doPermissionsFetch = false;
			this.fetchPermissions();
		}

		return this._permissionsStream.asObservable();
	}

	public getMyPermissions(): Observable<Array<Permission>> {
		//console.debug('PermissionService | getMyPermissions()');

		if (this._doMyPermissionsFetch) {
			this.fetchMyPermissions();
			this._doMyPermissionsFetch = false;
		}

		return this._myPermissionsStream.asObservable();
	}

	public hasPermission(permissionName: Array<string>): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			if (!permissionName) {
				observer.next(false);
				observer.complete();
				return;
			}

			this.getMyPermissions()
				.subscribe(perms => {
					let permissions = perms.filter(x => {
						return permissionName.indexOf(x.name.toLowerCase()) > -1;
					});

					console.debug(`PermissionService | hasPermission() | permission : '${permissionName}' -- granted : '${!!permissions.length}'`);

					observer.next(!!permissions.length);
					observer.complete();
				});
		});
	}

	public canReadPrivateArticles(): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			this.getMyPermissions().subscribe(permission => {
				let perm = !!permission
					.filter(x => x.name == "view-internal-articles" || x.name == "view-private-articles" || x.name == "compose-internal-articles" || x.name == "compose-private-articles")
					.length;
				observer.next(perm);
				observer.complete();
			});
		});
	}

	public canApproveArticles(): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			this.getMyPermissions().subscribe(permission => {
				let perm = !!permission
					.filter(x => x.name == "approve-public-articles")
					.length;
				observer.next(perm);
				observer.complete();
			});
		});
	}

	public canReviewArticles(): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			this.getMyPermissions().subscribe(permission => {
				let perm = !!permission
					.filter(x => x.name == "review-public-articles")
					.length;
				observer.next(perm);
				observer.complete();
			});
		});
	}

	public canEditorialReviewArticles(): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			this.getMyPermissions().subscribe(permission => {
				let perm = !!permission
					.filter(x => x.name == "editorial-review-public-articles")
					.length;
				observer.next(perm);
				observer.complete();
			});
		});
	}

	public canManageClassification(): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			this.getMyPermissions().subscribe(permission => {
				let perm = !!permission
					.filter(x => x.name == "manage-classification")
					.length;
				observer.next(perm);
				observer.complete();
			});
		});
	}

}
