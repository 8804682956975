import { ArticleAuthor } from "./article-author.model";
import { ArticleType } from "../../../shared/enums/article-type.enum";

export class LatestArticles {
		
	public author: ArticleAuthor;

	public articleId: number;

	public version: number;

	public title: string;

	public articleType: number;

	public modified: Date;

	public userName: string;

	public articleTypeLabel(): string {
		return ArticleType[this.articleType] ? ArticleType[this.articleType] : "";
	}

	public getLink() {
		return `/article/details/${this.articleId}/${this.version}`;
	}

}
