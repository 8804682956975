import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { RolesService } from '../../../../../core/services/roles/roles.service';

import { TechnicalNoteArticle } from '../../../../../core/models/articles/article-technical-note.model';
import { Role } from '../../../../../core/models/roles/role.model';

@Component({
	selector: 'app-targeted-roles',
	templateUrl: './targeted-roles.component.pug',
	styleUrls: ['./targeted-roles.component.scss']
})
export class TargetedRolesComponent implements OnInit, OnDestroy {

	readonly rolesTargetControlName = 'targetedRoles';
	
	@Input("group")
	public formGroup: FormGroup;

	@Input("article")
	public article: TechnicalNoteArticle;

	public roles: Array<Role>;

	public checkboxGroup: FormGroup;

	private _subscriptions = new Subscription();

	constructor(private _rolesService: RolesService) { }

	ngOnInit() {
		this.getRoles();
	}

	private getRoles(): void {
		this._subscriptions.add(
			this._rolesService.getTeams()
				.subscribe(results => {
					this.roles = results.sort((left, right): number => { return (left.name > right.name) ? 1 : ((right.name > left.name) ? -1 : 0); });

					this.createForm();
				})
		);
	}

	private createForm(): void {
		if (this.formGroup) {
			// form elements that are bound to external group
			this.formGroup.addControl(this.rolesTargetControlName, new FormArray([], Validators.required));

			// create an internal FormGroup for binding to the checkboxes
			const controls = this.roles.map(r => new FormControl(this.article && this.article.targetedRoles && this.article.targetedRoles.some(tr => tr.roleId == r.id)));

			this.checkboxGroup = new FormGroup({
				items: new FormArray(controls)
			});

			this._subscriptions.add(
				this.checkboxGroup.controls['items'].valueChanges
					.subscribe(val => {
						this.syncSelectedRoles(val);
					})
			);

			this.syncSelectedRoles(this.checkboxGroup.controls.items.value);
		}
	}

	private syncSelectedRoles(val: Array<any>): void {
		let externalControls = <FormArray>this.formGroup.controls[this.rolesTargetControlName];

		// clear existing and add anything that is checked
		// clearing this way maintains any subscriptions to the FormArray that may exist
		while (externalControls.length !== 0) {
			externalControls.removeAt(0);
		}

		for (let i = 0; i < val.length; i++) {
			if (val[i]) {
				externalControls.push(
					new FormGroup({
						roleId: new FormControl(this.roles[i].id)
					})
				);
			}
		}
	}

	// needed because of a bug reported back in Angular version 5 that does not cast
	public getControls(key: string): any {
		return (<FormArray>this.checkboxGroup.controls[key]).controls;
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

}
