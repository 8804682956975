import { Component, OnInit } from '@angular/core';

import { RolesService } from '../../../../core/services/roles/roles.service';
import { PermissionService } from '../../../../core/services/permissions/permission.service';
import { Role } from '../../../../core/models/roles/role.model';
import { Permission } from '../../../../core/models/permissions/permission.model';

@Component({
	selector: 'app-roles-permissions',
	templateUrl: './roles-permissions.component.pug',
	styleUrls: ['./roles-permissions.component.scss']
})
export class RolesPermissionsComponent implements OnInit {

	public roles: Array<Role>;

	public permissions: Array<Permission>;

	constructor(private _rolesService: RolesService, private _permsService: PermissionService) {
	}

	ngOnInit() {
		this._rolesService.getRoles()
			.subscribe(result => {
				this.roles = result.sort((left, right): number => { return (left.name > right.name) ? 1 : ((right.name > left.name) ? -1 : 0); });;;

				this.roles.forEach(role => {
					role.permissions = role.permissions.sort((left, right): number => { return (left.name > right.name) ? 1 : ((right.name > left.name) ? -1 : 0); });;;
				});
			});

		this._permsService.getPermissions()
			.subscribe(result => {
				this.permissions = result.sort((left, right): number => { return (left.name > right.name) ? 1 : ((right.name > left.name) ? -1 : 0); });;;
			});
	}

}
