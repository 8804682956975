import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap';

import { Article } from '../../../../../../core/models/articles/article.model';
import { PermissionService } from '../../../../../../core/services/permissions/permission.service';
import { ApprovePartnerConfirmationComponent } from '../notices-and-confirmations/approve-partner-confirmation/approve-partner-confirmation.component';
import { RejectPartnerConfirmationComponent } from '../notices-and-confirmations/reject-partner-confirmation/reject-partner-confirmation.component';

@Component({
  selector: 'app-partner-approval',
  templateUrl: './partner-approval.component.pug',
  styleUrls: ['./partner-approval.component.scss']
})
export class PartnerApprovalComponent implements OnInit {

	@Input()
	public article: Article;

	@Output("refreshNeeded")
	public refreshNeededEmitter = new EventEmitter<boolean>();

	public canApprove: boolean;

	private _subscriptions = new Subscription();

	constructor(private _modalService: BsModalService, private _permissions: PermissionService) { }

	ngOnInit() {
		this._subscriptions.add(
			this._permissions.canApproveArticles()
				.subscribe(response => {
					this.canApprove = response;
				})
		);
	}

	public confirmApprovePartner(): void {
		const initialState = {
			'article': this.article
		};

		this._subscriptions.add(
			this._modalService.show(ApprovePartnerConfirmationComponent, { initialState: initialState, class: 'modal-lg' })
				.content
				.refreshNeeded
				.subscribe(response => {
					this.refreshNeededEmitter.emit(true);
				})
		);
	}

	public confirmRejectPartner(): void {
		const initialState = {
			'article': this.article
		};

		this._subscriptions.add(
			this._modalService.show(RejectPartnerConfirmationComponent, { initialState: initialState, class: 'modal-lg' })
				.content
				.refreshNeeded
				.subscribe(response => {
					this.refreshNeededEmitter.emit(true);
				})
		);
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
