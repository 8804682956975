import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { WorkflowState } from '../../models/workflow/workflow-state.model';
import { Article } from '../../models/articles/article.model';

@Injectable({
   providedIn: 'root'
})
export class ArticleWorkflowService {

   private _urlBase: string = "odata/article";

   constructor(private _httpClient: HttpClient) { }

   public getValidTransitions(articleId: number, version: number): Observable<Array<WorkflowState>> {
	  return this._httpClient.get<Array<WorkflowState>>(`${this._urlBase}(${articleId},${version})/workflow`);
   }

   public setWorkflowTransition(articleId: number, version: number, action: string, feedback: string): Observable<Article> {
	  return this._httpClient.post<Article>(`${this._urlBase}(${articleId},${version})/workflow`, { action: action, feedback: feedback });
   }

}
