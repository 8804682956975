import { ArticleFixedVersion } from "../articles/article-fixed-version.model";
import { ApplicationVersionViewModel } from "./application-version-view-model.model";

export class Version
	extends ArticleFixedVersion {

	public id: string;

	public versionLabel: string;

	public static mapFromApplicationVersionViewModelArray(response: Array<ApplicationVersionViewModel>): Array<Version> {
		let versionArr = new Array<Version>();

		if (response && response.map) {
			versionArr = response.map(x => {
				let newVersion = new Version();
				newVersion.id = `${x.applicationId}-${x.major}-${x.minor}-${x.build}-${x.revision}-${x.servicePack}`;
				newVersion.versionApplicationId = x.applicationId;
				newVersion.versionMajor = x.major;
				newVersion.versionMinor = x.minor;
				newVersion.versionRevision = x.revision;
				newVersion.versionServicePack = x.servicePack;
				newVersion.versionBuild = x.build;
				newVersion.versionIsReleased = x.isReleased;

				return newVersion;
			});
		}

		return versionArr;
	} 

}
