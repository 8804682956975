import { ArticleContributor } from "./article-contributor.model";
import { ArticleDeflectedIncident } from "./article-deflected-incident.model";
import { ArticleTargetedVersion } from "./article-targeted-version.model";
import { ArticleLogPhrase } from "./article-log-phrase.model";
import { ArticleStateChange } from "./article-state-change.model";
import { ArticleAuthor } from "./article-author.model";

export class Article {

	public articleId: number;

	public author: ArticleAuthor;

	public version: number;

	public title: string;

	public classificationCode: string;

	public classificationText: string;

	public incidentReference: string = null;

	public created: Date;

	public authorId: string;

	public modified: Date;

	public editorId: string;

	public editor: ArticleAuthor;

	public technicalReviewerId: string = null;

	public technicalReviewer: ArticleAuthor;

	public deletionId: string;

	public deletedOn: Date;

	public deletedById: string;

	public unpublished: Date;

	public unpublisherId: string;

	public isMinorRevision: boolean;

	public externalReferenceId: string;

	public workflowState: string;

	public proposedCommonIssueTitle: string;

	public articleTypeLabel: string;

	public isMajorVersion: string;

	public articleContributors: Array<ArticleContributor> = null;

	public articleDeflectedIncidents: Array<ArticleDeflectedIncident>;

	public articleTargetedVersions: Array<ArticleTargetedVersion> = null;

	public articleLogPhrases: Array<ArticleLogPhrase>;

	public articleStateChanges: Array<ArticleStateChange>;

	public sourceArticleId: number;

	public sourceArticleVersion: number;

	public audience: string;

	public expiration: Date;

	public isManualPublish: boolean;

	public articleType: string;

	public discriminator: string;

	public affectedApplications: string;

}
