export enum ArticleType {

	"Technical Note" = 1,

	"Knowledge Base",

	"Solution",

	"Troubleshooting",

	"Frequently Asked Question",

	"Best Practices",

	"Technical Reference"

}
