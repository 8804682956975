import { Component } from '@angular/core';
import { Input } from '@angular/core';

@Component({
	selector: 'app-psr-search-result',
	templateUrl: './search.result.psr.component.pug',
	styleUrls: ['./search.result.psr.component.scss']
})

export class PartnerServiceRequestSearchResultComponent {

	@Input()
	public result: any;

}
