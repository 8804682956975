import { Pipe, PipeTransform } from '@angular/core';

import { MarkedOptions } from 'marked';

import * as marked from 'marked';

@Pipe({
	name: 'markdown'
})
export class MarkdownPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		if (value) {
			let renderer = new marked.Renderer();

			renderer.table = function (header, body) {
				return '<table class="table table-bordered">' + header + body + '</table>';
			};

			renderer.codespan = function (code) {
				const htmlDecode = innerHTML => Object.assign(document.createElement('textarea'), { innerHTML }).value;

				code = htmlDecode(code);
				return "<code>" + code + "</code>";
			};

			let markedOptions: MarkedOptions = {
				renderer: renderer,
				langPrefix: 'hljs ',
				breaks: true,
				highlight: function (code) {
					// apply highlighting

					// this LOOKS like the left and right sides are the same but it is not
					// for some reason in my testing, if it was solely <$1>$2<$3> it would just display the escaped HTML.. Adding the span display none fixes that issue
					// i am NOT sure why that occurrs.. however, this is a hack that will get this working and have little (if any) negative impact
					value = code.replace(/<(span class=['"]hl hl-\d['"])>(.*)<(\/span)>/g, "<$1>$2<$3><span class='d-none'></span>");

					return value;
				}
			};

			marked.setOptions(markedOptions);

			return marked(value);
		}

		return null;
	}

}
