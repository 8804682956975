import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { CreateMetallicTicketComponent } from '../create-metallic-ticket/create-metallic-ticket.component';
import { ConfigurationService } from '../../../modules/runtime-configuration/services/configuration.service';

@Component({
  selector: 'app-internal-case',
  templateUrl: './internal-case.component.pug',
  styleUrls: ['./internal-case.component.scss']
})
export class InternalCaseComponent implements OnInit {

	private _modalRef: BsModalRef;

	constructor(private _modalService: BsModalService, private _configSvc: ConfigurationService) { }

  ngOnInit() {
  }

	public createMetallic(): void {
		window.open(this._configSvc.configuration.caseCreationUrl.concat("/New-SaaS-Case/"));
	}

	public createSoftwareSupportCase(): void {
		window.open(this._configSvc.configuration.caseCreationUrl.concat("/New-Software-Case/"));
	}
}
