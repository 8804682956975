import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

import { Subscription } from 'rxjs';

import { FilterSettingsModel, PageSettingsModel, SortSettingsModel, IFilter } from '@syncfusion/ej2-angular-grids';
import { DataManager, Query, ODataV4Adaptor } from '@syncfusion/ej2-data';

import { ArticleService } from '../../../../core/services/articles/article.service';
import { AuthService } from '../../../../core/services/auth/auth.service';

import { ArticleDefinition } from '../../../../core/models/articles/article-definition.model';
import { Views } from '../../../../core/models/views/views.model';

@Component({
	selector: 'app-manage-articles',
	templateUrl: './manage-articles.component.pug',
	styleUrls: ['./manage-articles.component.scss']
})
export class ManageArticlesComponent implements OnInit, OnDestroy {

	private _pageSizes: number[] = [15, 30, 50, 100];

	private _subscriptions = new Subscription();

	public viewOptions: Array<Views>;

	public definitions: Array<ArticleDefinition>;

	public manageArticleResults: DataManager;

	public filterOptions: FilterSettingsModel;

	public discriminatorFilter: IFilter;

	public workflowStateFilter: IFilter;

	public affectedApplicationsFilter: IFilter;

	public pageOptions: PageSettingsModel;

	public sortOptions: SortSettingsModel;

	public activeView: string;

	public query: Query;

	constructor(private _articleService: ArticleService, private _authService: AuthService, private _route: ActivatedRoute, private _router: Router) { }

	@ViewChild('articlesGrid') articlesGrid;

	ngOnInit() {
		// ** Go get data we need for view/form options

		this._articleService.getViews().subscribe(response => {
			this.viewOptions = response;
		});

		this._articleService.getArticleTypes().subscribe(response => {
			this.definitions = response;
		});

		// ** Set up the grid for filtering/sorting

		this.pageOptions = { pageCount: 15, pageSize: this._pageSizes[0], pageSizes: this._pageSizes };

		this.filterOptions = {
			type: 'Menu'
		};

		// Hopefully this will no longer be needed once OData groupby() is fixed.
		this.discriminatorFilter = {
			type: 'CheckBox'
		};

		this.workflowStateFilter = {
			type: 'CheckBox'
		};

		this.affectedApplicationsFilter = {
			type: 'CheckBox'
		};

		this.sortOptions = { columns: [{ field: 'modified', direction: 'Descending' }] };

		this.query = new Query().expand('author').select(['discriminator', 'affectedApplications', 'articleId', 'version', 'versionLabel', 'workflowState', 'title', 'classificationText', 'author.userName', 'modified']);

		// ** React to view changes and update data

		this._subscriptions.add(
			this._route.paramMap.subscribe((map: ParamMap) => {
				this.activeView = map.get('view') || 'mydrafts';

				this.applyViewToResult();
			})
		);
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

	private applyViewToResult(): void {
		// Not really results being bound but the DataManager
		this.manageArticleResults = this.getDataManagerArticles(this.activeView);
	}

	private applyViewToRouter(): void {
		// This check is needed because we can initially hit the component two different ways
		//     /article/management
		//     /article/management/some_view_name

		if (this._route.snapshot.paramMap.get('view')) {
			this._router.navigate(['..', this.activeView], { relativeTo: this._route });
		}
		else {
			this._router.navigate([this.activeView], { relativeTo: this._route });
		}
	}

	private getDataManagerArticles(articleView: string): DataManager {
		return new DataManager({
			url: `odata/article/view(view='${articleView}')`,
			adaptor: new ODataV4Adaptor,
			crossDomain: true,
			headers: [{ 'Authorization': this._authService.getAuthorizationHeaderValue() }],
		});
	}

	public onViewChange(value: string): void {
		this.activeView = value;

		this.applyViewToRouter();
	}

	public onRefresh(): void {
		this.articlesGrid.refresh();
	}

	public onBeginGridAction(e) {
		switch (e.requestType) {
			case 'filterbeforeopen':
				switch (e.columnName) {
					case 'workflowState':
						e.filterModel.options.dataSource = [
							{ workflowState: "ApprovalDenied" },
							{ workflowState: "Approved" },
							{ workflowState: "Draft" },
							{ workflowState: "EditorialReview" },
							{ workflowState: "PartnerAccessible" },
							{ workflowState: "PartnerAccessibleDenied" },
							{ workflowState: "PendingApproval" },
							{ workflowState: "PendingPartnerAccessible" },
							{ workflowState: "ReviewDenied" },
							{ workflowState: "Reviewed" },
							{ workflowState: "TechnicalReview" },
							{ workflowState: "TechnoteDraft" },
							{ workflowState: "TechnotePendingPromotion" },
							{ workflowState: "TechnotePendingPromotion_PartnerAccessible" },
							{ workflowState: "TechnotePromoted" },
							{ workflowState: "TechnotePromoted_PartnerAccessible" },
							{ workflowState: "TechnotePromoted_PendingPartnerAccessible" },
							{ workflowState: "TechnotePromotionDenied" },
							{ workflowState: "Unpublished" }
						];
						e.filterModel.options.filteredColumns =
							e.filterModel.options.filteredColumns.filter(function (col) {
								if (col.field == 'workflowState') {
									return true;
								}
								return false;
							});
						break;
					case 'discriminator':
						e.filterModel.options.dataSource = [
							{ discriminator: "BestPracticesArticle" },
							{ discriminator: "FrequentlyAskedQuestionArticle" },
							{ discriminator: "SolutionArticle" },
							{ discriminator: "TechnicalNoteArticle" },
							{ discriminator: "TechnicalReferenceArticle" },
							{ discriminator: "TroubleshootingArticle" }
						];
						e.filterModel.options.filteredColumns =
							e.filterModel.options.filteredColumns.filter(function (col) {
								if (col.field == 'discriminator') {
									return true;
								}
								return false;
							});
						break;
					case 'affectedApplications':
						e.filterModel.options.dataSource = [
							{ affectedApplications: "Commvault" },
							{ affectedApplications: "Metallic" },
							{ affectedApplications: "Hedvig" }
						];
						e.filterModel.options.filteredColumns =
							e.filterModel.options.filteredColumns.filter(function (col) {
								if (col.field == 'affectedApplications') {
									return true;
								}
								return false;
							});
						break;
				}
				break;
			case 'filtering':
				e.columns.forEach(col => {
					switch (col.field) {
						case 'affectedApplications':
							col.operator = col.operator !== "notequal" ? "contains" : col.operator;
						case 'discriminator':
						case 'workflowState':
							col.matchCase = true;
							break;
					}
				})
				break;
		}
	}
}
