import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Comment } from '../../models/comments/comment.model';
import { CommentViewModel } from '../../models/comments/comment-view.model';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
	})
};

@Injectable({
	providedIn: 'root'
})
export class CommentService {

	private _urlBase: string = "api/comments";

	private _subscription: string;

	constructor(private _httpClient: HttpClient) { }
	
	public getComments(threadId: string): Observable<Array<Comment>> {
		return this._httpClient.get<Array<Comment>>(this._urlBase + `/${threadId}`);			
	}

	public addComment(newCommentModel: CommentViewModel): Observable<Comment> {
		if (!newCommentModel.parentId) newCommentModel.parentId = null;
		
		return this._httpClient
			.post<Comment>(this._urlBase, newCommentModel, httpOptions);
			
	}
	
	public updateComment(newCommentModel: CommentViewModel): Observable<Comment> {
		if (!newCommentModel.parentId) newCommentModel.parentId = null;

		return this._httpClient
			.put<Comment>(this._urlBase, newCommentModel, httpOptions);

	}

}
