import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { PartnerService } from '../../../../../core/services/psrs/psrs.service';
import { AlertService } from '../../../../../core/services/alert/alert.service';

import { PartnerServiceRequest } from '../../../../../core/models/psrs/psrs.model';

@Component({
	selector: 'app-psr-viewer',
	templateUrl: './psr-viewer.component.pug',
	styleUrls: ['./psr-viewer.component.scss']
})
export class PsrViewerComponent implements OnInit {
	private _subscription = new Subscription();

	public request: PartnerServiceRequest;

	@Input()
	public id: string;

	@Input()
	public searchTerms: string;

	// Used to indicate when the entity does not exist OR the user does not have access
	public wasNotFound: boolean;

	constructor(private _route: ActivatedRoute, private _partnerService: PartnerService, private _alertService: AlertService) {
	}

	ngOnInit() {
		if (this.id) {
			this.getPartnerServiceRequestData();
		}
		else {
			this._subscription.add(
				this._route.params.subscribe(params => {
					this.id = (params['id'] ? params['id'] : null);
					this.getPartnerServiceRequestData();
				})
			);
		}
	}

	private getPartnerServiceRequestData(): void {
		if (!this.id) {
			return;
		}

		this._subscription.add(
			this._partnerService.getPartnerServiceRequest(this.id)
				.subscribe(
					response => {
						this.wasNotFound = false;

						this.request = response;

						if (this.request) {
							this.request.description = (this.request.description ? this.request.description.trim() : null);
						}
					},
					err => {
						// Hate the idea of having to add code to **conditionally** handle this...specifically show an error if **not** a 401 or 404. Ideally,
						// we would only get here if we needed to handle the 401 or 404...
						if (err instanceof HttpErrorResponse && (err.status == 401 || err.status == 404)) {
							this.wasNotFound = true;
						}
						else {
							this._alertService.show('An unexpected error seems to have occurred. Please try the action again or refresh your page. If the problem continues, you can contact the <a href="mailto:BATS-Alexandria@commvault.com">BATS-Alexandria</a> team for assistance.');
						}
					}
				)
		);
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

}
