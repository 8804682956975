import { Injectable } from '@angular/core';

import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Injectable({
	providedIn: 'root'
})
export class SpinnerService {

	private _activeCounter: number = 0;

	public loadingText: string = "Please wait...";

	constructor(private _realSpinnerService: Ng4LoadingSpinnerService) {
	}

	public show(loadingText: string = null): void {
		// stops ExpressionChangedAfterItHasBeenCheckedError being thrown
		setTimeout(() => {
			this.loadingText = loadingText;
		});

		this._activeCounter++;
		this._realSpinnerService.show();
	}

	public hide(): void {
		this._activeCounter--;

		if (this._activeCounter == 0) {
			this._realSpinnerService.hide();
		}
	}

}
