import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators, AbstractControl } from '@angular/forms';

//import { AbstractControl } from '@angular/forms';
//import { Validators } from '@angular/forms';
import { CheckboxItem } from '../../../../../shared/models/checkbox-item.model';
import { ArticleTargetedVersion } from '../../../../../core/models/articles/article-targeted-version.model';
import { ApplicationVersionsService } from '../../../../../core/services/application-versions/application-versions.service';
import { ApplicationVersionViewModel } from '../../../../../core/models/applications/application-version-view-model.model';
import { Article } from '../../../../../core/models/articles/article.model';
import { Subscription } from 'rxjs';
import { ParentVersion } from '../../../../../core/models/applications/parent-version.model';
import { Version } from '../../../../../core/models/applications/version';

@Component({
	selector: 'app-affected-versions',
	templateUrl: './affected-versions.component.pug',
	styleUrls: ['./affected-versions.component.scss']
})
export class AffectedVersionsComponent implements OnInit, OnDestroy {

	@Input("group")
	public formGroup: FormGroup;

	@Input()
	public majorOnly: boolean = false;

	@Input()
	public applicationId: string;

	@Input()
	public article: Article;

	public ready: boolean = false;

	public ver = new Array<ParentVersion>();

	public flattendVersionsList = new Array<Version>();

	private _subscriptions = new Subscription();

	constructor(private _fb: FormBuilder, private _applicationVersionsService: ApplicationVersionsService) {
	}

	ngOnInit() {
		this.createForm();
		this.loadMajorMinorVersion(this.applicationId)
	}

	private createForm(): void {
		var affectedVersionsControl = this.formGroup.get("affectedVersions") as FormGroup;
		if (!affectedVersionsControl) {
			this.formGroup.addControl("affectedVersions", this._fb.group([]));
			// used to simplify the validation. A bit of a hack, but it works given the time
			this.formGroup.addControl("affectedVersionValidator", new FormControl(false, Validators.requiredTrue));
		}
	}

	private initializeForm(): void {
		this.ver.forEach(version => {
			version.minorVersions.forEach(minor => {
				let fg = this.formGroup.get('affectedVersions') as FormGroup;

				let initialValue = false;

				// get data from article if application
				if (this.article && this.article.articleTargetedVersions && this.article.articleTargetedVersions.filter) {
					initialValue = !!this.article.articleTargetedVersions
						.filter(x => `${x.versionApplicationId}-${x.versionMajor}-${x.versionMinor}-${x.versionBuild}-${x.versionRevision}-${x.versionServicePack}` == minor.id)
						.length;

					if (initialValue) {
						this.formGroup.patchValue({ "affectedVersionValidator": true });
					}
				}

				let ctrl = new FormControl(initialValue);
				fg.addControl(minor.id, ctrl);
			});
		});

		this.affectedVersionOnChange();
	}

	private loadMajorMinorVersion(applicationId: string): void {
		this._subscriptions.add(
			this._applicationVersionsService.getApplicationVersions(applicationId, true, null, this.majorOnly)
				.subscribe(response => {
					let mappedResponse = Version.mapFromApplicationVersionViewModelArray(response);
					this.flattendVersionsList = this.flattendVersionsList.concat(mappedResponse);
					// get distinct major versions and create the flattened structure so it's easier for us to filter
					let majorVersions = new Array<number>();

					mappedResponse.forEach(appVersionVM => {
						if (majorVersions.indexOf(appVersionVM.versionMajor) == -1) {
							majorVersions.push(appVersionVM.versionMajor);
						}
					});

					// create the hierarchial structure so it's easier for us to loop through on the UI
					majorVersions.forEach(mv => {
						let major = new ParentVersion();
						major.id = `${mv}-*`;
						major.articleId = this.article.articleId;
						major.versionLabel = String(mv);
						major.versionApplicationId = applicationId;
						major.versionMajor = mv;
						major.versionMinor = 0;
						major.versionBuild = 0;
						major.versionRevision = 0;
						major.versionServicePack = "*";
						major.versionIsReleased = false;
						let resp = this.flattendVersionsList.filter(x => x.versionMajor == mv);

						major.minorVersions = resp.map(x => {
							let targetedVersion = new ArticleTargetedVersion();
							targetedVersion.versionMajor = x.versionMajor;
							targetedVersion.versionMinor = x.versionMinor;
							targetedVersion.versionBuild = x.versionBuild;
							targetedVersion.versionRevision = x.versionRevision;
							targetedVersion.versionServicePack = x.versionServicePack;

							x.versionLabel = ArticleTargetedVersion.versionLabel(targetedVersion);

							if (!x.versionIsReleased) {
								x.versionLabel = `${x.versionLabel}*`;
							}

							return x;
						});

						this.ver.push(major);
					});

					this.initializeForm();
				})
		);
	}

	private affectedVersionOnChange(): void {
		this._subscriptions.add(
			// this method maps and clears out the articleTargetedVersions so we don't have to worry about mapping this in the parent component
			this.formGroup.get("affectedVersions").valueChanges
				.subscribe(keyValue => {
					if (!Object.values(keyValue).filter(x => x === true).length) {
						// check to see if we have anything selected, if we don't set the validator to false
						this.formGroup.patchValue({ "affectedVersionValidator": false });
					}

					this.flattendVersionsList.forEach((fv) => {
						if (keyValue[fv.id] === undefined) {
						}
						else {
							// we want to explicitly know if this is the TRUE value. not if it is TRUTHY
							if (keyValue[fv.id] === true) {
								this.formGroup.patchValue({ "affectedVersionValidator": true });

								// if articleTargetedVersions is not here and not an array, then we want to create the array
								if (!this.article || !this.article.articleTargetedVersions || !this.article.articleTargetedVersions.filter) {
									this.article.articleTargetedVersions = new Array<ArticleTargetedVersion>();
								}

								let targetedVersionInArticle = this.article.articleTargetedVersions
									.filter(x => `${x.versionApplicationId}-${x.versionMajor}-${x.versionMinor}-${x.versionBuild}-${x.versionRevision}-${x.versionServicePack}` == fv.id);

								// if targeted version is in the list, and the checkbos is true, alright then.. we're good
								if (!targetedVersionInArticle.length) {
									let newArticleTargetedVersion = new ArticleTargetedVersion();
									Object.assign(newArticleTargetedVersion, fv);
									this.article.articleTargetedVersions.push(newArticleTargetedVersion);
								}
							} else {
								if (this.article && this.article.articleTargetedVersions && this.article.articleTargetedVersions.filter) {
									// if the value is false (I.E. the checknox unchecked) remove the version from the list if it is in it
									this.article.articleTargetedVersions = this.article.articleTargetedVersions
										.filter(x => `${x.versionApplicationId}-${x.versionMajor}-${x.versionMinor}-${x.versionBuild}-${x.versionRevision}-${x.versionServicePack}` != fv.id);
								}
							}
						}
					});
				}));
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	// needed because of a bug reported back in Angular version 5 that does not cast
	public getControls(key: string): any {
		return (<FormArray>this.formGroup.controls[key]).controls;
	}

}
