import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: 'yesno'
})
export class YesNoPipe implements PipeTransform {

	transform(value: boolean, lowercase: boolean = false): string {
		let output = value == true ? "Yes" : "No";

		return (lowercase == false ? output : output.toLowerCase());
	}

}
