import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { ReportsService } from '../../services/reports.service';
import { ReportInformation } from '../../models/report-information.model';

@Component({
	selector: 'app-reports-gallery',
	templateUrl: './reports-gallery.component.pug',
	styleUrls: ['./reports-gallery.component.scss']
})
export class ReportsGalleryComponent implements OnInit, OnDestroy {

	private _subscriptions = new Subscription();

	public availableReports = new Array<ReportInformation>();

	constructor(private _reportsService: ReportsService) { }

	ngOnInit() {
		this._subscriptions.add(
			this._reportsService.getReports().subscribe(response => {
				this.availableReports = (response ? response.sort((left, right): number => { return (left.name > right.name) ? 1 : ((right.name > left.name) ? -1 : 0); }) : null);
			})
		);
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
