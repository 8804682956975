import { SearchRequestFacet } from "./search-request-facet.model";
import { SearchSortCriteria } from "./search-sort-criteria.model";
import { KeyValue } from "./key-value.model";

export class SearchRequest {

	public searchTerms: string = null;

	public explicitSearchTerms: Array<KeyValue> = new Array<KeyValue>();

	public escapeSearchTerms: boolean = true;

	public facets: Array<SearchRequestFacet> = new Array<SearchRequestFacet>();

	public pageIndex: number = 1;

	public maxPaginationSize: number = 10;

	public pageSize: number = 0;

	public sortOrder: Array<SearchSortCriteria> = new Array<SearchSortCriteria>();

	public normalizeScores: boolean = false;

	public includeFilteredFacets: boolean = false;

}
