import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common'
import { Subscription } from 'rxjs';
import { CommentService } from '../../../../core/services/comments/comment.service';
import { UserProfileService } from '../../../../core/services/user-profiles/user-profile.service';
import { Comment } from '../../../../core/models/comments/comment.model';
import { CommentViewModel } from '../../../../core/models/comments/comment-view.model';

@Component({
	selector: 'app-comment',
	templateUrl: './comment.component.html',
	styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit, OnDestroy {

	@Input("comment")
	public comment: Comment;

	@Input("parent")
	public parentComment: Comment;

	@Input()
	public threadId: string;

	@Input()
	public threadTitle: string;

	@Input()
	public referenceId: string;

	@Input()
	public referenceVersion: string;

	public threadUrl: string;

	public replying: boolean;

	public editing: boolean;

	public commentPlaceholder: string;

	public userId: string;

	private _subscription = new Subscription();

	public commentForm = new FormGroup({
		commentInput: new FormControl('', Validators.required)
	});

	constructor(private _profileService: UserProfileService,
		private _commentService: CommentService,
		private _location: Location) { }

	ngOnInit() {
		this.getCurrentUser();
		this.threadUrl = window.location.origin + this._location.path();
	}

	private commentReply(): void {
		this.replying = true;
		this.editing = false;
		this.commentForm.setValue({ commentInput: "" });
		this.commentPlaceholder = "Please enter reply here ....";
	}

	private commentEdit(): void {
		this.editing = true;
		this.replying = false;
		this.commentForm.setValue({ commentInput: this.comment.content });
	}

	private submit(): void {
		if (this.editing) {
			this.editing = false;
			this.submitEdit();
		}
		else {
			this.replying = false;
			this.submitReply();
		}
	}

	private cancelCommenting(event: any) {
		this.replying = false;
		this.editing = false;
	}

	private submitReply(): void {
		var newCommentModel: CommentViewModel = {
			id: 0,
			userId: this.userId,
			isAnonymous: false,
			content: this.commentForm.value.commentInput,
			threadId: this.comment.thread.id == null ? this.parentComment.thread.id : this.comment.thread.id,
			parentId: this.comment.id == null ? this.parentComment.id : this.comment.id,
			userName: "",
			userEmail: "",
			url: this.threadUrl,
			title: this.comment.thread.title,
			referenceId: this.referenceId,
			referenceVersion: this.referenceVersion
		};
		
		this._subscription.add(
			this._commentService.addComment(newCommentModel)
				.subscribe(response => {

					if (this.parentComment) {
						this.parentComment.replies.push(response);
					}
					else {
						if (this.comment.replies) {
							this.comment.replies.push(response);
						}
						else {
							this.comment.replies = Array<Comment>();
							this.comment.replies[0] = response;

						}
					}
				})
		);
	}

	private submitEdit(): void {
		var newCommentModel: CommentViewModel = {
			id: this.comment.id,
			userId: this.userId,
			isAnonymous: false,
			content: this.commentForm.value.commentInput,
			threadId: this.comment.thread.id,
			parentId: null,
			userName: "",
			userEmail: "",
			url: this.threadUrl,
			title: this.comment.thread.title,
			referenceId: this.referenceId,
			referenceVersion: this.referenceVersion
		};
		
		this._subscription.add(
			this._commentService.updateComment(newCommentModel)
				.subscribe(response => {
					this.comment.content = response.content;
					this.comment.lastUpdated = response.lastUpdated;
				})
		);
	}

	private getCurrentUser() {
		this._subscription.add(
			this._profileService
				.getMyProfile()
				.subscribe(
					profile => {
						this.userId = profile.id;
					})
		);
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

}
