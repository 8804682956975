import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-switch',
	templateUrl: './switch.component.pug',
	styleUrls: ['./switch.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SwitchComponent),
			multi: true
		}
	]
})
export class SwitchComponent implements ControlValueAccessor {

	@Input()
	public label = 'switch';

	@Input('value')
	private _value = false;

	private onChange: any = () => { };

	private onTouched: any = () => { };

	constructor() {
	}

	public get value() {
		return this._value;
	}

	public set value(val) {
		this._value = val;
		this.onChange(val);
		this.onTouched();
	}

	public registerOnChange(fn) {
		this.onChange = fn;
	}

	public registerOnTouched(fn) {
		this.onTouched = fn;
	}

	public writeValue(value) {
		if (value) {
			this.value = value;
		}
	}

	public switch() {
		this.value = !this.value;
	}

}
