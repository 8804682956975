import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { DataManager, ODataV4Adaptor, Query } from '@syncfusion/ej2-data';

import { AuthService } from '../../../../../core/services/auth/auth.service';
import { Article } from '../../../../../core/models/articles/article.model';
import { Subscription } from 'rxjs';

@Component({
   selector: 'app-collaborators',
   templateUrl: './collaborators.component.pug',
   styleUrls: ['./collaborators.component.scss']
})
export class CollaboratorsComponent implements OnInit, OnDestroy {

   @Input("group")
   public formGroup: FormGroup;

   @Input("article")
   public article: Article;

   public remoteFields = { text: 'userName', value: 'id' };

   public data: DataManager;

   public query: Query;

   private _subscriptions = new Subscription();

   constructor(private _fb: FormBuilder, private _authService: AuthService) {
   }

   ngOnInit() {
	  this.bindData();
	  this.createForm();
   }

   private createForm(): void {
	  if (this.formGroup) {
		 // selected items appear as Array of 'id' values
		 this.formGroup.addControl('collaborators', this._fb.control(''));

		 this._subscriptions.add(
			this.formGroup.get("collaborators").valueChanges
			   .subscribe(value => {
				  if (value && value.map) {
					 this.article.articleContributors = value
						.map(collaborator => {
						   return { "userId": collaborator };
						});
				  }
			   })
		 );
	  }

	  if (this.article && this.article.articleContributors && this.article.articleContributors.map) {
		 this.article.articleContributors = this.article.articleContributors.filter(x => x.contributorType == 'Collaborator' || x.contributorType == '2');
		 this.formGroup.patchValue({
			'collaborators': this.article.articleContributors.map(x => x.userId)
		 });
	  }
   }

   private bindData(): void {
	  this.data = new DataManager({
		 url: '/odata/User',
		 adaptor: new ODataV4Adaptor,
		 crossDomain: false,
		 headers: [{ 'Authorization': this._authService.getAuthorizationHeaderValue() }]
	  });

	  this.query = new Query().select(['id', 'userName']);
   }

   ngOnDestroy(): void {
	  this._subscriptions.unsubscribe();
   }

}
