import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { Article } from '../../../../../../core/models/articles/article.model';
import { ArticleHistory } from '../../../../../../core/models/articles/article-history.model';
import { ArticleService } from '../../../../../../core/services/articles/article.service';
import { ArticleFriendlyVersionPipe } from '../../../../../../shared/pipes/article-friendly.pipe';
import { Router } from '@angular/router';

@Component({
	selector: 'app-article-history',
	templateUrl: './article-history.component.pug',
	styleUrls: ['./article-history.component.scss']
})
export class ArticleHistoryComponent implements OnInit, OnDestroy {

	@Input()
	public article: Article;

	public articleHistory: Array<ArticleHistory>;

	private _compareFrom: ArticleHistory;

	private _compareTo: ArticleHistory;

	private _subscriptions = new Subscription();

	constructor(private _bsModalRef: BsModalRef, private _articleService: ArticleService, private _friendlyVersionPipe: ArticleFriendlyVersionPipe, private _router: Router) { }

	ngOnInit() {
		this._subscriptions.add(
			this._articleService.getArticleHistory(this.article.articleId)
				.subscribe(response => {
					this.articleHistory = response;
					this.resetButtonText();
				})
		);
	}

	public compare(compareArticle: ArticleHistory): void {
		if (this._compareFrom && this._compareFrom.version == compareArticle.version) {
			this._compareFrom = null;
			this.resetButtonText();
		} else if (this._compareFrom) {
			this._router.navigate(["article", "diff", compareArticle.articleId, this._compareFrom.version, compareArticle.version]);
			this._bsModalRef.hide();
		} else {
			this._compareFrom = compareArticle;

			let compareWithVersion = this._friendlyVersionPipe.transform(compareArticle.version);
			this.articleHistory.map(x => {
				if (x.version == compareArticle.version) {
					x.buttonText = `v${compareWithVersion} selected`;
				} else {
					let compareFromVersion = this._friendlyVersionPipe.transform(x.version);
					x.buttonText = `Compare v${compareFromVersion} with v${compareWithVersion}`;
				}
			});
		}
	}

	public close(): void {
		this._bsModalRef.hide();
	}

	private resetButtonText(): void {
		this.articleHistory.map(x => x.buttonText = "Select to compare");
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
