import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';
import { SpinnerService } from '../../services/spinner/spinner.service';

@Component({
	template: ''
})
export class AuthCallbackComponent implements OnInit {

	constructor(private _router: Router, private _authService: AuthService, private _spinnerService: SpinnerService) {
	}

	ngOnInit() {
		this._spinnerService.show("Authenticating");

		this._authService.loggedIn()
			.subscribe(redirectUrl => {
				redirectUrl = redirectUrl || '/';
				this._router.navigateByUrl(redirectUrl);

				this._spinnerService.hide();
			});
	}

}
