// Angular
import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModuleWithProviders } from '@angular/compiler/src/core';

// 3rd party imports
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

import { CollapseModule, BsDropdownModule, TabsModule, ModalModule } from 'ngx-bootstrap';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faShare, faThumbsDown, faThumbsUp, faSync, faBooks, faQuestion, faEdit, faCog, faCogs, faBell, faPhone, faUser, faComment, faGlobe, faEnvelope, faBriefcase, faExchange, faCode, faSmile, faFrown, faRoad, faCompressAlt, faExpandAlt, faEye, faTrashAlt, faSlidersH } from "@fortawesome/pro-solid-svg-icons";

import { MomentModule } from 'ngx-moment';

// *** App

// Modules
import { SharedModule } from '../shared/shared.module';
import { ContentViewerModule } from '../modules/content-viewer/content-viewer.module';
import { ContentManagerModule } from '../modules/content-manager/content-manager.module';
import { SearchModule } from '../modules/search/search.module';
import { ReportsModule } from '../modules/reports/reports.module';
import { ClassificationModule } from '../modules/classification/classification.module';
import { AdminModule } from '../modules/admin/admin.module';

// Components
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AlertComponent } from "./components/alert/alert.component";
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { HomeComponent } from './components/home/home.component';
import { EngineeringHomeComponent } from './components/engineering-home/engineering-home.component';
import { SalesHomeComponent } from './components/sales-home/sales-home.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';

// Services
import { AuthPermissionsGuard } from './services/permissions/auth-permissions.guard';
import { LoggingService } from './services/logging/logging.service';
import { PermissionService } from './services/permissions/permission.service';
import { UserProfileService } from './services/user-profiles/user-profile.service';
import { ErrorHandlerService } from './services/logging/error.handler.service';
import { PeriodService } from './services/periods/period.service';
import { ArticleService } from './services/articles/article.service';
import { SearchService } from './services/searches/search.service';
import { AlertService } from './services/alert/alert.service';
import { CommentService } from './services/comments/comment.service';
import { RolesService } from './services/roles/roles.service';
import { ApplicationService } from './services/applications/application.service';
import { ApplicationVersionsService } from './services/application-versions/application-versions.service';
import { CategoryService } from './services/categories/category.service';
import { MessageService } from './services/messages/message.service';

// Interceptors
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { HeadersInterceptor } from './interceptors/headers.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { CanDeactivateGuard } from './services/can-deactivate/can-deactivate.guard';
import { EscalationComponent } from './components/escalation/escalation.component';

library.add(faShare, faThumbsDown, faThumbsUp, faSync, faBooks, faQuestion, faEdit, faCog, faCogs, faBell, faPhone, faUser, faComment, faGlobe, faEnvelope, faBriefcase, faExchange, faCode, faSmile, faFrown, faRoad, faCompressAlt, faExpandAlt, faEye, faTrashAlt, faSlidersH);

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,

		// ** 3rd Party
		Ng4LoadingSpinnerModule.forRoot(),

		CollapseModule.forRoot(),
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		ModalModule.forRoot(),
		FontAwesomeModule,
		MomentModule,

		TabsModule.forRoot(),

		// ** App

		// Modules
		SharedModule,
		ContentViewerModule,
		ContentManagerModule,
		SearchModule,
		ClassificationModule,
		ReportsModule,
		AdminModule
	],
	exports: [
		// ** 3rd Party
		Ng4LoadingSpinnerModule,

		// ** App
		HomeComponent,
		NavMenuComponent,
		NotFoundComponent,
		AlertComponent
	],
	declarations: [
		NotFoundComponent,
		AlertComponent,
		NavMenuComponent,
		HomeComponent,
		EngineeringHomeComponent,
		SalesHomeComponent,
		UserProfileComponent,
		AuthCallbackComponent,
		EscalationComponent,
	]
})
export class CoreModule {

	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import it into the AppModule only');
		}
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: CoreModule,
			providers: [
				{
					provide: HTTP_INTERCEPTORS,
					useClass: SpinnerInterceptor,
					multi: true
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: ErrorInterceptor,
					multi: true
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: HeadersInterceptor,
					multi: true
				},
				// App Singleton Services
				LoggingService,
				AlertService,
				AuthPermissionsGuard,
				CanDeactivateGuard,
				PermissionService,
				UserProfileService,
				ApplicationService,
				ApplicationVersionsService,
				ArticleService,
				SearchService,
				PeriodService,
				CommentService,
				RolesService,
				{
					provide: ErrorHandler,
					useClass: ErrorHandlerService
				},
				CategoryService,
				MessageService
			]
		};
	}

}
