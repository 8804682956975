import { Component, ViewChild, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, ValidatorFn } from '@angular/forms';

import { Subscription, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { BsModalRef } from 'ngx-bootstrap';

import { ClassificationService } from '../../../services/classification/classification.service';
import { RolesService } from '../../../../../core/services/roles/roles.service';

import { GraphNode } from '../../../models/graph-node.model';
import { SubmittedGraphNode } from '../../../models/submitted-graph-node.model';
import { Role } from '../../../../../core/models/roles/role.model';
import { TroubleshootingInformation } from '../../../models/troubleshooting-information.model';

@Component({
	selector: 'app-common-issue-editor',
	templateUrl: './common-issue-editor.component.pug',
	styleUrls: ['./common-issue-editor.component.scss']
})
export class CommonIssueEditorComponent implements OnInit, OnDestroy {

	@Input()
	public nodeId: number;

	@Input()
	public parentNodeId: number;

	@Input()
	public title: string;

	@ViewChild('listview') listview: any;

	@Output()
	public nodeCreated = new EventEmitter<GraphNode>();

	@Output()
	public nodeEdited = new EventEmitter<GraphNode>();

	public node: GraphNode;

	public nodeForm: FormGroup;

	public hasBeenSubmitted: boolean;

	public isCollapsed: boolean = true;

	public troubleshootingInfo = new Array<TroubleshootingInformation>();

	public teams = new Array<Role>();

	public diagnostics = new Array<any>();

	public fields: Object = { id: 'id', isChecked: 'isChecked' };

	private _subscriptions = new Subscription();

	constructor(private _bsModalRef: BsModalRef, private _fb: FormBuilder, private _classificationService: ClassificationService, private _rolesService: RolesService) {
	}

	ngOnInit() {
		this._subscriptions.add(
			forkJoin(
				this._rolesService.getTeams(),
				this._classificationService.getDiagnosticItems()
			)
				.subscribe(response => {
					this.teams = response[0].sort((left, right): number => { return (left.name > right.name) ? 1 : ((right.name > left.name) ? -1 : 0); });
					this.troubleshootingInfo = response[1];

					this.createForm();

					if (this.nodeId) {
						this.loadNode();
					}
					else {
						this.node = new GraphNode();
						this.setDiagnosticControl();
					}

					if (!!this.title) {
						// the update method makes calls against all of these items, even though we are only supplying a title
						// if we want to use the updateFormFromModel method we need to make sure they are there or we'll throw an exception
						this.node.title = this.title;
						this.node.tags = [];
						this.node.id = null;
						this.node.escalationAlias = null;
						this.node.team = null;

						this.updateFormFromModel();
					}
				})
		);
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

	private loadNode(): void {
		this._subscriptions.add(
			this._classificationService.getNode(this.nodeId)
				.subscribe(response => {
					if (response && response.value) {
						if (!response.value.length) {
							throw new Error(`Unable to retrieve Classification Node '${this.nodeId}'.`);
						}
						else if (response.value.length > 1) {
							// this is odd...
							throw new Error(`More than one result for Classification Node '${this.nodeId}'. This should not be possible.`);
						}
						else {
							this.node = response.value[0];

							this.updateFormFromModel();
						}
					}
				})
		);
	}

	private createForm(): void {
		this.nodeForm = this._fb.group({
			id: [''],
			title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
			isInternal: [false],
			isECC: [false],
			team: ['', [Validators.required]],
			escalationAlias: ['']
		});
	}

	private setDiagnosticControl(): void {
		for (let diag of this.troubleshootingInfo) {
			let checked = false;

			if (this.node.id) {
				checked = this.node.troubleshootingInformationIds.some(r => r == diag.troubleshootingInformationId);
			}

			let item = { id: diag.troubleshootingInformationId, text: diag.whatIsNeeded, isChecked: checked };
			this.diagnostics.push(item);
		}
	}

	private getModelFromForm(): SubmittedGraphNode {
		let node = new SubmittedGraphNode();

		let values = this.nodeForm.value;

		node.id = (values.id ? values.id : null);
		node.parentId = this.parentNodeId;

		node.nodeType = 'common-issue';

		node.title = values.title;

		node.tags = new Array<string>();

		node.tags.push('Common Issue');

		if (values.isInternal) {
			node.tags.push('Private');
		}
		else {
			node.tags.push('Public');
		}

		if (values.isECC) {
			node.tags.push('ECC');
		}

		node.team = values.team;
		node.escalationAlias = values.escalationAlias;

		node.troubleshootingIds = new Array<number>();
		let selecteditems = this.listview.getSelectedItems();
		if (selecteditems.data.length > 0) {
			selecteditems.data.forEach(function (item) {
				node.troubleshootingIds.push(item.id);
			});
		}

		return node;
	}

	private updateFormFromModel(): void {
		let node = this.node;

		if (!node) {
			node = new GraphNode();
		}

		let isInternal = node.tags.map(t => t.toLowerCase()).includes('private');
		let isEcc = this.node.tags.map(t => t.toLowerCase()).includes('ecc');

		this.setDiagnosticControl();

		this.nodeForm.setValue({
			id: this.node.id,
			title: this.node.title,
			isInternal: isInternal,
			isECC: isEcc,
			team: this.node.team,
			escalationAlias: this.node.escalationAlias
		});
	}

	public get versionControls(): FormArray {
		return this.nodeForm.get('versions') as FormArray;
	}

	public close() {
		this._bsModalRef.hide();
	}

	public submit() {
		this.hasBeenSubmitted = true;

		if (this.nodeForm.invalid) {
			return;
		}

		let node = this.getModelFromForm();

		this.nodeForm.disable();

		this._subscriptions.add(
			this._classificationService.saveNode(node)
				.pipe(
					finalize(() => {
						this.nodeForm.enable();
					})
				)
				.subscribe(response => {
					if (!this.node.id) {
						this.nodeCreated.emit(response);
					}
					else {
						this.nodeEdited.emit(response);
					}
					this.close();
				})
		);
	}

}

function minSelected(min = 1) {
	const validator: ValidatorFn = (formArray: FormArray) => {
		const totalSelected = formArray.controls
			.map(control => control.value)
			.reduce((prev, next) => next ? prev + next : prev, 0);

		return totalSelected >= min ? null : { required: true };
	};

	return validator;
}
