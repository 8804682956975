import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap';
import { Article } from '../../../../../../core/models/articles/article.model';
import { ApproveReviewConfirmationComponent } from '../notices-and-confirmations/approve-review-confirmation/approve-review-confirmation.component';
import { RejectReviewConfirmationComponent } from '../notices-and-confirmations/reject-review-confirmation/reject-review-confirmation.component';
import { PermissionService } from '../../../../../../core/services/permissions/permission.service';

@Component({
	selector: 'app-review-approval',
	templateUrl: './review-approval.component.pug',
	styleUrls: ['./review-approval.component.scss']
})
export class ReviewApprovalComponent implements OnInit, OnDestroy {

	@Input()
	public article: Article;

	@Output("refreshNeeded")
	public refreshNeededEmitter = new EventEmitter<boolean>();

	public canReview: boolean = false;

	private _subscriptions = new Subscription();

	constructor(private _modalService: BsModalService, private _permissions: PermissionService) { }

	ngOnInit() {
		this._subscriptions.add(
			this._permissions.canReviewArticles()
				.subscribe(response => {
					this.canReview = response;
				})
		);
	}

	public confirmApproveReview(): void {
		const initialState = {
			'article': this.article
		};

		this._subscriptions.add(
			this._modalService.show(ApproveReviewConfirmationComponent, { initialState: initialState, class: 'modal-lg' })
				.content
				.refreshNeeded
				.subscribe(response => {
					this.refreshNeededEmitter.emit(true);
				})
		);
	}

	public confirmRejectionReview(): void {
		const initialState = {
			'article': this.article
		};

		this._modalService.show(RejectReviewConfirmationComponent, { initialState: initialState, class: 'modal-lg' });
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
