import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.pug',
	styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
