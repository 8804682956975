import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AccordionModule, ModalModule } from 'ngx-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMinus, faSearch, faEdit, faPlusCircle, faMinusCircle } from "@fortawesome/pro-solid-svg-icons";

import { MomentModule } from 'ngx-moment';

import { WidgetComponent } from './components/widget/widget.component';
import { SimpleSearchComponent } from './components/simple-search/simple-search.component';
import { QuickEscalationComponent } from './components/quick-escalation/quick-escalation.component';
import { TopContributorsComponent } from './components/top-contributors/top-contributors.component';
import { LatestArticlesComponent } from './components/latest-articles/latest-articles.component';
import { CommentComponent } from './components/comments/comment/comment.component';
import { CommentThreadsComponent } from './components/comments/comment-threads/comment-threads.component';
import { TriStateCheckboxComponent } from './components/tri-state-checkbox/tri-state-checkbox.component';
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { VotingComponent } from './components/voting/voting/voting.component';
import { VotingDislikeCommentComponent } from './components/voting/voting-dislike-comment/voting-dislike-comment.component';
import { ShareComponent } from './components/share/share.component';
import { SwitchComponent } from './components/switch/switch.component';

import { Nl2BrPipeModule } from 'nl2br-pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { ValuesPipe } from './pipes/values.pipe';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { ArticleFriendlyVersionPipe } from './pipes/article-friendly.pipe';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CreateMetallicTicketComponent } from './components/create-metallic-ticket/create-metallic-ticket.component';
import { MetallicTicketConfirmationComponent } from './components/metallic-ticket-confirmation/metallic-ticket-confirmation.component';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { InternalCaseComponent } from './components/internal-case/internal-case.component';

library.add(faMinus, faSearch, faEdit, faPlusCircle, faMinusCircle);

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		RouterModule,

		// 3rd party imports
		AccordionModule.forRoot(),
		ModalModule.forRoot(),
		FontAwesomeModule,
		MomentModule,
		Nl2BrPipeModule,
		ClipboardModule,
		DropDownListModule,
		BrowserAnimationsModule,
    	TypeaheadModule.forRoot()
	],
	declarations: [
		SimpleSearchComponent,
		QuickEscalationComponent,
		WidgetComponent,
		TopContributorsComponent,
		LatestArticlesComponent,
		TriStateCheckboxComponent,
		CommentComponent,
		CommentThreadsComponent,
		CheckboxGroupComponent,
		CheckboxComponent,
		VotingComponent,
		ShareComponent,
		VotingDislikeCommentComponent,
		SwitchComponent,

		YesNoPipe,
		ValuesPipe,
		MarkdownPipe,
		ArticleFriendlyVersionPipe,
		ConfirmationDialogComponent,
		CreateMetallicTicketComponent,
		MetallicTicketConfirmationComponent,
		InternalCaseComponent
	],
	exports: [
		SimpleSearchComponent,
		QuickEscalationComponent,
		WidgetComponent,
		TopContributorsComponent,
		LatestArticlesComponent,
		TriStateCheckboxComponent,
		CommentComponent,
		CommentThreadsComponent,
		CheckboxGroupComponent,
		CheckboxComponent,
		VotingComponent,
		ShareComponent,
		SwitchComponent,
		ConfirmationDialogComponent,

		YesNoPipe,
		ValuesPipe,
		MarkdownPipe,
		ArticleFriendlyVersionPipe,
		InternalCaseComponent		
	],
	entryComponents: [VotingDislikeCommentComponent, ShareComponent, ConfirmationDialogComponent, CreateMetallicTicketComponent, MetallicTicketConfirmationComponent]
})
export class SharedModule { }
