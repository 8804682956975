import { Component } from '@angular/core';

@Component({
	selector: 'app-sales-home',
	templateUrl: './sales-home.component.pug',
	styleUrls: ['./sales-home.component.scss']
})
export class SalesHomeComponent {

	constructor() { }

}
