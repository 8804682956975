import { Component, OnInit, ViewEncapsulation, ViewChild, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, from, forkJoin } from 'rxjs';
import { map, flatMap, tap, switchMap } from 'rxjs/operators';

import { DataManager, ODataV4Adaptor, Query } from '@syncfusion/ej2-data';
import { TreeViewComponent, NodeSelectEventArgs, DragAndDropEventArgs } from '@syncfusion/ej2-angular-navigations';

import { ClassificationService } from '../../services/classification/classification.service';
import { ClassificationSelectionService } from '../../services/classification-selection/classification-selection.service';
import { AuthService } from '../../../../core/services/auth/auth.service';

import { TreeNode } from '../../models/tree-node.model';
import { GraphNode } from '../../models/graph-node.model';
import { SubmittedGraphNode } from '../../models/submitted-graph-node.model';

var noOp = () => { };

export const TREE_EDITOR_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ClassificationTreeComponent),
	multi: true,
};

export class ClassificationAdapter extends ODataV4Adaptor {

	public processResponse(): Object {
		let original: GraphNode[] = super.processResponse.apply(this, arguments);

		let nodes = original
			.map<TreeNode>((node: GraphNode) => {
				return {
					// specific to GraphNode
					id: node.id,
					nodeType: node.nodeType,
					parentId: node.parentId,
					title: node.title,
					children: node.children,
					team: node.team,
					escalationAlias: node.escalationAlias,
					troubleshootingInformationIds: node.troubleshootingInformationIds,
					tags: node.tags,
					viewOrder: node.viewOrder,
					// specific to TreeNode
					hasChildren: (node.id != '-1'),		// articles have id of -1
					iconCss: `cea-icon-${node.nodeType}`
				}
			});

		return nodes;
	}

}

@Component({
	selector: 'app-classification-tree',
	templateUrl: './classification-tree.component.pug',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./classification-tree.component.scss'],
	providers: [TREE_EDITOR_VALUE_ACCESSOR]
})
export class ClassificationTreeComponent implements OnInit {

	private _data: DataManager;

	private _query: Query = new Query().select('id, parentId, title, nodeType, viewOrder').sortBy('viewOrder');

	private _nodesToExpand: Array<string>;

	private _selectedNodeId: string;

	public field: Object;

	@Input()
	public showArticles: boolean = false;

	@Input()
	public allowReordering: boolean = false;

	@ViewChild('tree')
	public tree: TreeViewComponent;

	@Output()
	isLoaded: boolean = false;

	@Output()
	loaded = new EventEmitter<boolean>();

	@Output()
	nodeSelected = new EventEmitter<GraphNode>();

	private onTouchedCallback: () => void = noOp;

	private onChangeCallback: (_: any) => void = noOp;

	private _originalViewOrder: number;

	constructor(private _classificationService: ClassificationService, private _nodeSelectedService: ClassificationSelectionService, private _authService: AuthService) { }

	ngOnInit() {
		this._data = new DataManager({
			url: "odata/classification",
			adaptor: new ClassificationAdapter,
			headers: [{ 'Authorization': this._authService.getAuthorizationHeaderValue() }]
		});

		this.field = { dataSource: this._data, query: this._query, text: 'title' };

		if (this.showArticles) {
			this._query.addParams("includeArticles", `${this.showArticles}`);
		}

		// listening to databound prevents any race condition that may occur between when the value is set and when the data is loaded
		this.tree.dataBound
			.subscribe(data => {
				// tried to find a boolean on tree for isLoaded, but nothing is present that is a simple boolean
				this.isLoaded = true;
				this.loaded.emit(this.isLoaded);

				this.autoExpandNodes();
			});
	}

	public onNodeSelected(selectEventArgs: any): void {
		if (this.tree.selectedNodes.length == 1) {
			let selectedNode = selectEventArgs.nodeData;

			this.onChangeCallback(selectedNode.id);

			this.getOriginalNode(selectedNode.id)
				.subscribe(node => {
					if (node) {
						this.nodeSelected.emit(node);
						this._nodeSelectedService.setNode(node);
					}
				});
		}
		else {
			this.onChangeCallback(null);
			this.nodeSelected.emit(null);
			this._nodeSelectedService.clearNode();
		}
	}

	public onNodeExpanded(e): void {
		this.autoExpandNodes();
		// this needs to be set on every node expand since we load the data on the fly
		// when i set selected nodes on init, the data would not remain selected presumably because the node id didn't exist
		this.tree.selectedNodes = [this._selectedNodeId];
	}

	public writeValue(value: any): void {
		const selectedNodeId = (this.tree.selectedNodes ? this.tree.selectedNodes[0] : null);
		if (value && value != selectedNodeId) {
			this._selectedNodeId = value;
			this.tree.selectedNodes = [this._selectedNodeId];
			this.getNodesToExpand(value);
		}
	}

	public onCheckDrop(args: DragAndDropEventArgs): void {
		// based on reviewing syncfusion treeview code, the following logic can be used to determine
		// if we are hovering before or after a given DROP node.

		// the business rules we are trying to apply is that nodes can only be dragged around to change
		// order WITHIN a given branch. Nodes CANNOT be moved up or down the tree.

		let allowDrop = false;
		let dropBefore = false;
		let dropAfter = false;

		// do we have drop target or are we in that in between world...
		if (args.droppedNodeData) {
			// AND is it a drop target within the same branch
			if (args.droppedNodeData.parentID === args.draggedNodeData.parentID) {
				// AND it is not ourselves
				if (args.droppedNodeData.id !== args.draggedNodeData.id) {
					// AND is it before or after the drop node instead of on top of it
					if (args.event.offsetY < 7) {
						allowDrop = dropBefore = true;
					}
					else if (args.target.offsetHeight > 0 && args.event.offsetY > (args.target.offsetHeight - 10)) {
						allowDrop = dropAfter = true;
					}
				}
			}
		}

		if (!allowDrop) {
			// need to go looking for the element so we can change the drop icon
			let draggingItem = document.getElementsByClassName('e-drop-in');

			if (!draggingItem.length) {
				draggingItem = document.getElementsByClassName('e-drop-out');
			}

			if (!draggingItem.length) {
				draggingItem = document.getElementsByClassName('e-drop-next');
			}

			if (draggingItem.length) {
				draggingItem[0].classList.add('e-no-drop');
			}

			args.cancel = true;

			return;
		}

		if ((args as any).name === 'nodeDragStop') {
			// because Syncfusion did not implement native Angular events backed by Observables, we cannot
			// chain together the AJAX calls to delay the tree from moving the node when the AJAX calls
			// are complete. So instead we need to cancel it and then perform the move ourselves at the
			// appropriate time.

			args.cancel = true;

			forkJoin(
				this.calculateNewNodeIndex(args.draggedNodeData, args.droppedNodeData, dropBefore),
				this.getOriginalNode((args.draggedNodeData as any).id)
					.pipe(
						map((node: GraphNode) => {
							return {
								id: +node.id,
								title: node.title,
								nodeType: node.nodeType,
								parentId: +node.parentId,
								tags: node.tags,
								team: node.team,
								escalationAlias: node.escalationAlias,
								viewOrder: node.viewOrder
							} as SubmittedGraphNode
						})
					)
			)
				.pipe(
					switchMap(result => {
						this._originalViewOrder = result[1].viewOrder;
						result[1].viewOrder = result[0];

						return this._classificationService.moveNode(result[1]);
					})
				)
				.subscribe(result => {
					// Because the Tree is applying the index AFTER removing it from the DOM, the index
					// may need to be shifted to properly place it
					let newViewOrder = result.viewOrder;
					if (newViewOrder < this._originalViewOrder) {
						newViewOrder--;
					}

					this.tree.moveNodes([`${args.draggedNodeData.id}`], `${args.draggedNodeData.parentID}`, newViewOrder);
				});
		}
	}

	private getOriginalNode(id: string): Observable<GraphNode> {
		// Because of a limitation with Syncfusion, we are unable to retrieve the original object when selected.
		// This is causing us (for now) to reach into the DataManager to get what we need (the *type* of node
		// selected) which in turn is making an oData call back to the server.
		return from(this._data.executeQuery(new Query().where('id', 'equal', +(id)).take(1)))
			.pipe(
				map((data: any) => {
					var node: TreeNode = (data.result ? data.result[0] : null);

					return node;
				})
			);
	}

	private getOriginalChildNodes(parentId: string): Observable<Array<GraphNode>> {
		// Because of a limitation with Syncfusion, we are unable to retrieve the original object when selected.
		// This is causing us (for now) to reach into the DataManager to get what we need (the *type* of node
		// selected) which in turn is making an oData call back to the server.
		return from(this._data.executeQuery(new Query().where('parentId', 'equal', +(parentId))))
			.pipe(
				map((data: any) => {
					var nodes: Array<TreeNode> = data.result;

					return nodes;
				})
			);
	}

	private calculateNewNodeIndex(sourceNode: any, targetNode: any, targetBefore: boolean): Observable<number> {
		if (!sourceNode) {
			throw new Error('sourceNode is required parameter');
		}

		if (!targetNode) {
			throw new Error('targetNode is required parameter');
		}

		return this.getOriginalChildNodes(targetNode.parentID)
			.pipe(
				map(children => {
					let sourceIndex: number = children.findIndex(n => +(n.id) === +(sourceNode.id));
					let targetIndex: number = children.findIndex(n => +(n.id) === +(targetNode.id));

					// viewing order starts at element 1 while arrays start at 0
					sourceIndex += 1;
					targetIndex += 1;

					// most times we should just be able to set the target index to the index of the target node
					//    ...but sometimes, depending on what direction we are moving the node, we need to shift it by 1
					let newIndex: number = targetIndex;

					if (sourceIndex < targetIndex && targetBefore) {
						// moving down further down but before target
						newIndex--;
					}
					else if (sourceIndex > targetIndex && !targetBefore) {
						// moving node further up but after target
						newIndex++;
					}

					return newIndex;
				})
			);
	}

	private getNodesToExpand(id: string) {
		this._classificationService.getParentHierarchy(id).subscribe(
			results => {
				if (results) {
					this._nodesToExpand = results.map(r => r.id.toString());

					this.autoExpandNodes();
				}
			});
	}

	// expands all of the nodes on that have been pushed on the to expand stack
	private autoExpandNodes(): void {
		// if the tree hasn't loaded yet, don't worry about trying to expand since nothing can expand
		if (!this.isLoaded) {
			return;
		}

		if (this._nodesToExpand && !!this._nodesToExpand.length) {
			let nodeId = this._nodesToExpand.shift();

			// this is to prevent parent node from expanding if the parent node is the selected node.
			// i assume we want to stop loading at the selected node and not load up it's children unnecessarily
			if (nodeId && this._selectedNodeId != nodeId) {
				this.tree.expandAll([nodeId]);
			}
		}
	}

	public registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

}
