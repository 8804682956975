import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { RolesPermissionsComponent } from "./components/roles-permissions/roles-permissions.component";
import { AdminToolComponent } from "./components/admin-tools/admin-tool/admin-tool.component";
import { AuthPermissionsGuard } from "../../core/services/permissions/auth-permissions.guard";

const routes: Routes = [
	{
		path: 'admin', canActivate: [AuthPermissionsGuard], data: { roles: ['manage-application-admin-tools'] }, children: [
			{ path: '', component: RolesPermissionsComponent },
			{ path: 'roles-permissions', component: RolesPermissionsComponent },
			{ path: 'admin-tools', component: AdminToolComponent }
		]
	}
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AdminRoutingModule { }
