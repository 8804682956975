export class TroubleshootingInformation {

	public troubleshootingInformationId: number;

	public whatIsNeeded: string;

	public whyItIsNeeded: string;

	public howItIsCollected: string;

}