import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';

import { Subscription } from 'rxjs';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';

import { AlertService } from '../../services/alert/alert.service';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {

	private _subscriptions = new Subscription();

	private _modalConfig = {
		animated: true,
		keyboard: true
	};

	public modal: BsModalRef;

	public message: string;

	@ViewChild('template')
	templateRef: TemplateRef<any>;

	constructor(private _modalService: BsModalService, private _alertService: AlertService) { }

	ngOnInit() {
		this._subscriptions.add(
			this._alertService.messages()
				.subscribe(message => {
					this.message = message;

					this.modal = this._modalService.show(this.templateRef, this._modalConfig);
				})
		);
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
