import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApplicationVersionViewModel } from '../../models/applications/application-version-view-model.model';

import { ConfigurationService } from '../../../modules/runtime-configuration/services/configuration.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationVersionsService {

    private _urlOdataBase: string = "odata/applicationversions";

    constructor(private _httpClient: HttpClient, private _configSvc: ConfigurationService) { }

    public getCommvaultApplicationVersions(isEnabled: boolean, isReleased: boolean, majorOnly: boolean): Observable<Array<ApplicationVersionViewModel>> {
        return this.getApplicationVersions(this._configSvc.configuration.commvaultApplicationId, isEnabled, isReleased, majorOnly);
    }

    public getApplicationVersions(applicationId: string, isEnabled: boolean, isReleased: boolean, majorOnly: boolean): Observable<Array<ApplicationVersionViewModel>> {
        let filter = `$filter=isEnabled eq ${isEnabled} and applicationId eq ${applicationId}`;

        if (isReleased !== null) {
            filter = `${filter} and isReleased eq ${isReleased}`;
        }

        if (majorOnly) {
            filter += ` and servicePack eq '*'`;
        } else {
            filter += ` and servicePack ne '*'`;
        }

        let order = `$orderBy=major desc, releaseDate desc`;

        return this._httpClient.get<Array<ApplicationVersionViewModel>>(this._urlOdataBase + `/get?${filter}&${order}`);
    }

}