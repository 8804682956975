import { IncidentDevelopmentEscalationActivity } from '../../models/incidents/incident-development-escalation-activity.model';
import { IncidentUpdateActivity } from '../../models/incidents/incident-update-activity.model';
import { IncidentResolutionActivity } from '../../models/incidents/incident-resolution-activity.model';
import { IncidentTaskActivity } from '../../models/incidents/incident-task-activity.model';
import { IncidentActivity } from '../../models/incidents/incident-activity.model';

export class IncidentActivitiesService {

	private _isOpen: boolean = false;

	constructor() { }

	private highlightKeywords(value: string, keyword: string): string {
		if (!value || !keyword) {
			return value;
		}

		let escapedKeyword = this.escapeRegex(keyword);

		let imageProtector = new Map<string, string>();

		// this is to prevent issues where words like "Activities" or "Download" would break image links because it would put the highlighting string in the image source
		// replaces all images with guids
		let imgMatches = value.match(new RegExp("<img.+?src=[\"'](.+?)[\"'].+?>", 'gi'));
		if (imgMatches) {
			imgMatches.forEach(match => {
				let guid = this.newGuid();
				imageProtector.set(guid, match);

				value = value.replace(match, guid);
			})
		}

		let valueMatches = value.match(new RegExp(`(${escapedKeyword})`, 'gi'));
		if (valueMatches) {
			this._isOpen = true;
		}

		value = value.replace(new RegExp(`(${escapedKeyword})`, 'gi'), `<em class='keyword'>${keyword}</em>`);

		// replace the temporary guids with the original img values now that we've done our highlights
		imageProtector.forEach((protectorValue: string, key: string) => {
			value = value.replace(key, protectorValue);
		});

		return value;
	}

	// took sample here and seems to be working nicely https://stackoverflow.com/a/26502275
	private newGuid() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			let r = Math.random() * 16 | 0,
				v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	// need equivalent of Regex.Escape - found sample here https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript
	private escapeRegex(stringToEscape: string) {
		return stringToEscape.replace(/[-\/\\^$*+?.()|[\]{}]/gi, '\\$&');
	}

	public formatActivity(activity: IncidentActivity, keyword: string): IncidentActivity {
		activity.description = this.highlightKeywords(activity.description, keyword);
		activity.subject = this.highlightKeywords(activity.subject, keyword);

		switch (activity.type) {
			case 'DevelopmentEscalationActivity':
				(activity as IncidentDevelopmentEscalationActivity).developmentTeam = this.highlightKeywords((activity as IncidentDevelopmentEscalationActivity).developmentTeam, keyword);
				(activity as IncidentDevelopmentEscalationActivity).otherTeam = this.highlightKeywords((activity as IncidentDevelopmentEscalationActivity).otherTeam, keyword);
				(activity as IncidentDevelopmentEscalationActivity).tierTwoTeam = this.highlightKeywords((activity as IncidentDevelopmentEscalationActivity).tierTwoTeam, keyword);
				(activity as IncidentDevelopmentEscalationActivity).businessImpact = this.highlightKeywords((activity as IncidentDevelopmentEscalationActivity).businessImpact, keyword);
				(activity as IncidentDevelopmentEscalationActivity).criticalAfterHoursReason = this.highlightKeywords((activity as IncidentDevelopmentEscalationActivity).criticalAfterHoursReason, keyword);
				(activity as IncidentDevelopmentEscalationActivity).subject = this.highlightKeywords((activity as IncidentDevelopmentEscalationActivity).subject, keyword);
				(activity as IncidentDevelopmentEscalationActivity).escalationPurpose = this.highlightKeywords((activity as IncidentDevelopmentEscalationActivity).escalationPurpose, keyword);
				(activity as IncidentDevelopmentEscalationActivity).issueSummary = this.highlightKeywords((activity as IncidentDevelopmentEscalationActivity).issueSummary, keyword);
				break;
			case 'UpdateActivity':
				(activity as IncidentUpdateActivity).systemLogCuts = this.highlightKeywords((activity as IncidentUpdateActivity).systemLogCuts, keyword);
				(activity as IncidentUpdateActivity).systemLogAnalysis = this.highlightKeywords((activity as IncidentUpdateActivity).systemLogAnalysis, keyword);
				break;
			case 'ResolutionActivity':
				(activity as IncidentResolutionActivity).findings = this.highlightKeywords((activity as IncidentResolutionActivity).findings, keyword);
				(activity as IncidentResolutionActivity).underlyingCause = this.highlightKeywords((activity as IncidentResolutionActivity).underlyingCause, keyword);
				(activity as IncidentResolutionActivity).secondaryCause = this.highlightKeywords((activity as IncidentResolutionActivity).secondaryCause, keyword);
				(activity as IncidentResolutionActivity).tertiaryCause = this.highlightKeywords((activity as IncidentResolutionActivity).tertiaryCause, keyword);
				(activity as IncidentResolutionActivity).patchIdScriptServicePackOrMR = this.highlightKeywords((activity as IncidentResolutionActivity).patchIdScriptServicePackOrMR, keyword);
				break;
			case 'TaskActivity':
				(activity as IncidentTaskActivity).systemLogCuts = this.highlightKeywords((activity as IncidentTaskActivity).systemLogCuts, keyword);
				(activity as IncidentTaskActivity).systemLogAnalysis = this.highlightKeywords((activity as IncidentTaskActivity).systemLogAnalysis, keyword);
				break;
		}

		activity.isDisplayOpen = this._isOpen;

		this._isOpen = false;

		return activity;
	}

	public filterActivityType(activities: Array<IncidentActivity>, activityTypes: string[]) {
		let filteredActivities = new Array<IncidentActivity>();
		activities.forEach(activity => {
			activityTypes.forEach(type => {
				if (activity.type == type) {
					filteredActivities.push(activity);
				}
			})
		});

		return filteredActivities;
	}

	public filterEscalationEmailActivities(activities: Array<IncidentActivity>, aliases: Array<string>): Array<IncidentActivity> {
		let filteredActivities = new Array<IncidentActivity>();
		activities.forEach(activity => {
			aliases.forEach(alias => {
				if (activity.type == 'UpdateActivity' &&
					(((activity as IncidentUpdateActivity).to && (activity as IncidentUpdateActivity).to.match(alias)) ||
						((activity as IncidentUpdateActivity).cc && (activity as IncidentUpdateActivity).cc.match(alias)))) {
					filteredActivities.push(activity);
				}
			})
		});

		return filteredActivities;
	}

}
