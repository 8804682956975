import { Permission } from "../permissions/permission.model";

export class Role {

   public id: string;

   public name: string;

   public isMembershipManaged: boolean;

   public permissions: Array<Permission>;

}
