import { Component, OnInit, Input, ElementRef, ViewEncapsulation, Renderer2, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as TuiEditor from 'tui-editor-alternate';

import ToastUIEditor from 'tui-editor-alternate';

import "../../extensions/highlighter/tui-editor-extHighlighter";

var noOp = () => { };

export const MD_EDITOR_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => MarkdownEditorComponent),
	multi: true,
};

@Component({
	selector: 'app-markdown-editor',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './markdown-editor.component.pug',
	styleUrls: [
		'../../../../../../node_modules/tui-editor-alternate/dist/tui-editor-contents.css',
		'../../../../../../node_modules/tui-editor-alternate/dist/tui-editor.css',
		'./codemirror.scss',
		'./markdown-editor.component.scss'
	],
	providers: [MD_EDITOR_VALUE_ACCESSOR]
})
export class MarkdownEditorComponent implements OnInit, ControlValueAccessor {

	@Input()
	options: any;

	private _editor: any;

	private onTouchedCallback: () => void = noOp;

	private onChangeCallback: (_: any) => void = noOp;

	constructor(private renderer: Renderer2, private el: ElementRef) { }

	ngOnInit() {
		this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
		this.createEditor();
	}

	private createEditor(): void {
		this._editor = new TuiEditor(Object.assign({
			el: this.el.nativeElement,
			initialEditType: 'markdown',
			previewStyle: 'vertical',
			height: '300px',
			usageStatistics: false,
			toolbarItems: [
				'heading',
				'bold',
				'italic',
				'strike',
				'divider',
				'hr',
				'quote',
				'divider',
				'ul',
				'ol',
				// 'task',
				// 'indent',
				// 'outdent',
				'divider',
				'table',
				'image',
				'link',
				'divider',
				'code',
				'codeblock'
			],
			events: {
				change: this._onEditorValueChange.bind(this),
			},
			exts: ['highlighter']
		}, this.options));

		ToastUIEditor.markdownitHighlight.options.highlight = function (code) {
			// apply highlighting

			// this LOOKS like the left and right sides are the same but it is not
			let value = code.replace(/<(span class=['"]hl hl-\d['"])>(.*)<(\/span)>/g, "<$1>$2<$3>");

			return value;
		};
	}

	private _onEditorValueChange(): void {
		const markdownData = this._editor.getMarkdown();

		this.onChangeCallback(markdownData);
	}

	public writeValue(value: any): void {
		const markdownData = this._editor.getMarkdown();

		if (value != markdownData) {
			this._editor.setMarkdown(value);
		}
	}

	public registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		// the editor does not support disabling
	}

}
