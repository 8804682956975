import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { forkJoin } from 'rxjs';
import { ArticleService } from '../../../../../core/services/articles/article.service';
import { Article } from '../../../../../core/models/articles/article.model';
import { ArticleMetaData } from '../../../../../core/models/articles/article-metadata.model';
import { ArticleContributor } from '../../../../../core/models/articles/article-contributor.model';
import { ArticleLogPhrase } from '../../../../../core/models/articles/article-log-phrase.model';
import { ArticleTargetedVersion } from '../../../../../core/models/articles/article-targeted-version.model';
import { ArticleTargetRole } from '../../../../../core/models/articles/article-target-role.model';

@Component({
	selector: 'app-articles-compare',
	templateUrl: './articles-compare.component.pug',
	styleUrls: ['./articles-compare.component.scss']
})
export class ArticlesCompareComponent implements OnInit {

	public leftArticle: Article;

	public leftMetadata: ArticleMetaData;

	public rightArticle: Article;

	public rightMetadata: ArticleMetaData;

	public showRightColumn: boolean = false;

	constructor(@Inject(LOCALE_ID) private _locale: string, private _route: ActivatedRoute, private _articleService: ArticleService) { }

	ngOnInit() {
		this._route.paramMap.subscribe(params => {
			// we really need to refactor how we retrieve articles...
			let expand = 'articleDeflectedIncidents, articleTargetedVersions($orderby=versionMajor desc), articleLogPhrases, articleContributors($orderby=timestamp desc;$expand = user), author, editor';

			let articleId = params.get("id") ? parseInt(params.get("id")) : null;
			let version1 = params.get("version1") ? parseInt(params.get("version1")) : null;
			let version2 = params.get("version2") ? parseInt(params.get("version2")) : null;

			let getArticlesV1 = this._articleService.getArticles(articleId, version1, expand);
			let getMetadataV1 = this._articleService.getArticleMetadata(articleId, version1);

			let getArticlesV2 = this._articleService.getArticles(articleId, version2, expand);
			let getMetadataV2 = this._articleService.getArticleMetadata(articleId, version2);

			forkJoin(getArticlesV1, getMetadataV1, getArticlesV2, getMetadataV2).subscribe(response => {
				if (version1 < version2) {
					this.leftArticle = response[0];
					this.leftMetadata = response[1];
					this.rightArticle = response[2];
					this.rightMetadata = response[3];
				} else {
					this.rightArticle = response[0];
					this.rightMetadata = response[1];
					this.leftArticle = response[2];
					this.leftMetadata = response[3];
				}
			});
		})
	}

	public toggleRightColumn(): void {
		this.showRightColumn = !this.showRightColumn;
	}

	public hasProperty(obj: any, propertyName: string): boolean {
		if (!obj) {
			return false;
		}

		return propertyName in obj;
	}

	public contributorsToString(contributors: Array<any>): string {
		if (!contributors) {
			return null;
		}

		let retVal = new Array<string>();

		contributors.forEach(contributor => {
			retVal.push(`${contributor.user.userName} (${formatDate(contributor.timestamp, 'EEEE, MMMM dd yyyy, h:mm a', this._locale)})`);
		});

		return retVal.join("<br>");
	}

	public dateToString(value: Date): string {
		return (value ? formatDate(value, 'EEEE, MMMM dd yyyy', this._locale) : null);
	}

	public versionsToString(targetedVersions: Array<ArticleTargetedVersion>): string {
		if (!targetedVersions) {
			return null;
		}

		let retVal = new Array<string>();

		targetedVersions.forEach(tv => {
			if (tv.versionServicePack != "") {
				retVal.push(`v${tv.versionMajor} ${tv.versionServicePack}`);
			} else {
				retVal.push(`v${tv.versionMajor}`);
			}
		});

		return retVal.join("<br>");
	}

	public logPhrasesToString(logPhrases: Array<ArticleLogPhrase>): string {
		if (!logPhrases) {
			return null;
		}

		let retVal = new Array<string>();

		logPhrases.forEach(lp => {
			retVal.push(`File: ${lp.filename}   Phrase: ${lp.phrase}`);
		});

		return retVal.join("<br>");
	}

	public targetedRolesToString(targetedRoles: Array<ArticleTargetRole>): string {
		if (!targetedRoles) {
			return null;
		}

		let retVal = new Array<string>();

		targetedRoles.forEach(tr => {
			retVal.push(tr.role.name);
		});

		return retVal.join("<br>");
	}

}
