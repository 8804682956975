import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../../modules/runtime-configuration/services/configuration.service';

@Component({
  selector: 'app-escalation',
  templateUrl: './escalation.component.html',
  styleUrls: ['./escalation.component.scss']
})
export class EscalationComponent implements OnInit {

	constructor(private _configSvc: ConfigurationService) { }

  ngOnInit() {
	  window.location.href = this._configSvc.configuration.escalationsRedirectionUrl;
  }

}
