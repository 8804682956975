import { Component, OnInit, PACKAGE_ROOT_URL } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UserProfileService } from '../../services/user-profiles/user-profile.service';
import { RolesService } from '../../services/roles/roles.service';
import { PermissionService } from '../../services/permissions/permission.service';

import { UserProfile } from '../../models/user-profiles/user-profile.model';
import { Role } from '../../models/roles/role.model';
import { Permission } from '../../models/permissions/permission.model';

import { UserProfileUpdate } from '../../models/user-profiles/user-profile-update.model';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.pug',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

	public profileForm: FormGroup;

	public profile: UserProfile;

	public roles: Array<Role>;

	public roleSubscriptions: Array<Role> = [];

	public managedRoles: Array<Role> = [];

	public permissions: Array<Permission>;

	public profileUpdated: boolean = false;

	constructor(private _fb: FormBuilder, private _profileService: UserProfileService, private _rolesService: RolesService, private _permsService: PermissionService) {
		this.createForm();
	}

	private createForm(): void {
		this.profileForm = this._fb.group({
			searchDetailsRendering: '',
			enableArticleUpVoteNotifications: false,
			enableArticleReviewNotifications: false,
			defaultAnonymousVotingComments: false,
			defaultEditorMode: '',
			homePage: '',
			roleSubscriptions: new FormArray([])
		});
	}

	ngOnInit() {
		forkJoin(this._profileService.getMyProfile(), this._rolesService.getTeams()).subscribe(response => {
			console.debug('UserProfileComponent | getMyRoles() | Processing response');

			let profileResponse = response[0];
			this.profile = profileResponse;

			this.profileForm.patchValue({
				searchDetailsRendering: profileResponse.searchDetailsAction,
				enableArticleUpVoteNotifications: profileResponse.enableArticleUpVoteNotifications,
				enableArticleReviewNotifications: profileResponse.enableArticleReviewNotifications,
				defaultAnonymousVotingComments: profileResponse.defaultToAnonymousComments,
				defaultEditorMode: profileResponse.defaultEditorMode,
				homePage: profileResponse.homePage
			});

			this.roleSubscriptions = response[1].sort((left, right): number => { return (left.name > right.name) ? 1 : ((right.name > left.name) ? -1 : 0); });

			this.roleSubscriptions.map((a, i) => {
				(this.profileForm.controls.roleSubscriptions as FormArray).push(new FormControl(false));
			});

			// mark selected controls based on user profile
			if (this.profile.userRoleSubscriptions) {
				this.profile.userRoleSubscriptions.forEach(mySubscription => {
					let index = this.roleSubscriptions.findIndex(roleSubscription => roleSubscription.id === mySubscription.roleId);
					if (index >= 0) {
						(this.profileForm.controls.roleSubscriptions as FormArray).controls[index].patchValue(true);
					}
				});

				// mark controls that have membershipmanaged to disabled
				var managedRoles = this.profile.userRoleSubscriptions.filter(r => r.isMembershipManaged === true);
				managedRoles.forEach(managedSubscription => {
					let index = this.roleSubscriptions.findIndex(roleSubscription => roleSubscription.id === managedSubscription.roleId);
					if (index >= 0) {
						(this.profileForm.controls.roleSubscriptions as FormArray).controls[index].disable();
						this.managedRoles.push(this.roleSubscriptions[index]);
						//console.log((this.profileForm.controls.userRoles as FormArray).controls[index].value);
					}
				});
			}

		})

		this._rolesService.getMyRoles()
			.subscribe(result => {
				console.debug('UserProfileComponent | getMyProfile() | Processing response');

				this.roles = result.sort((left, right): number => { return (left.name > right.name) ? 1 : ((right.name > left.name) ? -1 : 0); });

				this.roles.forEach(role => {
					role.permissions = role.permissions.sort((left, right): number => { return (left.name > right.name) ? 1 : ((right.name > left.name) ? -1 : 0); });
				});
			});

		this._permsService.getMyPermissions()
			.subscribe(result => {
				console.debug('UserProfileComponent | getMyPermissions() | Processing response');

				this.permissions = result.sort((left, right): number => { return (left.name > right.name) ? 1 : ((right.name > left.name) ? -1 : 0); });
			});
	}

	public onSubmit() {
		this.profileUpdated = false;

		const data = this.profileForm.value;

		let rolesSubscriptionVm = new Array<string>();
		for (var i = 0; i < (this.profileForm.controls.roleSubscriptions as FormArray).controls.length; i++) {
			if ((this.profileForm.controls.roleSubscriptions as FormArray).controls[i].value) {
				rolesSubscriptionVm.push(this.roleSubscriptions[i].id);
			}
		}

		let vm: UserProfileUpdate = {
			id: this.profile.id,
			searchDetailsAction: data.searchDetailsRendering,
			enableArticleUpVoteNotifications: data.enableArticleUpVoteNotifications,
			enableArticleReviewNotifications: data.enableArticleReviewNotifications,
			defaultToAnonymousComments: data.defaultAnonymousVotingComments,
			defaultEditorMode: data.defaultEditorMode,
			homePage: data.homePage,
			subscriptions: rolesSubscriptionVm
		};

		this.profileForm.disable();

		this._profileService.updateMyProfile(vm)
			.subscribe(profile => {
				// successful update returns full (and updated) profile
				this.profile = profile;

				this.profileForm.enable();

				this.profileForm.patchValue({
					searchDetailsRendering: profile.searchDetailsAction,
					enableArticleUpVoteNotifications: profile.enableArticleUpVoteNotifications,
					enableArticleReviewNotifications: profile.enableArticleReviewNotifications,
					defaultAnonymousVotingComments: profile.defaultToAnonymousComments,
					defaultEditorMode: profile.defaultEditorMode,
					homePage: profile.homePage
				});

				this.profileForm.reset(this.profileForm.value);

				// mark controls that have membershipmanaged to disabled
				this.managedRoles.forEach(managedRole => {
					let index = this.roleSubscriptions.findIndex(roleSubscription => roleSubscription.id === managedRole.id);
					if (index >= 0) {
						(this.profileForm.controls.roleSubscriptions as FormArray).controls[index].disable();
						let roleSubscriptionIndex = this.profile.userRoleSubscriptions.findIndex(mr => mr.roleId === this.roleSubscriptions[index].id);
						this.profile.userRoleSubscriptions[roleSubscriptionIndex].isMembershipManaged = true;
						//console.log((this.profileForm.controls.userRoles as FormArray).controls[index].value);
					}
				});

				this.profileUpdated = true;
			});
	}

}
