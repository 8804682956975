import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from "rxjs";
import { map, catchError } from 'rxjs/operators';

import { UserContribution } from '../../models/articles/user-contribution.model';
import { ArticleAuthor } from '../../models/articles/article-author.model';
import { LatestArticles } from '../../models/articles/latest-articles.model';
import { ArticleDefinition } from '../../models/articles/article-definition.model';
import { PeriodOption } from '../../models/periods/period-option.model';
import { Article } from '../../models/articles/article.model';
import { Views } from '../../models/views/views.model';
import { TechnicalNoteArticle } from '../../models/articles/article-technical-note.model';
import { SolutionArticle } from '../../models/articles/article-solution.model';
import { TroubleshootingArticle } from '../../models/articles/article-troubleshooting.model';
import { FrequentlyAskedQuestionArticle } from '../../models/articles/article-faq.model';
import { BestPracticesArticle } from '../../models/articles/article-best-practices.model';
import { TechnicalReferenceArticle } from '../../models/articles/article-technical-reference.model';
import { ArticleMetaData } from '../../models/articles/article-metadata.model';
import { ArticleQualityVote } from '../../models/articles/article-quality-vote.model';
import { ArticleHistory } from '../../models/articles/article-history.model';
import { ArticleQualityVoteResult } from '../../models/articles/article-quality-vote-result.model';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
	})
};

@Injectable({
	providedIn: 'root'
})
export class ArticleService {

	private _urlBase: string = "api/articles";

	private _urlOdataBase: string = "odata/article";

	constructor(private _httpClient: HttpClient) { }

	public getTopContributors(periodFilter: PeriodOption, roleId: string): Observable<Array<UserContribution>> {
		let params = new Array<string>();

		if (periodFilter && periodFilter.periodStart) {
			params.push(`startPeriod=${periodFilter.periodStart}`);
		}

		if (periodFilter && periodFilter.periodEnd) {
			params.push(`endPeriod=${periodFilter.periodEnd}`);
		}

		if (roleId) {
			params.push(`roleId=${roleId}`);
		}

		let queryString = params.join("&");

		return this._httpClient.get<Array<any>>(this._urlBase + `/views/topContributors?${queryString}`).pipe(
			map(contributionData => {
				return contributionData.map(x => {
					let uc = new UserContribution();
					uc.author = new ArticleAuthor();

					uc.author.userName = x.authorName;
					uc.articlesCount = x.contributionCount;

					return uc;
				});
			})
		);
	}

	public getLatestArticles(): Observable<Array<LatestArticles>> {
		return this._httpClient.get<Array<LatestArticles>>(this._urlBase + `/views/latest`).pipe(
			map(contributionData => {
				return contributionData.map(x => {
					let uc = new LatestArticles();
					Object.assign(uc, x);

					let author = new ArticleAuthor();
					Object.assign(author, x.author);

					uc.author = author;

					return uc;
				});
			})
		);
	}

	public getArticleTypes(): Observable<Array<ArticleDefinition>> {
		return this._httpClient.get<Array<ArticleDefinition>>(this._urlBase + `/types`).pipe(
			map(definitionData => {
				return definitionData.map(x => {
					let ad = new ArticleDefinition();
					Object.assign(ad, x);
					return ad;
				});
			})
		);
	}

	public getArticleType(type: string): Observable<ArticleDefinition> {
		return this._httpClient.get<ArticleDefinition>(this._urlBase + `/types/${type}`).pipe(
			map(definition => {
				let ad = new ArticleDefinition();

				Object.assign(ad, definition);

				return ad;
			})
		);
	}

	public getArticles(id: number, version: number, expand: string): Observable<Article> {
		let keyFilter = `${id}`;

		if (version) {
			keyFilter = `${keyFilter}, ${version}`;
		}

		let params = new Array<string>();

		if (expand) {
			params.push(`$expand=${expand}`);
		}

		const queryString = params.join("&");

		const url = `${this._urlOdataBase}(${keyFilter})?${queryString}`;

		return this._httpClient.get<Article>(url);
	}

	public getLatestArticleById(id: number, version?: number): Observable<Article> {
		let expand = 'articleDeflectedIncidents, articleTargetedVersions($orderby=versionMajor desc), articleLogPhrases, articleContributors($orderby=timestamp desc;$expand = user), author, editor, *';

		let url = this._urlOdataBase;

		if (!version) {
			url += `/latest(id='${id}')`;
		} else {
			url += `(${id}, ${version})`
		}

		url += `?$expand=${expand}`;

		return this._httpClient.get<Article>(url);
	}

	public getViews(): Observable<Array<Views>> {
		return this._httpClient.get<Array<Views>>(`${this._urlBase}/views`);
	}

	public getArticleMetadata(articleId: number, version: number = null): Observable<ArticleMetaData> {
		let url = `${this._urlBase}/meta/${articleId}`;

		if (version) {
			url = `${url}/${version}`;
		}

		return this._httpClient.get<ArticleMetaData>(url).pipe(
			catchError(() => {
				return of(null);
			})
		);
	}

	public getLatestArticleVersion(articleId: number): Observable<number> {
		let hideProgressOptions = {
			headers: httpOptions.headers.set('X-Hide-Progress', 'true')
		};
		return this._httpClient.get<number>(`${this._urlBase}/${articleId}/latestVersion`, hideProgressOptions);
	}

	public getPublishedArticleVersion(articleId: number): Observable<number> {
		return this._httpClient.get<number>(`${this._urlBase}/${articleId}/publishedVersion`);
	}

	public getDefaultedArticle(type: string): Observable<Article> {
		return this._httpClient.get<Article>(`${this._urlBase}/default/${type}`)
			.pipe(
				map(
					response => {
						let article = <any>response;

						// ** A better solution would be server side but that would involve new ViewModels

						// Need to clean up some default data type values that better match client environment
						article.versionImplied = null;
						article.versionLabel = null;

						article.created = null;
						article.modified = null;
						article.isMajorVersion = false;		// this is because of the default 0 for version

						if (article.hasOwnProperty('categoryId') && article.categoryId === 0) {
							article.categoryId = null;
						}

						return response;
					}
				)
			);
	}

	public createArticle(type: string): Article {
		// Not really happy about this approach. Think I would want a more
		// abstract approach...maybe specify type as property of definition

		// think https://stackoverflow.com/a/21159196/240372 would help

		if (!type) {
			return null;
		}

		switch (type.toLowerCase()) {
			case "technicalnote": {
				return new TechnicalNoteArticle();
			}
			case "solution": {
				return new SolutionArticle();
			}
			case "troubleshooting": {
				return new TroubleshootingArticle();
			}
			case "frequentlyaskedquestion": {
				return new FrequentlyAskedQuestionArticle();
			}
			case "bestpractices": {
				return new BestPracticesArticle();
			}
			case "technicalreference": {
				return new TechnicalReferenceArticle();
			}
		}

		return null;
	}

	public getArticleQualityVote(articleId: number): Observable<ArticleQualityVoteResult> {
		return this._httpClient.get<ArticleQualityVoteResult>(this._urlBase + `/${articleId}/votes`);
	}

	public voteAsync(articleId: number, version: number, request: ArticleQualityVote): Observable<ArticleQualityVoteResult> {
		let hideProgressOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'X-Hide-Progress': 'true'
			})
		};
		return this._httpClient
			.post<ArticleQualityVoteResult>(this._urlBase + `/${articleId}/${version}/votes`, request, hideProgressOptions);
	}

	public saveArticle(article: Article, isMajorVersion: boolean = false): Observable<Article> {
		return this._httpClient.post<Article>(`${this._urlBase}?isMajorVersion=${isMajorVersion}`, article);
	}

	public approvePublish(articleId: number, version: number): Observable<Article> {
		return this._httpClient.post<Article>(`${this._urlBase}/${articleId}/${version}/workflow/approve`, null);
	}

	public denyPublish(articleId: number, version: number, denialReason: string): Observable<Article> {
		return this._httpClient.post<Article>(`${this._urlBase}/${articleId}/${version}/workflow/deny`, JSON.stringify(denialReason), httpOptions);
	}

	public approveReview(articleId: number, version: number): Observable<Article> {
		return this._httpClient.post<Article>(`${this._urlBase}/${articleId}/${version}/workflow/reviewed`, null);
	}

	public denyReview(articleId: number, version: number, denialReason: string): Observable<Article> {
		return this._httpClient.post<Article>(`${this._urlBase}/${articleId}/${version}/workflow/denyreview`, JSON.stringify(denialReason), httpOptions);
	}

	public approvePartner(articleId: number, version: number): Observable<Article> {
		return this._httpClient.post<Article>(`${this._urlBase}/${articleId}/${version}/workflow/approvepartner`, null);
	}

	public denyPartner(articleId: number, version: number, denialReason: string): Observable<Article> {
		return this._httpClient.post<Article>(`${this._urlBase}/${articleId}/${version}/workflow/denypartner`, JSON.stringify(denialReason), httpOptions);
	}

	public unpublish(articleId: number): Observable<Article> {
		return this._httpClient.post<Article>(`${this._urlBase}/${articleId}/unpublish`, null);
	}

	public deleteArticles(articleIds: Array<number>): Observable<void> {
		return this._httpClient.delete<void>(`${this._urlBase}?ids=${articleIds.join(',')}`, httpOptions);
	}

	public deleteArticle(articleId: number, version: number, includePrevious: boolean = false): Observable<void> {
		let url = `${this._urlOdataBase}(${articleId},${version})`;

		if (includePrevious) {
			url += '?includePrevious=true';
		}

		return this._httpClient.delete<void>(url);
	}

	public nominateAsSolution(articleId: number, version: number): Observable<Article> {
		return this._httpClient.post<Article>(`${this._urlBase}/${articleId}/${version}/workflow/nominate`, null);
	}

	public denyPromotion(articleId: number, version: number, denialReason: string): Observable<Article> {
		return this._httpClient.post<Article>(`${this._urlBase}/${articleId}/${version}/workflow/denypromotion`, JSON.stringify(denialReason), httpOptions);
	}

	public getArticleHistory(articleId: number): Observable<Array<ArticleHistory>> {
		return this._httpClient.get<Array<ArticleHistory>>(`${this._urlBase}/${articleId}/history`);
	}

	public setWorkflowTransition(articleId: number, version: number, action: string, feedback: string): Observable<Article> {
		return this._httpClient.post<Article>(`${this._urlOdataBase}(${articleId},${version})/workflow`, { action: action, feedback: feedback });
	}

	public recordArticleInspection(articleId: number, userId: string): Observable<boolean> {
		let hideProgressOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'X-Hide-Progress': 'true'
			})
		};
		return this._httpClient.post<boolean>(`${this._urlBase}/${articleId}/inspection/user/${userId}`, null, hideProgressOptions);
	}

	public updateArticleClassification(articleId: number, version: number, classificationCode: number) {
		const url = `${this._urlBase}/${articleId}/${version}`;

		const patch = [
			{ op: 'replace', path: '/proposedCommonIssueTitle', value: null },
			{ op: 'replace', path: '/classificationText', value: null },
			{ op: 'replace', path: '/classificationCode', value: classificationCode }
		];

		return this._httpClient.patch<Article>(url, patch);
	}

	public getWorkflowTriggers(articleId: number, version: number): Observable<Array<number>> {
		return this._httpClient.get<Array<number>>(`${this._urlBase}/${articleId}/${version}/workflowtriggers`);
	}

}
