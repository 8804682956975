export class ArticleAuthor {

	public id: string;

	public userName: string;

	public getLink() {
		return `/search;facets=owningUser%3A${this.userName}`;
	}

	public getSearchFacet() {
		return `owningUser:${this.userName}`;
	}

}
