import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MomentModule } from 'ngx-moment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule, ButtonsModule, BsDropdownModule } from 'ngx-bootstrap';
import { DiffMatchPatchModule } from "ng-diff-match-patch";

import { SharedModule } from '../../shared/shared.module';
import { ContentViewerRoutingModule } from './content-viewer-routing.module';

import { ArticleFriendlyVersionPipe } from '../../shared/pipes/article-friendly.pipe';

import { ArticlesViewerComponent } from './components/articles/articles-viewer/articles-viewer.component';
import { ArticlesViewerTechnicalNoteComponent } from './components/articles/articles-viewer-technicalnote/articles-viewer-technicalnote.component';
import { ArticlesViewerSolutionComponent } from './components/articles/articles-viewer-solution/articles-viewer-solution.component';
import { ArticlesViewerTroubleshootingComponent } from './components/articles/articles-viewer-troubleshooting/articles-viewer-troubleshooting.component';
import { ArticlesViewerTechnicalReferenceComponent } from './components/articles/articles-viewer-technical-reference/articles-viewer-technical-reference.component';
import { ArticlesViewerBestPracticesComponent } from './components/articles/articles-viewer-best-practices/articles-viewer-best-practices.component';
import { ArticlesViewerFrequentlyAskedQuestionComponent } from './components/articles/articles-viewer-frequently-asked-question/articles-viewer-frequently-asked-question.component';
import { ArticlesViewerModalPreviewComponent } from './components/articles/articles-viewer-modal-preview/articles-viewer-modal-preview.component';

import { IncidentViewerComponent } from './components/incidents/incident-viewer/incident-viewer.component';
import { IncidentActivitiesViewerComponent } from './components/incidents/incident-activities-viewer/incident-activities-viewer.component';
import { IncidentEscalationComponent } from './components/incidents/incident-escalation/incident-escalation.component';
import { IncidentShareComponent } from './components/incidents/incident-share/incident-share.component';
import { ArticleVersionIndicatorComponent } from './components/articles/article-version-indicator/article-version-indicator.component';
import { ApprovePublishConfirmationComponent } from './components/articles/toolbar/notices-and-confirmations/approve-publish-confirmation/approve-publish-confirmation.component';
import { RejectPublishConfirmationComponent } from './components/articles/toolbar/notices-and-confirmations/reject-publish-confirmation/reject-publish-confirmation.component';
import { ApproveReviewConfirmationComponent } from './components/articles/toolbar/notices-and-confirmations/approve-review-confirmation/approve-review-confirmation.component';
import { RejectReviewConfirmationComponent } from './components/articles/toolbar/notices-and-confirmations/reject-review-confirmation/reject-review-confirmation.component';
import { ReviewApprovalComponent } from './components/articles/toolbar/review-approval/review-approval.component';
import { PublishApprovalComponent } from './components/articles/toolbar/publish-approval/publish-approval.component';
import { ArticleVotingComponent } from './components/articles/toolbar/article-voting/article-voting.component';
import { ArticleHistoryComponent } from './components/articles/toolbar/article-history/article-history.component';
import { ArticlesCompareComponent } from './components/articles/articles-compare/articles-compare.component';
import { PartnerApprovalComponent } from './components/articles/toolbar/partner-approval/partner-approval.component';
import { ApprovePartnerConfirmationComponent } from './components/articles/toolbar/notices-and-confirmations/approve-partner-confirmation/approve-partner-confirmation.component';
import { RejectPartnerConfirmationComponent } from './components/articles/toolbar/notices-and-confirmations/reject-partner-confirmation/reject-partner-confirmation.component';
import { PsrViewerComponent } from './components/psrs/psr-viewer/psr-viewer.component';
import { PsrActivitiesViewerComponent } from './components/psrs/psr-activities-viewer/psr-activities-viewer.component';
import { WorkflowTransitionComponent } from './components/articles/toolbar/workflow-transition/workflow-transition.component';
import { ConfirmationWithCommentComponent } from './components/articles/toolbar/notices-and-confirmations/confirmation-with-comment/confirmation-with-comment.component';
import { AcceptWithCommentComponent } from './components/articles/toolbar/notices-and-confirmations/accept-with-comment/accept-with-comment.component';
import { RejectWithCommentComponent } from './components/articles/toolbar/notices-and-confirmations/reject-with-comment/reject-with-comment.component';
import { RejectPromotionComponent } from './components/articles/toolbar/reject-promotion/reject-promotion.component';
import { RejectPromotionConfirmationComponent } from './components/articles/toolbar/notices-and-confirmations/reject-promotion-confirmation/reject-promotion-confirmation.component';
import { IncidentInternalNoteComponent } from './components/incidents/incident-internal-note/incident-internal-note.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { IncidentEscalationConfirmationComponent } from './components/incidents/incident-escalation-confirmation/incident-escalation-confirmation.component';
import { IncidentActivitiesRenderComponent } from './components/incidents/incident-activities-render/incident-activities-render.component';
import { IncidentActivitiesHeaderComponent } from './components/incidents/incident-activities-header/incident-activities-header.component';

@NgModule({
   imports: [
	  CommonModule,
	  ReactiveFormsModule,
	  FormsModule,
	  MomentModule,
	  FontAwesomeModule,
	  AccordionModule,
	  ButtonsModule,
	  DiffMatchPatchModule,
	  BsDropdownModule,
	  AutoCompleteModule,

	  // ** App
	  SharedModule,
	  ContentViewerRoutingModule
   ],
   declarations: [
	  ArticlesViewerComponent,
	  ArticlesViewerTechnicalNoteComponent,
	  ArticlesViewerSolutionComponent,
	  ArticlesViewerTroubleshootingComponent,
	  ArticlesViewerTechnicalReferenceComponent,
	  ArticlesViewerBestPracticesComponent,
	  ArticlesViewerFrequentlyAskedQuestionComponent,
	  ArticlesViewerModalPreviewComponent,
	  IncidentViewerComponent,
	  PsrViewerComponent,
	  IncidentActivitiesViewerComponent,
	  IncidentEscalationComponent,
	  IncidentShareComponent,
	  ArticleVersionIndicatorComponent,
	  ApprovePublishConfirmationComponent,
	  RejectPublishConfirmationComponent,
	  ApproveReviewConfirmationComponent,
	  RejectReviewConfirmationComponent,
	  ReviewApprovalComponent,
	  PublishApprovalComponent,
	  ArticleVotingComponent,
	  ArticleHistoryComponent,
	  ArticlesCompareComponent,
	  PartnerApprovalComponent,
	  ApprovePartnerConfirmationComponent,
	  RejectPartnerConfirmationComponent,
	  PsrActivitiesViewerComponent,
	  WorkflowTransitionComponent,
	  ConfirmationWithCommentComponent,
	  AcceptWithCommentComponent,
	  RejectWithCommentComponent,
	  RejectPromotionComponent,
	  RejectPromotionConfirmationComponent,
	  IncidentInternalNoteComponent,
	  IncidentEscalationConfirmationComponent,
	  IncidentActivitiesRenderComponent,
	  IncidentActivitiesHeaderComponent
   ],
   // the pipe is needed to be delcared here so we can use it in a component
   providers: [
	  ArticleFriendlyVersionPipe
   ],
   exports: [
	  IncidentViewerComponent,
	  PsrViewerComponent,
	  ArticlesViewerComponent
   ],
   entryComponents: [
	  ApprovePublishConfirmationComponent,
	  RejectPublishConfirmationComponent,
	  ApproveReviewConfirmationComponent,
	  RejectReviewConfirmationComponent,
	  ApprovePartnerConfirmationComponent,
	  RejectPartnerConfirmationComponent,
	  AcceptWithCommentComponent,
	  RejectWithCommentComponent,
	  ArticleHistoryComponent,
	  ArticlesViewerModalPreviewComponent,
	  IncidentEscalationComponent,
	  RejectPromotionComponent,
	  RejectPromotionConfirmationComponent,
	  IncidentInternalNoteComponent,
	  IncidentEscalationConfirmationComponent
   ]
})
export class ContentViewerModule { }
