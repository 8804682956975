import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { GraphNode } from '../../../classification/models/graph-node.model';

@Injectable({
	providedIn: 'root'
})
export class ClassificationSelectionService {

	private _subject = new Subject<GraphNode>();

	public setNode(node: GraphNode) {
		this._subject.next(node);
	}

	public clearNode() {
		this._subject.next();
	}

	public getNode(): Observable<GraphNode> {
		return this._subject.asObservable();
	}

}
