import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from '../services/auth/auth.service';

@Injectable({
	providedIn: 'root'
})
export class HeadersInterceptor implements HttpInterceptor {

	constructor(private _authService: AuthService, private _router: Router) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// for Web API model binding
		request = request.clone({
			headers: request.headers.set('Content-Type', 'application/json')
		});

		// for authentication
		let token = this._authService.getAuthorizationHeaderValue();

		if (token) {
			request = request.clone({
				headers: request.headers.set('Authorization', token)
			});
		} else {
			this._authService.login(this._router.url);
			return next.handle(request);
		}

		return next.handle(request);
	}

}
