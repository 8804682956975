import * as Editor from 'tui-editor-alternate';

import { MdAddHighlighting } from './commands/markdown-commands/add-highlighting';
import { WwAddHighlighting } from './commands/wysiwyg-commands/add-highlighting';

function highlighterExtension(editor) {
	const { colorSyntax = {} } = editor.options;
	const { preset, useCustomSyntax = false } = colorSyntax;

	if (editor.isViewer()) {
		return;
	}

	if (editor.getUI().name === 'default') {
		// define commands for both modes
		editor.addCommand(MdAddHighlighting);
		editor.addCommand(WwAddHighlighting);

		// initialize
		initUI(editor, preset);
	}

	function initUI(editor, preset) {
		const name = 'highlighter';
		const className = 'tui-highlighter';
		const toolbar = editor.getUI().getToolbar();

		editor.eventManager.addEventType('highlighterButtonClickedYellow');
		editor.eventManager.addEventType('highlighterButtonClickedGreen');
		editor.eventManager.addEventType('highlighterButtonClickedRed');

		toolbar.insertItem(4, {
			type: 'divider'
		});

		toolbar.insertItem(5, {
			type: 'button',
			options: {
				name,
				className: className + ' yellow-highlighter',
				event: 'highlighterButtonClickedYellow',
				tooltip: 'Highlighter'
			}
		});

		// hackyish... But can't figure out how to get FontAwesome fonts into here..  
		let svg = '<svg aria xmlns = "http://www.w3.org/2000/svg" style="height: 15px; margin-bottom: 5px; width: auto;" viewBox = "0 0 512 512"> <path fill="currentColor" d = "M497.941 273.941c18.745-18.745 18.745-49.137 0-67.882l-160-160c-18.745-18.745-49.136-18.746-67.883 0l-256 256c-18.745 18.745-18.745 49.137 0 67.882l96 96A48.004 48.004 0 0 0 144 480h356c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H355.883l142.058-142.059zm-302.627-62.627l137.373 137.373L265.373 416H150.628l-80-80 124.686-124.686z" class=""></path></svg > ';

		// sets the contents of the toolbar to be the SVG.. you can't set this through the text or any other of the options that i was able to find
		toolbar.getItem(5).$el[0].innerHTML = svg;

		toolbar.insertItem(6, {
			type: 'button',
			options: {
				name,
				className: className + ' green-highlighter',
				event: 'highlighterButtonClickedGreen',
				tooltip: 'Highlighter'
			}
		});

		// sets the contents of the toolbar to be the SVG.. you can't set this through the text or any other of the options that i was able to find
		toolbar.getItem(6).$el[0].innerHTML = svg;

		toolbar.insertItem(7, {
			type: 'button',
			options: {
				name,
				className: className + ' red-highlighter',
				event: 'highlighterButtonClickedRed',
				tooltip: 'Highlighter'
			}
		});

		// sets the contents of the toolbar to be the SVG.. you can't set this through the text or any other of the options that i was able to find
		toolbar.getItem(7).$el[0].innerHTML = svg;

		// listen for toolbar button click event. this was replicated for each color as no variables appear to be able to be passed in on the method
		// all values were undefined when i tried to have a parameter instead of an empty parameter for the lambda
		editor.eventManager.listen('highlighterButtonClickedYellow', () => {
			editor.eventManager.emit('command', 'highlighting', '1')
		});

		editor.eventManager.listen('highlighterButtonClickedGreen', () => {
			editor.eventManager.emit('command', 'highlighting', '2')
		});

		editor.eventManager.listen('highlighterButtonClickedRed', () => {
			editor.eventManager.emit('command', 'highlighting', '3')
		});
	}
}

Editor.defineExtension('highlighter', highlighterExtension);
