import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap';

import { Article } from '../../../../../../../core/models/articles/article.model';
import { ArticleService } from '../../../../../../../core/services/articles/article.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reject-partner-confirmation',
  templateUrl: './reject-partner-confirmation.component.pug',
  styleUrls: ['./reject-partner-confirmation.component.scss']
})
export class RejectPartnerConfirmationComponent implements OnInit {

	@Input()
	public article: Article;

	public formGroup: FormGroup;

	public submitted: boolean = false;

	@Output()
	public refreshNeeded = new EventEmitter<boolean>();

	private _subscriptions = new Subscription();

	constructor(private _fb: FormBuilder, private _bsModalRef: BsModalRef, private _articleService: ArticleService, private _router: Router) { }

	ngOnInit() {
		this.formGroup = this._fb.group({
			denialReason: ['', [Validators.required, Validators.minLength(5)]]
		});
	}

	public close(): void {
		this._bsModalRef.hide();
	}

	public submit(): void {
		if (this.formGroup.valid) {
			this.submitted = true;
			this._subscriptions.add(
				this._articleService.denyPartner(this.article.articleId, this.article.version, this.formGroup.value["denialReason"])
					.subscribe(response => {
						if (response && response.articleId && response.version) {
							this.refreshNeeded.emit(true);
							this._bsModalRef.hide();
						} else {
							// this error should not happen as it should be caught by the global error handler. But adding this just in case
							this.submitted = false;
							throw new Error("Article approval did not complete successfully");
						}
					})
			);
		}
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

}
