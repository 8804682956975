import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { GridModule } from '@syncfusion/ej2-angular-grids';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

import { ReportsRoutingModule } from './reports-routing.module';

import { ReportsService } from './services/reports.service';

import { ReportsGalleryComponent } from './components/reports-gallery/reports-gallery.component';
import { RenderComponent } from './components/render/render.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,

		GridModule,
		DatePickerModule,

		ReportsRoutingModule
	],
	declarations: [ReportsGalleryComponent, RenderComponent],
	providers: [
		ReportsService
	]
})
export class ReportsModule { }
