import { Article } from "./article.model";
import { ArticleTargetRole } from "./article-target-role.model";

export class TechnicalNoteArticle
	extends Article {

	public type: string = "technicalnote";

	public symptoms: string = null;

	public resolution: string = null;

	public moreInformation: string = null;

	public additionalResources: string = null;

	public targetedRoles: Array<ArticleTargetRole> = null;

}
