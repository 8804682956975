import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ReportInformation } from '../models/report-information.model';
import { ReportDefinition } from '../models/report-definition.model';

@Injectable({
	providedIn: 'root'
})
export class ReportsService {

	private _urlBase: string = "api/reports";

	constructor(private _httpClient: HttpClient) {
	}

	public getReports(): Observable<Array<ReportInformation>> {
		return this._httpClient.get<Array<ReportInformation>>(this._urlBase);
	}

	public getReport(reportId: string): Observable<ReportDefinition> {
		return this._httpClient.get<ReportDefinition>(`${this._urlBase}/${reportId}`);
	}

	public renderReport(reportId: string, data?: any): Observable<any>  {
		let httpParams = new HttpParams();

		if (data) {
			for (let param of data) {
				let value: any;
				
				if (param['value'] && param['type'] === 'DateTime' && typeof(param["value"]) != 'string' ) {					
					let date = param["value"];
					value = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();				
				}
				else {
					value = param["value"]
				}

				if (value) {
					httpParams = httpParams.append(param["name"], value);
				}
			}
		}

		return this._httpClient.get(`${this._urlBase}/render/${reportId}`, { params: httpParams });
	}
}
