import { Component, Input } from '@angular/core';

import { SolutionArticle } from '../../../../../core/models/articles/article-solution.model';

@Component({
	selector: 'app-articles-viewer-solution',
	templateUrl: './articles-viewer-solution.component.pug',
	styleUrls: ['./articles-viewer-solution.component.scss']
})
export class ArticlesViewerSolutionComponent {

	@Input("article")
	public article: SolutionArticle;

}
