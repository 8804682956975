import { Component, OnInit, ViewChild } from '@angular/core';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { BsModalRef, BsModalService, TypeaheadMatch } from 'ngx-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { CommCell } from '../../../core/models/comm-cells/comm-cell.model';
import { Category } from '../../../core/models/incidents/incident-category.model';
import { Contact, ContactRes } from '../../../core/models/incidents/incident-contact.model';
import { Incident } from '../../../core/models/incidents/incident.model';
import { UserProfile } from '../../../core/models/user-profiles/user-profile.model';
import { AlertService } from '../../../core/services/alert/alert.service';
import { IncidentService } from '../../../core/services/incidents/incident.service';
import { UserProfileService } from '../../../core/services/user-profiles/user-profile.service';
import { ConfigurationService } from '../../../modules/runtime-configuration/services/configuration.service';
import { MetallicTicketModel } from '../../models/metallic-ticket.model';
import { MetallicTicketConfirmationComponent } from '../metallic-ticket-confirmation/metallic-ticket-confirmation.component';

@Component({
  selector: 'app-create-metallic-ticket',
  templateUrl: './create-metallic-ticket.component.pug',
  styleUrls: ['./create-metallic-ticket.component.scss']
})
export class CreateMetallicTicketComponent implements OnInit {

  public metallicTicketModel = new MetallicTicketModel();

  public contactMethodData: any = [{'id': '279660000', 'value': 'Email'}, {'id':'279660001', 'value': 'Phone Call'}];
  public contactMethodFields = { text: 'value', value: 'id' };
  public severityData: any = [{'id': '1', 'value': 'Low'}, {'id': '279660000', 'value': 'Medium'}, {'id': '279660001' , 'value':  'High'}, {'id': '279660002', 'value': 'Critical'}];
  public severityFields = { text: 'value', value: 'id' };
  public subscriptionData: any = [];
  public productData: any;
  
  public showContact: boolean = false;
  public isTenantIDValid: boolean = true;
  public isContactValid: boolean = true;
  public isContactMethodValid: boolean = true;
  public isContactDetailsValid: boolean = true;
  public isProductTypeValid: boolean = true;
  public isSubscriptionTypeValid: boolean = true;
  public isSeverityValid: boolean = true;
  public isBusinessImpactValid: boolean = true;
  public isDetailedDesValid: boolean = true;
  public isBriefDesValid: boolean = true;
  public isLastNameValid: boolean = true;
  public isFirstNameValid: boolean = true;
  public isEmailValid: boolean = true;
  public isPhoneValid: boolean = true;
  public isFormValidated: boolean = false;
  public isValidTenantID: boolean = true;
  public isContactDisabled: boolean = false;
  public isValidEmail: boolean = true;
  public contactTypeAhead: any;
  public commCellDetails: any;
  public caseID: string;
  public contactID: any;
  public productFields: Object;
  public subscriptionFields: Object;
  public subscriptionID: any;
  public secondaryContactId: string;
  public profile: UserProfile;
  public fullName: string[];
  public isValidContact: boolean = true;
  public companyName: string;
  public activeSubscription: any;
  public additionalSubscription: any;
  public caseSubmittedBy: string;
  public selectedContact: Contact;

  public defaultValue: string = '279660000';

  public placeholderText: string = 'Please explain why you need a Severity 1 response (eg mission critical servers are down). Otherwise, please select a more appropriate severity level.';

  private _subscription = new Subscription(); 

  @ViewChild('subDropdown')
  public subDropdownObject: DropDownListComponent;

  constructor(private _bsModalRef: BsModalRef, private _modalService: BsModalService, private _profileService: UserProfileService, private _incidentService: IncidentService, private _configSvc: ConfigurationService, private _alertService: AlertService) {
    this.contactTypeAhead = Observable.create((observer: any) => {
      this._incidentService.getMatchingContacts(this.metallicTicketModel.accountId,this.metallicTicketModel.contact).subscribe((result: any) => {
        if(result.isSuccess){
          if(result.response && result.response.length !== 0){
            observer.next(result.response);
          }
        }else {
          this._alertService.show('An unexpected error seems to have occurred. Why not try the action again or refreshing your page? Or you can contact the <a href="mailto:BITS@commvault.com">BITS</a> team if the problem persists.');
          throw new Error(result.error[0]);
        }
      })
    });
  }

  ngOnInit() {
    this.getProfile();
    this.metallicTicketModel.severity = '279660000';
  }

  validateForm(metallicTicketData) {
    if (!metallicTicketData.tenantID) {
      this.isTenantIDValid = false;
    }
    else { this.isTenantIDValid = true; }
    if (!this.showContact && !metallicTicketData.contact) {
      this.isContactValid = false;
    }
    else { this.isContactValid = true; }
    if (!metallicTicketData.contactMethod) {
      this.isContactMethodValid = false;
    }
    else { this.isContactMethodValid = true; }
    if (!metallicTicketData.contactDetails) {
      this.isContactDetailsValid = false;
    }
    else { this.isContactDetailsValid = true; }
    if (!metallicTicketData.subscriptionType) {
      this.isSubscriptionTypeValid = false;
    }
    else { this.isSubscriptionTypeValid = true; }
    if (!metallicTicketData.productType) {
      this.isProductTypeValid = false;
    }
    else { this.isProductTypeValid = true; }
    if (!this.metallicTicketModel.severity) {
      this.isSeverityValid = false;
    }
    else { this.isSeverityValid = true; }
    if (!metallicTicketData.businessImpact && metallicTicketData.severity === "279660002") {
      this.isBusinessImpactValid = false;
    }
    else { this.isBusinessImpactValid = true; }
    if (!metallicTicketData.briefDes) {
      this.isBriefDesValid = false;
    }
    else if( metallicTicketData.briefDes.length > 300) {
      this.isBriefDesValid = false;
    }
    else { this.isBriefDesValid = true; }
    if (!metallicTicketData.detailedDes) {
      this.isDetailedDesValid = false;
    }
    else if( metallicTicketData.detailedDes.length > 1048576) {
      this.isDetailedDesValid = false;
    }
    else { this.isDetailedDesValid = true; }
    if(this.showContact) {
      if (!metallicTicketData.lastName) {
        this.isLastNameValid = false;
      }
      else { this.isLastNameValid = true; }
      if (!metallicTicketData.firstName) {
        this.isFirstNameValid = false;
      }
      else { this.isFirstNameValid = true; }
      if (!metallicTicketData.email) {
        this.isEmailValid = false;
      }
      else { this.isEmailValid = true; }
      if(metallicTicketData.email && !this.validateEmail(metallicTicketData.email)){
        this.isValidEmail = false;
      }
      else{ this.isValidEmail = true; }
      if (!metallicTicketData.phone) {
        this.isPhoneValid = false;
      }
      else { this.isPhoneValid = true; }
    }
    if(!this.showContact && metallicTicketData.contact && !this.contactID){
      this.isValidContact= false;
    }
    else{ this.isValidContact = true; }

    this.isFormValidated = (this.isTenantIDValid && this.isValidTenantID && this.isContactValid && this.isValidContact && this.isContactMethodValid && this.isContactDetailsValid && this.isSubscriptionTypeValid && this.isProductTypeValid 
      && this.isSeverityValid && this.isBusinessImpactValid && this.isBriefDesValid && this.isDetailedDesValid && this.isLastNameValid && this.isFirstNameValid && this.isEmailValid && this.isValidEmail && this.isPhoneValid);

    return this.isFormValidated;
  }

  public validateEmail(email) {
    return email.match(new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"));
  }

  public validateTenantId(event) {
    let tenantId = event.target.value;
    tenantId = tenantId.toLocaleLowerCase().replace(/-/g, "").slice(0,10);
    let response = this._incidentService.getCommcell(tenantId).then(data => {
      if(data.isSuccess){
        if(data.response){
          this.commCellDetails = data.response;
          this.isValidTenantID = true;
          this.isContactDisabled = true;
          this.metallicTicketModel.contact = "";
          this.subscriptionData = [];
          this.productData = [];
          this.metallicTicketModel.company = data.response.customer.name;
          this.metallicTicketModel.accountId = data.response.customer.id;
          for(let i=0; i< data.response.subscriptionTypes.length; i++){
            data.response.subscriptionTypes[i].status = '---- Active Subscriptions ----';
          }
          this.activeSubscription = data.response.subscriptionTypes;
          this.getCategories();
        }
        else {
          this.isValidTenantID = false;
        } 
      }else {
        this._alertService.show('An unexpected error seems to have occurred. Why not try the action again or refreshing your page? Or you can contact the <a href="mailto:BITS@commvault.com">BITS</a> team if the problem persists.');
        throw new Error(data.error[0]);
      }       
    });
    return this.isValidTenantID;
  }

  public getCategories(): void {
    let response = this._incidentService.getCategories("Metallic Classification").then(data => {
      if(data.isSuccess){
        if(data.response){
          for(let i=0; i< data.response.length; i++){
            data.response[i].status = '---- Additional Subscription Types ----';
          }
          this.additionalSubscription = data.response;
          for(let i=0; i< this.activeSubscription.length; i++){
            let index = this.additionalSubscription.findIndex(val =>
              val.categoryId === this.activeSubscription[i].categoryId);
              if(index !== -1){
                this.additionalSubscription.splice(index,1);
              }
          }
          this.subscriptionData = [...this.activeSubscription,...this.additionalSubscription];
          this.subscriptionFields = { text: 'title', value: 'categoryId', groupBy: 'status' };
        } 
      }else {
        this._alertService.show('An unexpected error seems to have occurred. Why not try the action again or refreshing your page? Or you can contact the <a href="mailto:BITS@commvault.com">BITS</a> team if the problem persists.');
        throw new Error(data.error[0]);
      }
    });
  }

  public onCancel(): void {
		this._bsModalRef.hide();
	}

  public addContact(): void {
    this.showContact = !this.showContact;
    this.metallicTicketModel.contact = "";
    this.metallicTicketModel.contactMethod = null;
    this.metallicTicketModel.contactDetails = "";
  }

  contactMethodChange(event): void {
    if(this.showContact) {
      if(event.item && event.item.id === '279660001') {
        this.metallicTicketModel.contactDetails = this.metallicTicketModel.phone;
      }else if(event.item && event.item.id === '279660000') {
        this.metallicTicketModel.contactDetails = this.metallicTicketModel.email;
      }
    }else {
      if(event.item && event.item.id === '279660001') {
        this.metallicTicketModel.contactDetails = this.selectedContact.phone;
      }else if(event.item && event.item.id === '279660000') {
        this.metallicTicketModel.contactDetails = this.selectedContact.emailAddress;
      }
    }
    
  }

  subscriptionChange(): void {
    this.subscriptionID = this.subDropdownObject.value;
    for (let i = 0; i < this.subscriptionData.length; i++) {
      if(this.subscriptionData[i].categoryId == this.subscriptionID){
        this.productData = this.subscriptionData[i].children;
        this.productFields = { text: 'title', value: 'categoryId' };
      }
    }    
  }

  public getProfile():void {
    this._subscription.add(
      this._profileService.getMyProfile()
      .subscribe(data => {
        this.profile =  data;
      })
    )
  }

  public onSelect(event: TypeaheadMatch): void {  
    this.contactID = event.item.id;
    this.selectedContact = event.item;
    this.metallicTicketModel.contactMethod = event.item.preferredContactMethodId;
    if(this.metallicTicketModel.contactMethod === '279660001') {
      this.metallicTicketModel.contactDetails = event.item.phone;
    }else {
      this.metallicTicketModel.contactDetails = event.item.emailAddress;
    }
  } 

  public ticketCreated(metallicTicketData): void {
    if(this.validateForm(metallicTicketData)){
      this.createContact();
    }
	}

  public createContact(){
    if(this.showContact){
      let contact = new Contact();
      contact.firstName = this.metallicTicketModel.firstName;
      contact.lastName = this.metallicTicketModel.lastName;
      contact.emailAddress = this.metallicTicketModel.email;
      contact.phone = this.metallicTicketModel.phone;
      contact.customerId = this.commCellDetails && this.commCellDetails.customerId;
      let response = this._incidentService.createContact(contact).then(data => {
        if(data.isSuccess){
          if(data.response){
            this.contactID = data.response;
            this.createTicket();
          } 
        }else {
          this._alertService.show('An unexpected error seems to have occurred. Why not try the action again or refreshing your page? Or you can contact the <a href="mailto:BITS@commvault.com">BITS</a> team if the problem persists.');
          throw new Error(data.error[0]);
        }
      });
    }else {
      this.createTicket();
    }
  }

  public createTicket() {
    let incident = new Incident();
      incident.tenantID = this.metallicTicketModel.tenantID;
      incident.contactId = this.contactID;
      incident.preferredContactMethodId = this.metallicTicketModel.contactMethod;
      incident.contactDetails = this.metallicTicketModel.contactDetails;
      incident.subscriptionType = this.metallicTicketModel.subscriptionType;
      incident.productType = this.metallicTicketModel.productType;
      incident.severity = this.metallicTicketModel.severity;
      incident.businessImpact = this.metallicTicketModel.businessImpact;
      incident.title = this.metallicTicketModel.briefDes;
      incident.description = this.metallicTicketModel.detailedDes;
      incident.caseOriginCode = '3';
      incident.applicationServicePack = '772370061';
      incident.applicationMajorVersion = '772370021';
      incident.supportType = this._configSvc.configuration.supportType;
      incident.preferredLanguage = "English";
      incident.commCellId = this.commCellDetails && this.commCellDetails.name;
      incident.accountId = this.commCellDetails && this.commCellDetails.customerId;
      incident.supportTeamId = this._configSvc.configuration.metallicCaseSupportTeamId;

      this.getSecondaryContact(incident);
  }

  public getSecondaryContact(incident) {
    let response = this._incidentService.getSecondaryContacts(this.profile.email).then(data => {
      if(data.isSuccess){
        if(data.response && data.response.length !== 0){
          this.caseSubmittedBy = data.response[0].id;
          incident.caseSubmittedBy = this.caseSubmittedBy;
          this.createIncident(incident);
        }else {
          this.addSecondaryContact(incident);
        }
      }else {
        this._alertService.show('An unexpected error seems to have occurred. Why not try the action again or refreshing your page? Or you can contact the <a href="mailto:BITS@commvault.com">BITS</a> team if the problem persists.');
        throw new Error(data.error[0]);
      }
    })
  }

  public addSecondaryContact(incident){
    let contact = new Contact();
    this.fullName = this.profile.displayName && this.profile.displayName.split(" ");
    if(this.fullName[0]){
      contact.firstName = this.fullName[0];
    }
    if(this.fullName[1]){
      contact.lastName = this.fullName[1];
    }
    contact.emailAddress = this.profile.email;

    let response = this._incidentService.createContact(contact).then(data => {
      if(data.isSuccess){
        if(data.response){
          this.caseSubmittedBy = data.response;
          incident.caseSubmittedBy = this.caseSubmittedBy;
          this.createIncident(incident);
        }
      }else {
        this._alertService.show('An unexpected error seems to have occurred. Why not try the action again or refreshing your page? Or you can contact the <a href="mailto:BITS@commvault.com">BITS</a> team if the problem persists.');
        throw new Error(data.error[0]);
      }
    });
  }

  public createIncident(incident) {
    if(this.metallicTicketModel.visibility) {
      incident.secondaryContactId = incident.caseSubmittedBy;
    }
    let response = this._incidentService.createTicket(incident).then(data => {
      if(data.isSuccess){
        if(data.response){
          this._bsModalRef.hide();
          this._bsModalRef = this._modalService.show(MetallicTicketConfirmationComponent);
          this._bsModalRef.content.caseID = data.response;
        }
      }else {
        this._alertService.show('An unexpected error seems to have occurred. Why not try the action again or refreshing your page? Or you can contact the <a href="mailto:BITS@commvault.com">BITS</a> team if the problem persists.');
        throw new Error(data.error[0]);
      }
    });
  }

  ngOnDestroy() {
		this._subscription.unsubscribe();
	}

}
