export class Contact {

    public id: string;

    public firstName: string;
    
    public lastName: string;

    public emailAddress: string;

    public phone: string;

    public fullName: string;

    public customerId: string;
}

export class ContactRes {

    public id: string;
    
}