import { Component, Input } from '@angular/core';

import { BestPracticesArticle } from '../../../../../core/models/articles/article-best-practices.model';

@Component({
  selector: 'app-articles-viewer-best-practices',
  templateUrl: './articles-viewer-best-practices.component.pug',
  styleUrls: ['./articles-viewer-best-practices.component.scss']
})
export class ArticlesViewerBestPracticesComponent {

	@Input("article")
	public article: BestPracticesArticle;

}
