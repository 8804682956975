import { Component, OnInit, Input } from '@angular/core';
import { IncidentActivity } from '../../../../../core/models/incidents/incident-activity.model';
import { IncidentService } from '../../../../../core/services/incidents/incident.service';
import { ConfigurationService } from '../../../../runtime-configuration/services/configuration.service';

@Component({
  selector: 'incident-activities-header',
  templateUrl: './incident-activities-header.component.pug',
  styleUrls: ['./incident-activities-header.component.scss']
})
export class IncidentActivitiesHeaderComponent implements OnInit {
  @Input()
  public id: string;

  @Input()
  public activity: IncidentActivity;

  @Input()
  public isEscalated: boolean = false;
  
  constructor() { }

  ngOnInit() {}
}
